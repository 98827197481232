import React from "react";
import { Modal as AntModal } from "antd";
import Warning from "../../assets/warning.png";
// import UserBankAccountDetails from "../../views/userBankAccountDetails";
import { Currency } from "../Number";

const ApproveRate = props => {
  return (
    <AntModal
      width="768px"
      className="modal-title-center"
      //   title="Store gold"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-6 mx-auto text-center">
        <div>
          <h1>Approve Request</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>
        <p className="text-black text-base w-3/4 mx-auto">
          Gold rate is set to{" "}
          <span className="font-bold">
            ₹ <Currency number={props.rate} />
          </span>{" "}
          Do you want to approve the gold rate?
          {/* mentioned below. */}
        </p>

        <div className="flex space-x-4 justify-center">
          <button className="btn-primary" onClick={() => props.approveRate()}>
            Approve
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default ApproveRate;
