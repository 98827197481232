// DEPS
import React, { useState, useEffect } from "react";

// COMPONENTS
import CheckerSidebar from "../../components/CheckerSidebar";

// VIEWS
import RateDetails from "./rateDetails";
import BizHoursDetails from "./bizHoursDetails";
import HolidaysDetails from "./holidaysDetails";

const ApprovedRequests = props => {
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [requestType, setRequestType] = useState(null);

  useEffect(() => {
    // console.log("fetching again", selectedRequest);
    // getRequestById({ variables: { id: selectedRequest || 0 } });
  }, [selectedRequest]);

  return (
    <div className="flex flex-row space-x-4">
      {/* Sidebar */}

      <CheckerSidebar
        props={props}
        index={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        status="APPROVED"
        trigger={trigger}
        setTrigger={setTrigger}
        setRequestType={setRequestType}
      />
      {/* Sidebar */}

      {/* {console.log("requestType", requestType)} */}

      <div className="px-4 space-y-8" style={{ width: "82vw" }}>
        {requestType === "RATES" ? (
          <RateDetails id={selectedRequest} status="approved" />
        ) : requestType === "BIZHOURS" ? (
          <BizHoursDetails id={selectedRequest} status="approved" />
        ) : requestType === "HOLIDAYS" ? (
          <HolidaysDetails id={selectedRequest} status="approved" />
        ) : null}
      </div>
    </div>
  );
};

export default ApprovedRequests;
