import gql from 'graphql-tag';

const APPROVE_LOAN = gql`
  mutation setLoanApprovedStatus(
    $loan_id: Int!
    $approved_at: timestamptz!
    $approved_by: String!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: {
        loan_status: "APPROVED"
        approved_at: $approved_at
        approved_by_auth_id: $approved_by
      }
    ) {
      id
      loan_status
      approved_by_auth_id
      approved_at
    }
  }
`;

const GET_LOAN_BY_ID = gql`
  query getLoanByIDWithSlabDetails($id: Int!) {
    loans_by_pk(id: $id) {
      id
      loan_number
      loan_type
      loan_status
      account_number
      rejection_reason
      approved_at
      receipts
      renew_from_loan
      loan_renew_from {
        id
        loan_number
      }
      golds {
        id
        gold_display_id
        quality
        quantity
        gross_weight
        actual_net_weight
        net_weight
        stone_deduction
        type
        pickup_seal_id
        pickup_seal_images
        sealed_cover_gross_weight
        gold_images
        pickup_sealed_gold_images
        amount
      }
      gl_amount {
        id
        loan_amount
      }
      od_amount {
        id
        credit_limit
      }
      plan {
        id
        slab_details {
          slab_order
          intervals
          lender_interest_rate
        }
        maximum_amount
        minimum_amount
        scheme_name
      }
      visits {
        id
        type
        agent_id
        agent_auth_id
        agent_first_name
        agent_last_name
        agent_mobile_number
        agent_photo
        visit_display_id
        intermediate_status
      }
      customer {
        id
      }
    }
  }
`;

const SET_BLC_VERIFIED_STATUS = gql`
  mutation setBLCVerifiedStatus(
    $id: Int!
    $loan_status: String!
    $checker_confirmed_at: timestamptz!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $id }
      _set: {
        loan_status: $loan_status
        checker_confirmed_at: $checker_confirmed_at
      }
    ) {
      id
      loan_status
      checker_confirmed_at
    }
  }
`;

const SET_LOAN_RELEASED_STATUS = gql`
  mutation setLoanReleasedStatus($id: Int!, $released_at: timestamptz!) {
    update_loans(
      where: { id: { _eq: $id } }
      _set: { released_at: $released_at, loan_status: "GOLD_RELEASED" }
    ) {
      returning {
        id
        released_at
        loan_status
      }
    }
  }
`;

const REJECT_LOAN = gql`
  mutation rejectLoan($loan_id: Int!, $reason: String!) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: { loan_status: "REJECTED", rejection_reason: $reason }
    ) {
      id
      loan_status
      rejection_reason
    }
  }
`;

const CHECK_SEAL_GROSS_WT = gql`
  query checkSealGrossWt($loan_id: Int!, $weights: [float8!]) {
    golds_aggregate(
      where: {
        loan_id: { _eq: $loan_id }
        _and: { sealed_cover_gross_weight: { _in: $weights } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const BLC_ABORT_LOAN = gql`
  mutation BlcAbortLoan(
    $loan_id: Int!
    $reason: String
    $checker_confirmed_at: timestamptz!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: {
        loan_status: "BLC_ABORTED"
        rejection_reason: $reason
        checker_confirmed_at: $checker_confirmed_at
      }
    ) {
      id
      loan_status
      checker_confirmed_at
    }
  }
`;

const BLV_ABORT_LOAN = gql`
  mutation BlvAbortLoan(
    $loan_id: Int!
    $reason: String
    $stored_at: timestamptz!
  ) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: {
        loan_status: "BLV_ABORTED"
        rejection_reason: $reason
        stored_at: $stored_at
      }
    ) {
      id
      loan_status
      stored_at
    }
  }
`;

const BLV_CONFIRM_LOAN = gql`
  mutation BlvConfirmLoan($loan_id: Int!, $stored_at: timestamptz!) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: { loan_status: "GOLD_STORED", stored_at: $stored_at }
    ) {
      id
      loan_status
      stored_at
    }
  }
`;

const BLV_RENEWED_LOAN = gql`
  mutation BlvConfirmRenewedLoan($loan_id: Int!) {
    update_loans_by_pk(
      pk_columns: { id: $loan_id }
      _set: { loan_status: "RENEWED" }
    ) {
      id
      loan_status
      stored_at
    }
  }
`;

const VALIDATE_OTP = gql`
  mutation validateOTP($otp: Int!) {
    validate_otp(otp: $otp) {
      message
    }
  }
`;

const UPDATE_VISIT_STATUS_OTP_VALIDATED = gql`
  mutation update_visit_status_to_otp_validated($visit_id: Int!) {
    update_visit_status_to_otp_validated(visit_id: $visit_id) {
      message
    }
    update_visits_by_pk(
      pk_columns: { id: $visit_id }
      _set: { intermediate_status: "OTP_VALIDATED" }
    ) {
      id
      intermediate_status
    }
  }
`;

const UPDATE_VISIT_STATUS_GOLD_HANDED_OVER = gql`
  mutation updateVisitStatusGoldHandedOver($visit_id: Int!) {
    update_visit_status_to_gold_handover_to_agent(visit_id: $visit_id) {
      message
    }
  }
`;

const GENERATE_OTP = gql`
  mutation generate_otp($agent_auth_id: String!, $visit_id: Int!) {
    generate_otp(agent_auth_id: $agent_auth_id, visit_id: $visit_id) {
      id
      otp
    }
  }
`;

const CHECK_OTP = gql`
  query checkOTP($otp: Int!, $auth_id: String!) {
    check_otp(otp: $otp, otp_from_auth_id: $auth_id) {
      is_active
    }
  }
`;

const UPDATE_GOLDS_ADD_SEALED_COVER_GROSS_WT_CHECKER = gql`
  mutation upsertGoldsUpdateSealedCoverGrossWtChecker(
    $obj: [golds_insert_input!]!
  ) {
    insert_golds(
      objects: $obj
      on_conflict: {
        constraint: golds_pkey
        update_columns: sealed_cover_gross_weight_checker
      }
    ) {
      affected_rows
    }
  }
`;

export {
  APPROVE_LOAN,
  GET_LOAN_BY_ID,
  SET_BLC_VERIFIED_STATUS,
  REJECT_LOAN,
  SET_LOAN_RELEASED_STATUS,
  CHECK_SEAL_GROSS_WT,
  BLC_ABORT_LOAN,
  BLV_ABORT_LOAN,
  BLV_CONFIRM_LOAN,
  BLV_RENEWED_LOAN,
  VALIDATE_OTP,
  UPDATE_VISIT_STATUS_OTP_VALIDATED,
  GENERATE_OTP,
  CHECK_OTP,
  UPDATE_VISIT_STATUS_GOLD_HANDED_OVER,
  UPDATE_GOLDS_ADD_SEALED_COVER_GROSS_WT_CHECKER,
};
