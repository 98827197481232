// DEPS
import React, { useState, useEffect } from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { NavLink } from "react-router-dom";
import { Badge } from "antd";
import PropTypes from "prop-types";

// ASSETS
import Logo from "../../assets/logo@2x.png";
import LoansIcon from "../../assets/Nav/loans-icon.png";
import ProfileIcon from "../../assets/Nav/profile-icon.png";
import SupportIcon from "../../assets/Nav/support-icon.png";
import GoldRate from "../../assets/Nav/gold-rate.png";

// COMPONENTS
import Can from "../Can";
import LogoutModal from "../Modals/logout";

// APIS
import {
  GET_USER_DATA,
  LOAN_AGGREGATE_DATA,
  REQUEST_AGGREGATE_DATA
} from "./graphql";
import LogoutTimer from "../LogoutTimer";
import AuthUtils from "../../utils/AuthUtils";
import { useBankDetails } from "../../contexts/BankDetailsContext";
import { getValueFromCookie } from "../../utils/cookies";
import { getValueFromLocalStorage } from "../../utils/local-storage";

const CSTNav = props => {
  const { currentBankName } = useBankDetails();
  const [isLogoutConfirmModalOpen, setLogoutConfirmModalOpen] = useState(false);
  const [prevLoanCount, setPrevLoanCount] = useState(0);

  const { data: userData, loading: userDataLoading } = useQuery(GET_USER_DATA, {
    variables: {
      notCST: false
    }
  });

  const {
    data: loanAggregateData,
    loading: loanAggregateLoading
  } = useSubscription(LOAN_AGGREGATE_DATA);

  const Play = () => {
    // console.log("notifSound", notifSound);
    if (!userData) {
      var audio = new Audio("https://www.soundjay.com/misc/bell-ring-01.mp3");
    } else {
      // var audio;

      if (userData.users[0].notification_sound === "Buzzer") {
        audio = new Audio("https://www.soundjay.com/button/beep-05.mp3");
      } else if (userData.users[0].notification_sound === "Soft bells") {
        audio = new Audio(
          "https://orocorp.s3.ap-south-1.amazonaws.com/378149soft_bells.mp3"
        );
      } else if (userData.users[0].notification_sound === "Bells") {
        audio = new Audio(
          "https://orocorp.s3.ap-south-1.amazonaws.com/443256bells.mp3"
        );
      } else {
        audio = new Audio("");
      }

      audio.play();
    }
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      window.Notification.requestPermission();
    }
  });

  useEffect(() => {
    if (loanAggregateData) {
      let new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
        return loan.loan_status === "TO_BE_APPROVED";
      });
      if (prevLoanCount !== new_loans.length) {
        var options = {
          // body: "New loan request received!",
          dir: "ltr"
        };
        //  var notification = new window.Notification("Notification Demo", options);
        new window.Notification(
          `You have ${new_loans.length} pending loan requests`,
          options
        );

        Play();
        setPrevLoanCount(new_loans.length);
      }
    }
  }, [loanAggregateData]);

  let new_loans;
  let active_loans;
  let rejected_loans;

  if (loanAggregateData) {
    new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return loan.loan_status === "TO_BE_APPROVED";
    });

    active_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return (
        loan.loan_status !== "TO_BE_APPROVED" && loan.loan_status !== "REJECTED"
      );
    });

    rejected_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return loan.loan_status === "REJECTED";
    });
  }

  return (
    <>
      {/* PRIMARY NAVBAR */}
      <div
        className="bg-white flex w-full justify-between pr-3 border-b border-gray-400"
        style={{ height: "72px" }}
      >
        <div className="space-x-8 flex">
          <img
            src={Logo}
            alt="orocorp-logo"
            style={{
              height: "72px",
              width: "auto"
            }}
            className="p-5"
          />
          <NavLink
            to={"/loans/new-loans"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={LoansIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">LOANS</h4>
          </NavLink>

          <NavLink
            to="/profile"
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={ProfileIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">PROFILE</h4>
          </NavLink>

          <NavLink
            to={"/support"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={SupportIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">SUPPORT</h4>
          </NavLink>
        </div>
        <div className="flex justify-center items-center space-x-4">
          <LogoutTimer/>
          <h4 className="capitalize">
            {userDataLoading ? null : `${currentBankName} - CST`}{" "}
          </h4>
          <button
            className="btn-danger border-none"
            style={{
              height: "32px",
              lineHeight: "16px",
              outline: "none"
            }}
            onClick={() => setLogoutConfirmModalOpen(true)}
          >
            Log out
          </button>
        </div>
      </div>
      {/* PRIMARY NAVBAR */}

      <div
        className="bg-white flex w-full space-x-8 px-3 border-b border-gray-400"
        style={{ height: "48px" }}
      >
        <div className="flex items-center space-x-2">
          <div className="m-1 pr-4 border-r border-black">
            <h2>Manage loans</h2>
          </div>
        </div>

        <div className="flex space-x-8">
          <NavLink
            to="/loans/new-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">NEW</h4>
              <Badge
                count={
                  loanAggregateLoading ? 0 : new_loans ? new_loans.length : 0
                }
              />
            </div>
          </NavLink>

          <NavLink
            to="/loans/approved-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">APPROVED</h4>
              <Badge
                count={
                  loanAggregateLoading
                    ? 0
                    : active_loans
                    ? active_loans.length
                    : 0
                }
              />
            </div>
          </NavLink>

          <NavLink
            to="/loans/rejected-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">REJECTED</h4>
              <Badge
                count={
                  loanAggregateLoading
                    ? 0
                    : rejected_loans
                    ? rejected_loans.length
                    : 0
                }
              />
            </div>
          </NavLink>

          <NavLink
            to="/loans/all-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">ALL LOANS</h4>
              <Badge
                count={
                  loanAggregateLoading
                    ? 0
                    : rejected_loans && new_loans && active_loans
                    ? rejected_loans.length +
                      new_loans.length +
                      active_loans.length
                    : 0
                }
              />
            </div>
          </NavLink>
        </div>
      </div>

      <LogoutModal
        isOpen={isLogoutConfirmModalOpen}
        setOpen={setLogoutConfirmModalOpen}
      />
    </>
  );
};

const BranchCheckerVerifierNav = props => {
  const { currentBankName } = useBankDetails();
  const [isLogoutConfirmModalOpen, setLogoutConfirmModalOpen] = useState(false);
  const [prevLoanCount, setPrevLoanCount] = useState(0);

  const { data: userData, loading: userDataLoading } = useQuery(GET_USER_DATA, {
    variables: {
      notCST: true
    }
  });

  const {
    data: loanAggregateData,
    loading: loanAggregateLoading
  } = useSubscription(LOAN_AGGREGATE_DATA, {
    variables: {
      loan_status: [
        "APPROVED",
        "BLC_VERIFIED",
        "BLV_CONFIRMED",
        "GOLD_STORED",
        "GOLD_RELEASED",
        "CLOSED",
        "BLC_ABORTED",
        "BLV_ABORTED"
      ]
    }
  });

  const Play = () => {
    // console.log("notifSound", notifSound);
    if (!userData) {
      var audio = new Audio("https://www.soundjay.com/misc/bell-ring-01.mp3");
    } else {
      // var audio;

      if (userData.users[0].notification_sound === "Buzzer") {
        audio = new Audio("https://www.soundjay.com/button/beep-05.mp3");
      } else if (userData.users[0].notification_sound === "Soft bells") {
        audio = new Audio(
          "https://orocorp.s3.ap-south-1.amazonaws.com/378149soft_bells.mp3"
        );
      } else if (userData.users[0].notification_sound === "Bells") {
        audio = new Audio(
          "https://orocorp.s3.ap-south-1.amazonaws.com/443256bells.mp3"
        );
      } else {
        audio = new Audio("");
      }

      audio.play();
    }
  };

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      window.Notification.requestPermission();
    }
  });

  useEffect(() => {
    if (loanAggregateData) {
      let new_loans;
      const xhasuraDefaultRole = getValueFromCookie("user");

      if (
        xhasuraDefaultRole?.["X_Hasura_Default_Role"] ===
        "blc"
      ) {
        new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
          return loan.loan_status === "APPROVED";
        });
      } else {
        new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
          return (
            loan.loan_status === "BLC_VERIFIED" ||
            loan.loan_status === "BLV_CONFIRMED"
          );
        });
      }

      if (prevLoanCount !== new_loans.length) {
        var options = {
          // body: "New loan request received!",
          dir: "ltr"
        };
        //  var notification = new window.Notification("Notification Demo", options);
        new window.Notification(
          `You have ${new_loans.length} pending loan requests`,
          options
        );

        Play();
        setPrevLoanCount(new_loans.length);
      }
    }
  }, [loanAggregateData]);

  let new_loans;
  // let active_loans;
  let stored_loans;
  let released_loans;
  let rejected_loans;
  let renewed_loans;

  if (loanAggregateData) {
    // new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
    //   return (
    //     loan.loan_status === "APPROVED" ||
    //     loan.loan_status === "BLC_VERIFIED" ||
    //     loan.loan_status === "BLV_CONFIRMED"
    //   );
    // });

    // console.log("loans", loanAggregateData.loans_aggregate.nodes);

    if (
      getValueFromCookie("user")?.["X_Hasura_Default_Role"] ===
      "blc"
    ) {
      new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
        return loan.loan_status === "APPROVED";
      });
    } else {
      new_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
        return (
          loan.loan_status === "BLC_VERIFIED" ||
          loan.loan_status === "BLV_CONFIRMED"
        );
      });
    }

    stored_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return loan.loan_status === "GOLD_STORED";
    });

    released_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return (
        loan.loan_status === "GOLD_RELEASED" || loan.loan_status === "CLOSED"
      );
    });

    rejected_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return (
        loan.loan_status === "BLC_ABORTED" || loan.loan_status === "BLV_ABORTED"
      );
    });

    renewed_loans = loanAggregateData.loans_aggregate.nodes.filter(loan => {
      return loan.loan_status === "GOLD_STORED" && !!loan.renew_from_loan
    });
  }

  return (
    <>
      {/* PRIMARY NAVBAR */}
      <div
        className="bg-white flex w-full justify-between pr-3 border-b border-gray-400"
        style={{ height: "72px" }}
      >
        <div className="space-x-8 flex">
          <img
            src={Logo}
            alt="orocorp-logo"
            style={{
              height: "72px",
              width: "auto"
            }}
            className="p-5"
          />
          <NavLink
            to={"/loans/new-loans"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={LoansIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
              // className="p-3"
            />
            <h4 className="text-gray-600">LOANS</h4>
          </NavLink>

          <NavLink
            to="/profile"
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={ProfileIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">PROFILE</h4>
          </NavLink>

          <NavLink
            to={"/support"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={SupportIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">SUPPORT</h4>
          </NavLink>
        </div>

        <div className="flex justify-center items-center space-x-4">
          <LogoutTimer/>
          <h4 className="capitalize">
            {userDataLoading
              ? null
              : `${currentBankName} - ${
                  userData?.users[0]?.branch?.name
                } - ${userData?.users[0]?.role
                  .split("_")
                  .join(" ")
                  .toLowerCase()}`}
          </h4>

          <button
            className="btn-danger border-none"
            style={{ height: "32px", lineHeight: "16px", outline: "none" }}
            onClick={() => setLogoutConfirmModalOpen(true)}
          >
            Log out
          </button>
        </div>
      </div>
      {/* PRIMARY NAVBAR */}

      <div
        className="bg-white flex w-full space-x-8 px-3 border-b border-gray-400"
        style={{ height: "48px" }}
      >
        <div className="flex items-center space-x-2">
          <div className="m-1 pr-4 border-r border-[#CBD5E0]">
            <h2>Manage loans</h2>
          </div>
        </div>

        <div className="flex space-x-8">
          <NavLink
            to="/loans/new-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">NEW</h4>
              <Badge
                count={
                  loanAggregateLoading ? 0 : new_loans ? new_loans.length : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/loans/stored-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">STORED</h4>
              <Badge
                count={
                  loanAggregateLoading
                    ? 0
                    : stored_loans
                    ? stored_loans.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/loans/released-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">RELEASED</h4>
              <Badge
                count={
                  loanAggregateLoading
                    ? 0
                    : released_loans
                    ? released_loans.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/loans/rejected-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">ABORTED</h4>
              <Badge
                count={
                  props.loanAggregateLoading
                    ? 0
                    : rejected_loans
                    ? rejected_loans.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/loans/renewed-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">RENEWED</h4>
              <Badge
                count={
                  props.loanAggregateLoading
                    ? 0
                    : renewed_loans
                    ? renewed_loans.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/loans/all-loans"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">ALL LOANS</h4>
              <Badge
                count={
                  props.loanAggregateLoading
                    ? 0
                    : rejected_loans &&
                      released_loans &&
                      stored_loans &&
                      new_loans
                    ? rejected_loans.length +
                      released_loans.length +
                      stored_loans.length +
                      new_loans.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>
        </div>
      </div>

      <LogoutModal
        isOpen={isLogoutConfirmModalOpen}
        setOpen={setLogoutConfirmModalOpen}
      />
    </>
  );
};

const MakerNavbar = props => {
  const { currentBankName } = useBankDetails();
  const [isLogoutConfirmModalOpen, setLogoutConfirmModalOpen] = useState(false);
  return (
    <>
      {/* PRIMARY NAVBAR */}
      <div
        className="bg-white flex w-full justify-between pr-3 border-b border-gray-400"
        style={{ height: "72px" }}
      >
        <div className="space-x-8 flex">
          <img
            src={Logo}
            alt="orocorp-logo"
            style={{
              height: "72px",
              width: "auto"
            }}
            className="p-5"
          />
          <NavLink
            to={"/update-rate"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={GoldRate}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
              // className="p-3"
            />
            <h4 className="text-gray-600">GOLD RATE</h4>
          </NavLink>

          <NavLink
            to="/profile"
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={ProfileIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">PROFILE</h4>
          </NavLink>

          <NavLink
            to={"/support"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={SupportIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">SUPPORT</h4>
          </NavLink>
        </div>

        <div className="flex justify-center items-center space-x-4">
          <LogoutTimer/>
          <h4 className="capitalize">{currentBankName} - Bank Maker</h4>

          <button
            className="btn-danger border-none"
            style={{ height: "32px", lineHeight: "16px", outline: "none" }}
            onClick={() => setLogoutConfirmModalOpen(true)}
          >
            Log out
          </button>
        </div>
      </div>
      {/* PRIMARY NAVBAR */}

      <LogoutModal
        isOpen={isLogoutConfirmModalOpen}
        setOpen={setLogoutConfirmModalOpen}
      />
    </>
  );
};

const CheckerNavbar = props => {
  const { currentBankName } = useBankDetails();
  const [isLogoutConfirmModalOpen, setLogoutConfirmModalOpen] = useState(false);

  let new_requests;
  let approved_requests;
  let rejected_requests;

  const {
    data: requestAggregateData,
    loading: requestAggregateLoading
  } = useSubscription(REQUEST_AGGREGATE_DATA);

  if (requestAggregateData) {
    new_requests = requestAggregateData.approvals_changelog_aggregate.nodes.filter(
      rate => {
        return rate.status === "TO_BE_APPROVED";
      }
    );

    approved_requests = requestAggregateData.approvals_changelog_aggregate.nodes.filter(
      rate => {
        return rate.status === "APPROVED";
      }
    );

    rejected_requests = requestAggregateData.approvals_changelog_aggregate.nodes.filter(
      rate => {
        return rate.status === "REJECTED";
      }
    );
  }

  return (
    <>
      {/* PRIMARY NAVBAR */}
      <div
        className="bg-white flex w-full justify-between pr-3 border-b border-gray-400"
        style={{ height: "72px" }}
      >
        <div className="space-x-8 flex">
          <img
            src={Logo}
            alt="orocorp-logo"
            style={{
              height: "72px",
              width: "auto"
            }}
            className="p-5"
          />
          <NavLink
            to={"/requests"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={LoansIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
              // className="p-3"
            />
            <h4 className="text-gray-600">REQUESTS</h4>
          </NavLink>

          <NavLink
            to="/profile"
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={ProfileIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">PROFILE</h4>
          </NavLink>

          <NavLink
            to={"/support"}
            activeClassName="partnerPrimaryNavClassName"
            className="pt-3"
          >
            <img
              src={SupportIcon}
              alt="loans icon"
              style={{
                height: "1.5rem",
                width: "auto",
                marginBottom: "0.25rem",
                filter: "grayscale(100%)"
              }}
            />
            <h4 className="text-gray-600">SUPPORT</h4>
          </NavLink>
        </div>

        <div className="flex justify-center items-center space-x-4">
          <LogoutTimer/>
          <h4 className="capitalize">{currentBankName} - Bank Checker</h4>

          <button
            className="btn-danger border-none"
            style={{ height: "32px", lineHeight: "16px", outline: "none" }}
            onClick={() => setLogoutConfirmModalOpen(true)}
          >
            Log out
          </button>
        </div>
      </div>
      {/* PRIMARY NAVBAR */}

      {/* SECONDARY NAVBAR  */}
      <div
        className="bg-white flex w-full space-x-8 px-3 border-b border-gray-400"
        style={{ height: "48px" }}
      >
        <div className="flex items-center space-x-2">
          <div className="m-1 pr-4 border-r border-black">
            <h2>Manage requests</h2>
          </div>
        </div>

        <div className="flex space-x-8">
          <NavLink
            to="/requests/new"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">NEW</h4>
              <Badge
                count={
                  requestAggregateLoading
                    ? 0
                    : new_requests
                    ? new_requests.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/requests/approved"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">APPROVED</h4>
              <Badge
                count={
                  requestAggregateLoading
                    ? 0
                    : approved_requests
                    ? approved_requests.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          <NavLink
            to="/requests/rejected"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">REJECTED</h4>
              <Badge
                count={
                  requestAggregateLoading
                    ? 0
                    : rejected_requests
                    ? rejected_requests.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink>

          {/* <NavLink
            to="/requests/all"
            className="flex items-end space-x-2"
            style={{ borderBottom: "3px solid #fff" }}
            activeClassName="partnerNavlinkClassName"
          >
            <div className="flex items-center space-x-2 mb-1">
              <h4 className="text-gray-600">ALL</h4>
              <Badge
                count={
                  rateAggregateLoading
                    ? 0
                    : new_requests && approved_requests && rejected_requests
                    ? new_requests.length +
                      approved_requests.length +
                      rejected_requests.length
                    : 0
                }
              ></Badge>
            </div>
          </NavLink> */}
        </div>
      </div>
      {/* SECONDARY NAVBAR  */}

      <LogoutModal
        isOpen={isLogoutConfirmModalOpen}
        setOpen={setLogoutConfirmModalOpen}
      />
    </>
  );
};

const PartnerNav = props => {
  return (
    <Can
      role={getValueFromCookie("user")?.["X_Hasura_Default_Role"]}
      perform="cst_navbar"
      yes={() => <CSTNav />}
      no={() => (
        <Can
          role={
            getValueFromCookie("user")?.["X_Hasura_Default_Role"]
          }
          perform="blc_navbar"
          yes={() => <BranchCheckerVerifierNav />}
          no={() => (
            <Can
              role={
                getValueFromCookie("user")?.["X_Hasura_Default_Role"]
              }
              perform="blv_navbar"
              yes={() => <BranchCheckerVerifierNav />}
              no={() => (
                <Can
                  role={
                    getValueFromCookie("user")?.["X_Hasura_Default_Role"]
                  }
                  perform="maker_navbar"
                  yes={() => <MakerNavbar />}
                  no={() => <CheckerNavbar />}
                />
              )}
            />
          )}
        />
      )}
    />
  );
};

const Nav = props => {
  return <PartnerNav props={props} />;
  // return null;
};

Nav.protoTypes = {
  title: PropTypes.string
};
export default Nav;
