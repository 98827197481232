import axios from 'axios';

const ApiPrototype = {
  // only methods common for all baseURLs
  get(path, config = {}) {
    return this.api.get(path, config);
  },
  post(path, payload, config = {}) {
    return this.api.post(path, payload, config);
  },
  put(path, payload, config = {}) {
    return this.api.put(path, payload, config);
  },
};

function errorInterceptor(err) {
  switch(err?.response.status.toString()[0]) {
  case '4':
    if(err?.response?.data?.message === 'jwt expired' || err?.response?.data?.message === 'invalid signature') throw err;
    // store.dispatch(pushNotification({ text: err?.response?.data?.message, type: "warning", key: notificationCount++ }));
    break;
  case '5':
    // store.dispatch(pushNotification({ text: "Something went wrong.", type: "warning", key: notificationCount++ }));
    break;
  default:
  }
  throw err;
}

function responseInterceptor(res) {
  return res?.data;
}

function ApiInit(config) {
  const instance = Object.create(ApiPrototype);
  instance.api = axios.create(config);
  instance.api.interceptors.response.use(responseInterceptor, errorInterceptor);
  return instance;
}

const ApiUtils = ApiInit({});
export default ApiUtils;
