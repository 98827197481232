import React from "react";
import SupportImg from "../assets/support-img.png";
import ConfirmCallback from "../components/Modals/confirmCallback";

const Support = props => {
  return (
    <div
      className="space-y-8 pt-20"
      style={{ width: "30%", margin: "0 auto", textAlign: "center" }}
    >
      <h1>Request a support call</h1>
      <div>
        <img
          src={SupportImg}
          alt="warning"
          style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
        />
      </div>
      <p>
        If you're facing any issues or have any questions, please reach out to
        us. We will get in touch with you as soon as possible.
      </p>
      <ConfirmCallback ctaBtn={true} />
    </div>
  );
};

export default Support;
