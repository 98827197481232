import React, { useState } from "react";
import { Modal as AntModal, notification } from "antd";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import Warning from "../../assets/warning.png";

const REQUEST_CALLBACK = gql`
  mutation requestCallback($message: String!) {
    insert_callback_request(message: $message) {
      id
      message
    }
  }
`;

const ConfirmCallbackModal = props => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [requestCallback] = useMutation(REQUEST_CALLBACK, {
    onError(error) {
      error.graphQLErrors.map(error => {
        // console.log(error);
        notification.error({
          message: "Error!",
          description: "Something went wrong. Please try again after some time"
        });
        setModalOpen(false);
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Callback requested"
      });
      setModalOpen(false);
    }
  });

  return (
    <>
      {props.ctaBtn ? (
        <button className="cta py-2" onClick={() => setModalOpen(true)}>
          Request a callback
        </button>
      ) : (
        <button
          type="submit"
          className="btn-white"
          onClick={() => setModalOpen(true)}
        >
          Oro Support
        </button>
      )}

      <AntModal
        width="400px"
        className="modal-title-center"
        //   title="Store gold"
        visible={isModalOpen}
        footer={null}
      >
        <div className="space-y-8 mx-auto text-center">
          <h1>Confirm callback request</h1>{" "}
          <div>
            <img
              src={Warning}
              alt="warning"
              style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
            />
          </div>
          <p>
            Please enter your issue and Oro will get in touch with you as soon
            as possible.
          </p>
          {/* <ReactInput placeholder="Enter your issue" /> */}
          <input
            id="message"
            className="border px-4 py-2 w-full"
            placeholder="Enter your issue"
            autoComplete="off"
          />
          <div className="flex space-x-4 justify-center ">
            <button
              className="cta"
              onClick={async () => {
                let message = document.getElementById("message").value;
                
                if(!/^((?![()<>/;[\]{}'"]).)*$/gm.test(message)) {
                  notification.error({
                    message: "Error!",
                    description: "Special Characters are not allowed",
                  });
                } else {
                  requestCallback({
                    variables: {
                      message: message
                    }
                  });
                  document.getElementById("message").value = "";
                }
              }}
            >
              Confirm
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </AntModal>
    </>
  );
};

export default ConfirmCallbackModal;
