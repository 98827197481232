// DEPS
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Spin, Steps, Tag, notification, Empty } from "antd";
import moment from "moment";

// COMPONENTS
import Sidebar from "../../../components/Sidebar";
import ErrorPage from "../../../components/ErrorPage";
import ConfirmCallbackModal from "../../../components/Modals/confirmCallback";
import ApproveLoanModal from "../../../components/Modals/approveLoan";
import RejectLoanModal from "../../../components/Modals/rejectLoan";

// VIEWS
import LoanDetails from "../../../views/loanDetails";
import PlanDetails from "../../../views/planDetails";
import GoldAppraisalDetails from "../../../views/goldAppraisalDetails";
import UserKYCDetails from "../../../views/userKYCDetails";
import Timestamps from "../../../views/timestamps";
import UserBankAccountDetails from "../../../views/userBankAccountDetails";

// APIS
import { APPROVE_LOAN, GET_LOAN_BY_ID, REJECT_LOAN } from "../graphql";
import AuthUtils from "../../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../../utils/local-storage";
import { getValueFromCookie } from "../../../utils/cookies";

const { Step } = Steps;

const CSTAllLoans = props => {
  const [trigger, setTrigger] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [isApproveLoanModalOpen, setApproveLoanModalOpen] = useState(false);
  const [isRejectLoanModalOpen, setRejectLoanModalOpen] = useState(false);

  const [rejectLoan] = useMutation(REJECT_LOAN, {
    onError(error) {
      error.graphQLErrors.map(error => {
        // console.log(error);
        return notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again"
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan rejected"
      });
      setSelectedLoan(0);
    }
  });

  const [approveLoan] = useMutation(APPROVE_LOAN, {
    onError(error) {
      error.graphQLErrors.map(error => {
        // console.log(error);
        return notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again"
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Loan approved"
      });
      setApproveLoanModalOpen(false);
      setTrigger(true);
    }
  });

  const TriggerApproveLoan = () => {
    approveLoan({
      variables: {
        loan_id: loanData.loans_by_pk.id,
        approved_by: getValueFromCookie("user")?.id,
        approved_at: moment(),
        visit_id: loanData.visits[0].id
      }
    });
  };

  const TriggerRejectLoan = reason => {
    // console.log(props);
    rejectLoan({
      variables: {
        loan_id: loanData.loans_by_pk.id,
        visit_id: loanData.visits[0].id,
        visit_status: "OD_LOAN_REJECTED_BY_PARTNER_BRANCH",
        reason: reason
      }
    });
  };

  const [
    getLoanById,
    { loading: loanDataLoading, data: loanData, error: loanQueryError }
  ] = useLazyQuery(GET_LOAN_BY_ID);

  useEffect(() => {
    getLoanById({ variables: { id: selectedLoan || 0 } });
  }, [selectedLoan]);

  if (loanQueryError) return <ErrorPage />;

  return (
    <div className='flex flex-row space-x-4'>
      {/* Sidebar */}
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      {/* Sidebar */}

      <div className='w-full space-y-8'>
        <div className='flex w-full justify-between space-x-16'>
          <div className='w-5/6 flex space-x-8'>
            {loanData ? (
              loanData.loans_by_pk !== null ? (
                loanData.loans_by_pk.loan_status === 'CST_REJECTED' ? (
                  <Steps current={2} size='small'>
                    <Step title='REQUEST RECEIVED' />
                    <Step title='LOAN REJECTED' />
                  </Steps>
                ) : (
                  <Steps
                    current={
                      loanData.loans_by_pk.loan_status === 'OROCORP_APPROVED'
                        ? 1
                        : 4
                    }
                    size='small'
                  >
                    <Step title='REQUEST RECEIVED' />
                    <Step title='LOAN APPROVED' />
                    <Step title='LOAN SANCTIONED' />
                    <Step title='MOVED TO BRANCH' />
                  </Steps>
                )
              ) : null
            ) : null}
          </div>

          {/* <button onClick={() => play()}>Click me</button> */}

          {loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='w-1/6 space-y-1 text-right'>
                <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                <p className='text-gray-600 font-semibold'>
                  {moment(loanData.loans_by_pk.created_at).format(
                    'DD-MM-YYYY HH:mm'
                  )}
                </p>
                {loanData.loans_by_pk.loan_status === 'OROCORP_APPROVED' ? (
                  <Tag color={'orange'} key='TO BE APPROVED'>
                    TO BE APPROVED
                  </Tag>
                ) : loanData.loans_by_pk.loan_status === 'CST_REJECTED' ? (
                  <Tag color={'red'} key='REJECTED'>
                    REJECTED
                  </Tag>
                ) : (
                  <Tag color={'green'} key='SANCTIONED'>
                    SANCTIONED
                  </Tag>
                )}
              </div>
            ) : null
          ) : null}
        </div>

        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <>
              <div className='space-y-8'>
                <Timestamps loan_id={loanData.loans_by_pk.id} />
                <div className='flex w-full justify-between items-center'>
                  <div className='flex space-x-8 items-center'>
                    {loanData.loans_by_pk.loan_status === 'OROCORP_APPROVED' ? (
                      <button
                        type='submit'
                        className='btn-primary hover:shadow-lg'
                        onClick={() => setApproveLoanModalOpen(true)}
                      >
                        Approve loan
                      </button>
                    ) : null}

                    {loanData.loans_by_pk.loan_status === 'OROCORP_APPROVED' ? (
                      <button
                        className='btn-danger hover:shadow-lg'
                        onClick={() => setRejectLoanModalOpen(true)}
                      >
                        Reject Loan
                      </button>
                    ) : null}

                    {loanData.loans_by_pk.loan_status !== 'CLOSED' ? (
                      <ConfirmCallbackModal />
                    ) : null}
                  </div>
                  {/* <DownloadLoanDetailsButton
                    loan_id={loanData.loans_by_pk.id}
                  /> */}
                </div>
                <div>
                  {/* {console.log("check", loanDataLoading, loanData)} */}

                  <div className='w-full flex space-x-8'>
                    {/* Loan request */}
                    <LoanDetails
                      loan_number={loanData.loans_by_pk.loan_number}
                      loan_type={loanData.loans_by_pk.loan_type.loan_type}
                      loan_amount={
                        loanData.loans_by_pk.od_amount
                          ? loanData.loans_by_pk.od_amount.credit_limit
                          : loanData.loans_by_pk.gl_amount
                          ? loanData.loans_by_pk.gl_amount.loan_amount
                          : null
                      }
                      gold_gross_weight={parseFloat(
                        loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                          return acc + goldItem.gross_weight;
                        }, 0)
                      ).toFixed(2)}
                      gold_net_weight={parseFloat(
                        loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                          return acc + goldItem.net_weight;
                        }, 0)
                      ).toFixed(2)}
                      gold_actual_net_weight={parseFloat(
                        loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                          return acc + goldItem.actual_net_weight;
                        }, 0)
                      ).toFixed(2)}
                    />
                    {/* Loan request */}

                    {/*Plan details */}
                    <PlanDetails
                      scheme_name={loanData.loans_by_pk.plan.scheme_name}
                      min_amt={loanData.loans_by_pk.plan.minimum_amount}
                      max_amt={loanData.loans_by_pk.plan.maximum_amount}
                      tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                        (total, obj) => obj.intervals + total,
                        0
                      )} months`}
                      interest={`   ${Math.max.apply(
                        Math,
                        loanData.loans_by_pk.plan.slab_details.map(function (
                          o
                        ) {
                          return o.lender_interest_rate;
                        })
                      )} %`}
                      interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                      interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                      loan_type={loanData.loans_by_pk.loan_type.loan_type}
                    />
                    {/* Plan details */}
                  </div>
                </div>

                {/* Gold details */}
                <div className='space-y-4'>
                  <GoldAppraisalDetails id={loanData.loans_by_pk.id} />
                </div>
                {/* Gold details */}

                {/* User Info & KYC */}
                <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
                {/* User Info & KYC */}

                <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />
              </div>

              {loanData ? (
                <ApproveLoanModal
                  loan_id={loanData.loans_by_pk.id}
                  isOpen={isApproveLoanModalOpen}
                  setOpen={setApproveLoanModalOpen}
                  loanAmount={
                    loanData.loans_by_pk.od_amount
                      ? `₹${loanData.loans_by_pk.od_amount.credit_limit}`
                      : `₹${loanData.loans_by_pk.gl_amount.loan_amount}`
                  }
                  approveLoan={TriggerApproveLoan}
                />
              ) : null}

              {loanData ? (
                <RejectLoanModal
                  isOpen={isRejectLoanModalOpen}
                  setOpen={setRejectLoanModalOpen}
                  rejectLoan={TriggerRejectLoan}
                />
              ) : null}
            </>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>
    </div>
  );
};

export default CSTAllLoans;
