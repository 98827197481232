import React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadLoanDetails from "../components/export/loan";

const GET_LOAN_DETAILS_FOR_EXPORT = gql`
  query getLoanDetailsForExport($id: Int!) {
    loans_by_pk(id: $id) {
      id
      loan_number
      account_number
      rejection_reason
      loan_type
      loan_status
      approved_at
      stored_at
      released_at
      checker_confirmed_at
      od_amount {
        id
        credit_limit
      }
      gl_amount {
        id
        loan_amount
      }
      kyc {
        id
        legal_name
        id_proof
        address
        address_proof
        live_photo
        mothers_name
        fathers_name
        pan_number
        pan_proof
        date_of_birth
        marital_status
        gender
      }
      branch {
        id
        name
      }
      plan {
        id
        scheme_name
        tenure
        interest_slab_1
        minimum_amount
        maximum_amount
      }
      rate {
        id
        ma_rate
      }
      customer {
        id
        bank_account_details {
          id
          bank_account_name
          bank_account_number
          bank_name
          ifsc
          bank_branch
          bank_account_proof
        }
      }
      golds {
        id
        gold_display_id
        quality
        quantity
        gross_weight
        net_weight
        stone_deduction
        type
        pickup_gold_images
        pickup_seal_id
      }
    }
  }
`;

const ExportLoanDetailsButton = props => {
  const {
    loading: loanDetailsLoading,
    data: loanData,
    error: loanDetailsQueryError
  } = useQuery(GET_LOAN_DETAILS_FOR_EXPORT, {
    variables: {
      id: props.loan_id
    }
  });

  if (loanDetailsLoading) return null;

  // if (loanDetailsQueryError) console.log(loanDetailsQueryError);

  return (
    <PDFDownloadLink
      document={
        <DownloadLoanDetails
          data={{
            loan_id: loanData.loans_by_pk.loan_number,
            number_of_items: loanData.loans_by_pk.golds.reduce(
              (acc, goldItem) => {
                return acc + goldItem.quantity;
              },
              0
            ),
            total_weight: `${parseFloat(
              loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                return acc + goldItem.gross_weight;
              }, 0)
            ).toFixed(2)}`,
            golds: loanData.loans_by_pk.golds,
            // goldImages: goldImages,
            rate: loanData.loans_by_pk.rate.ma_rate,
            plans: loanData.loans_by_pk.plan,
            kyc: loanData.loans_by_pk.kyc,
            loan_details: {
              loan_type: loanData.loans_by_pk.loan_type.loan_type,
              loan_amount: loanData.loans_by_pk.od_amount
                ? loanData.loans_by_pk.od_amount.credit_limit
                : loanData.loans_by_pk.gl_amount
                ? loanData.loans_by_pk.gl_amount.loan_amount
                : null
            },
            plan_details: {
              scheme_name: loanData.loans_by_pk.plan.scheme_name,
              tenure: `${loanData.loans_by_pk.plan.tenure} months`,
              minimum_amount: loanData.loans_by_pk.plan.minimum_amount,
              maximum_amount: loanData.loans_by_pk.plan.maximum_amount,
              interest_slab_1: `${loanData.loans_by_pk.plan.interest_slab_1}%`
            },
            bank_account_details: {
              account_name:
                loanData.loans_by_pk.customer.bank_account_details[0]
                  .bank_account_name,
              account_number:
                loanData.loans_by_pk.customer.bank_account_details[0]
                  .bank_account_number,
              bank_name:
                loanData.loans_by_pk.customer.bank_account_details[0].bank_name,
              bank_brank:
                loanData.loans_by_pk.customer.bank_account_details[0]
                  .bank_branch,
              ifsc: loanData.loans_by_pk.customer.bank_account_details[0].ifsc,
              bank_account_proof:
                loanData.loans_by_pk.customer.bank_account_details[0]
                  .bank_account_proof
            }
          }}
        />
      }
      fileName={`Loan details for loan ID: ${loanData.loans_by_pk.loan_number}`}
      style={{
        textDecoration: "none",
        color: "black",
        backgroundColor: "#f7c366",
        padding: "0.5rem 1rem 0.5rem 1rem",
        height: "fit-content",
        boxShadow: "0 0 3px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px"
        // border: "1px solid #f7c366"
      }}
    >
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : "Export loan documents"
      }
    </PDFDownloadLink>
  );
};

export default ExportLoanDetailsButton;
