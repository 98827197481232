import { addCookie, deleteAllCookies, getValueFromCookie } from "./cookies";
import { clearAllLocalStorage  } from "./local-storage";

const AuthUtils = {
  getToken() {
    return getValueFromCookie("token");
  },
  getRefreshToken() {
    return getValueFromCookie("refreshToken");
  },
  setToken(token) {
    addCookie("token", token);
  },
  storeAuthTokens(data) {
    addCookie("token", data?.token);
    addCookie("refreshToken", data?.refreshToken);
  },
  logout() {
    deleteAllCookies();
    clearAllLocalStorage();
    window.location.reload();
  },
  isLoggedIn() {
    if (!!getValueFromCookie("token") && !!getValueFromCookie("refreshToken"))
      return true;
    return false;
  },
};

export default AuthUtils;
