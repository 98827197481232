import { Input } from "antd";
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import "./form.css";
const ReactInput = props => {
  const [field, meta] = useField(props);
  return (
    <div className="text-red-500 text-left space-y-2">
      {props.label && (
        <label className="text-gray-800 font-semibold text-base">
          {props.label}
        </label>
      )}

      {!props.ispassword && (
        <Input
          {...field}
          {...props}
          size="large"
          className={`py-2 text-gray-900 leading-tight ant-input-bg focus:outline-none focus:shadow-outline rounded-none ${
            !props.showError && meta.touched && meta.error
              ? "border-red-500"
              : ""
          } `}
        />
      )}
      {props.ispassword && (
        <div>
          <Input.Password
            {...field}
            {...props}
            size="large"
            ispassword={`${props.ispassword}`}
            className={`text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded-none ${
              !props.showError && meta.touched && meta.error
                ? "border-red-500"
                : ""
            }`}
          />
        </div>
      )}
      {/* {props.showError && ( */}
      <div style={{ minHeight: "21px" }}>
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
      {/* )} */}
    </div>
  );
};
ReactInput.defaultProps = {
  showError: true,
  autoComplete: "off",
};
ReactInput.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  ispassword: PropTypes.bool,
  showError: PropTypes.bool,
  autoComplete: PropTypes.bool,
};
export default ReactInput;
