import React, { useState } from "react";
import { Modal as AntModal, Radio, notification } from "antd";
import Warning from "../../assets/warning.png";

const RejectLoanModal = props => {
  const [reason, setReason] = useState(null);
  return (
    <AntModal
      width="400px"
      className="modal-title-center"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <div>
          <h1>Approve Loan</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>
        <p>
          Please enter a valid reason for rejecting the user's loan request.
        </p>
        <input
          className="border px-4 py-2 w-full"
          placeholder="Enter your reason"
          autoComplete="off"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <div className="flex space-x-4 justify-center">
          <button
            className="cta"
            onClick={() => {
              if (reason === null) {
                notification.error({
                  message: "Error!",
                  description: "Please select a reason"
                });
              } else {
                props.rejectLoan(reason);
              }
            }}
          >
            Reject Loan
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default RejectLoanModal;
