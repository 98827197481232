import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import DownloadAgentDetails from "../components/export/agent";
import Carousel, { Modal, ModalGateway } from "react-images";
import { get_image_url } from "../utils";

const GET_VISIT_BY_LOAN_ID = gql`
  query getVisitByLoanID($id: Int!) {
    visits(where: { loan_id: { _eq: $id } }) {
      id
      visit_display_id
      agent_first_name
      agent_last_name
      agent_mobile_number
      agent_photo
      agent_id
      agent_address
      agent_secondary_number
    }
  }
`;

const AgentDetails = (props) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const [pdfUrl, setPdfUrl] = useState([]);

  // console.log("props", props);

  const {
    loading: agentDetailsLoading,
    data: agentDetails,
    error: agentDetailsQueryError,
  } = useQuery(GET_VISIT_BY_LOAN_ID, {
    variables: {
      id: props.loan_id,
    },
  });

  useEffect(() => {
    async function getUrl() {
      if (agentDetails !== undefined) {
        let images = await Promise.all(
          agentDetails.visits.map(async (visit) => {
            const valid_url = await get_image_url({
              url: [visit.agent_photo],
            });

            return valid_url;
          })
        );

        setPdfUrl(images);
      }
    }

    getUrl();
  }, [agentDetails]);

  if (agentDetailsQueryError) return <div>Error</div>;

  if (agentDetailsLoading) return null;

  // console.log("agent details", agentDetails);

  if (!agentDetails) return null;

  return (
    <div className="space-y-2">
      <h4>AGENT DETAILS</h4>
      <div className="space-y-4">
        {agentDetails.visits.map((visit, index) => {
          return (
            <div className="w-full flex justify-between items-center">
              <div className="space-y-2 w-2/3">
                <div className="border bg-white flex p-2 w-full">
                  <div className="m-3 w-1/4 break-all border-r">
                    <h5 className="text-xs">AGENT NAME</h5>
                    <p className="text-base font-bold text-black">
                      {visit.agent_first_name}
                      {visit.agent_last_name}
                    </p>
                  </div>

                  <div className="m-3 w-1/4 break-all border-r">
                    <h5 className="text-xs">AGENT ID</h5>
                    <p className="text-base font-bold text-black">{visit.agent_id}</p>
                  </div>

                  <div className="m-3 w-1/4 break-all border-r">
                    <h5 className="text-xs">AGENT MOBILE NO.</h5>
                    <p className="text-base font-bold text-black">
                      {visit.agent_mobile_number}
                    </p>
                  </div>

                  <div className="m-3 w-1/4 break-all ">
                    <h5 className="text-xs">VISIT ID</h5>
                    <p className="text-base font-bold text-black">
                      {visit.visit_display_id}
                    </p>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="text-xs btn-white"
                onClick={async () => {
                  // console.log("pic", visit.agent_photo);
                  const valid_url = await get_image_url({
                    url: [visit.agent_photo],
                  });

                  setImages([
                    {
                      source: valid_url,
                    },
                  ]);
                  setIsImageModalOpen(true);
                }}
              >
                View Agent Photo
              </button>

              {/* Pdf is dynamically generated when the user clicks on the button */}
              <button
                className="text-xs cta px-4 py-2"
                onClick={async () => {
                  // const props = await getProps();
                  const doc = (
                    <DownloadAgentDetails
                      data={{
                        name: `${visit.agent_first_name} ${visit.agent_last_name}`,
                        id: visit.agent_id,
                        number: visit.agent_mobile_number,
                        picture: pdfUrl[index],
                        address: visit.agent_address,
                        secondary_contact_number: visit.agent_secondary_number,
                      }}
                    />
                  );
                  const asPdf = pdf([]); // {} is important, throws without an argument
                  asPdf.updateContainer(doc);
                  const blob = await asPdf.toBlob();
                  saveAs(
                    blob,
                    `Agent details for visit ID: ${visit.visit_display_id}`
                  );
                }}
              >
                Export agent details
              </button>

              {/* OLD CODE - PDF generation happens when page loads. This was scrapped since this led to too much load on the browser */}

              {/* {agentDetails && pdfUrl.length > 0 ? (
                <PDFDownloadLink
                  document={
                    <DownloadAgentDetails
                      data={{
                        name: `${visit.agent_first_name} ${visit.agent_last_name}`,
                        id: visit.agent_id,
                        number: visit.agent_mobile_number,
                        picture: pdfUrl[index],
                        address: visit.agent_address,
                        secondary_contact_number: visit.agent_secondary_number,
                      }}
                    />
                  }
                  fileName={`Agent details for visit ID: ${visit.visit_display_id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    backgroundColor: "#f7c366",
                    padding: "0.5rem 1rem 0.5rem 1rem",
                    height: "fit-content",
                    boxShadow: "0 0 3px rgba(0, 0, 0, 0.25)",
                    borderRadius: "4px",
                    // border: "1px solid #f7c366"
                  }}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : "Export agent details"
                  }
                </PDFDownloadLink>
              ) : null} */}
            </div>
          );
        })}
      </div>

      <ModalGateway>
        {isImageModalOpen && images.length > 0 ? (
          <Modal
            onClose={() => {
              setIsImageModalOpen(!isImageModalOpen);
            }}
          >
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default AgentDetails;
