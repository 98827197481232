import React from "react";
import Can from "../../components/Can";
import BLCStoredLoans from "./blc/stored";
import BLVStoredLoans from "./blv/stored";
import AuthUtils from "../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const StoredLoans = props => {
  return (
    <Can
      role={getValueFromCookie("user")?.["X_Hasura_Default_Role"]}
      perform="blc"
      yes={() => {
        return <BLCStoredLoans />;
      }}
      no={() => {
        return <BLVStoredLoans />;
      }}
    />
  );
};

export default StoredLoans;
