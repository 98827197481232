import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import Logo from "../../assets/logo@2x.png";
import { version } from "../../../package.json";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Layout = props => {
  return (
    <Spin indicator={antIcon} spinning={props.loading || false}>
      <div className="loginLayout flex justify-center content-center flex-col">
        <div className="loginContainer space-y-4 py-16 px-48">
          <div>
            <img
              className="mx-auto"
              src={Logo}
              style={{ height: "4rem", width: "auto" }}
              alt="orocorp-logo"
            />
          </div>
          <h2 className="text-4xl">Partner Software</h2>
          {props.content}
          {props.children}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "right",
            paddingBottom: 10,
            paddingRight: 10
          }}
        >
          {version}
        </div>
      </div>
    </Spin>
  );
};

export default Layout;
