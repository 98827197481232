import { Empty } from "antd";
import React from "react";

const ErrorPage = () => {
  return <Empty description="Something went wrong"></Empty>;
};

export const EmptyPage = () => {
  return <Empty description="No data" className="pt-8"></Empty>;
};

export default ErrorPage;
