import React from "react";
import { Redirect } from "react-router-dom";
import AuthUtils from "../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const RedirectPage = () => {
  if (
    getValueFromCookie("user")?.["X_Hasura_Default_Role"] !==
      "maker" &&
    getValueFromCookie("user")?.["X_Hasura_Default_Role"] !==
      "checker"
  )
    return <Redirect to="/loans/new-loans" />;
  else if (
    getValueFromCookie("user")?.["X_Hasura_Default_Role"] ===
    "maker"
  )
    return <Redirect to="/update-rate" />;
  else return <Redirect to="/requests/new" />;
};

export default RedirectPage;
