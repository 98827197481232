import React from "react";
import Can from "../../components/Can";
import CSTRejectedLoans from "./cst/rejected";
import BLCAbortedLoans from "./blc/aborted";
import BLVAbortedLoans from "./blv/aborted";
import AuthUtils from "../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const RejectedLoans = props => {
  return (
    <Can
      role={getValueFromCookie("user")?.["X_Hasura_Default_Role"]}
      perform="cst"
      yes={() => {
        return <CSTRejectedLoans />;
      }}
      no={() => {
        return (
          <Can
            role={
              getValueFromCookie("user")?.["X_Hasura_Default_Role"]
            }
            perform="blc"
            yes={() => {
              return <BLCAbortedLoans />;
            }}
            no={() => {
              return <BLVAbortedLoans />;
            }}
          />
        );
      }}
    />
  );
};

export default RejectedLoans;
