import { useEffect, useState } from "react";

const useRateData = (loanNumber) => {
  const [ratePerGram, setRate] = useState(0);

  useEffect(() => {
    if (loanNumber) {
        fetch(`${process.env.REACT_APP_SFL_OPS_URL}/api/v1/rate?loanNumber=${loanNumber}`)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setRate(data?.rate);
            })
            .catch(err => {

                setRate(0);
            });
    } else  {
        setRate(0)
    }
  }, [loanNumber]);

  return { ratePerGram };
};

export default useRateData;
