import React from "react";
import gql from "graphql-tag";
import moment from "moment";
import { useSubscription } from "@apollo/react-hooks";
import GreenCheck from "../assets/green-check.png";
import Loader from "../assets/loader.png";
// import ConfirmCallbackModal from "../components/Modals/confirmCallback";

const GET_LOAN_TIMESTAMPS = gql`
  query getLoanTimestamps($id: Int!) {
    loans_by_pk(id: $id) {
      id
      loan_number
      loan_status
      approved_at
      stored_at
      released_at
      closed_at
      checker_confirmed_at
    }
  }
`;

const Timestamps = props => {
  const { data: timestamps } = useSubscription(GET_LOAN_TIMESTAMPS, {
    variables: {
      id: props.loan_id
    }
  });

  // console.log("timestamps", timestamps);

  if (timestamps === undefined) return null;

  return (
    <div className="flex space-x-8">
      {timestamps.loans_by_pk.checker_confirmed_at ? (
        <div>
          <div className="space-x-2 flex items-center">
            <img
              src={timestamps.loans_by_pk.stored_at ? GreenCheck : Loader}
              alt="check"
              style={{ height: "1rem", width: "1rem" }}
            />
            <h4>
              {timestamps.loans_by_pk.stored_at
                ? "Verifier confirmation complete"
                : "Verifier confirmation in progress"}
            </h4>
          </div>
          <p>
            Checker confirmed on{" "}
            {moment(timestamps.loans_by_pk.checker_confirmed_at).format(
              "HH:mm a"
            )}
          </p>

          {timestamps.loans_by_pk.stored_at ? (
            <p>
              Verifier confirmed on{" "}
              {moment(timestamps.loans_by_pk.stored_at).format("HH:mm a")}
            </p>
          ) : null}
        </div>
      ) : null}

      {timestamps.loans_by_pk.approved_at ? (
        <div>
          <div className="space-x-2 flex items-center">
            <img
              src={GreenCheck}
              alt="check"
              style={{ height: "1rem", width: "1rem" }}
            />
            <h4>Fund transfer complete</h4>
          </div>
          <p>
            CST Approved on{" "}
            {moment(timestamps.loans_by_pk.approved_at).format("HH:mm a")}
          </p>
          <p>
            Transfer completed on{" "}
            {moment(timestamps.loans_by_pk.approved_at).format("HH:mm a")}
          </p>
        </div>
      ) : null}

      {/* {timestamps.loans_by_pk.stored_at && timestamps.loans_by_pk.stored_at ? (
        <div>
          <div className="space-x-2 flex items-center">
            <img
              src={GreenCheck}
              alt="check"
              style={{ height: "1rem", width: "1rem" }}
            />
            <h4>Disbursement complete</h4>
          </div>
          <p>
            Disbursement Approved on{" "}
            {moment(timestamps.loans_by_pk.stored_at).format("HH:mm a")}
          </p>
          <p>
            Disbursement completed on{" "}
            {moment(timestamps.loans_by_pk.stored_at).format("HH:mm a")}
          </p>
        </div>
      ) : null} */}

      {timestamps.loans_by_pk.released_at ? (
        <div>
          <div className="space-x-2 flex items-center">
            <img
              src={timestamps.loans_by_pk.closed_at ? GreenCheck : Loader}
              alt="check"
              style={{ height: "1rem", width: "1rem" }}
            />
            <h4>
              {timestamps.loans_by_pk.closed_at
                ? "Loan closure complete"
                : "Loan closure in progress"}
            </h4>
          </div>
          <p>
            Gold release completed on{" "}
            {moment(timestamps.loans_by_pk.released_at).format("HH:mm a")}
          </p>

          {timestamps.loans_by_pk.closed_at ? (
            <p>
              Loan closure completed on{" "}
              {moment(timestamps.loans_by_pk.closed_at).format("HH:mm a")}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Timestamps;
