//DEPS
import React from 'react';

// ASSETS
import Tick from '../assets/tick.png';

function Steps({ count, steps }){
    return (
        <div className="flex flex-col w-full items-center">
            <div className={`relative z-30 h-px w-10/12 border border-black`} style={{top: '14px'}}>
            </div>
            <div className="flex justify-between w-full">
                {steps.map((step, i) => <div className="flex flex-col items-center">
                    <div className={`${i<=count ? 'bg-yellow-500' : 'bg-gray-300'} z-40 border border-black flex justify-center p-2 rounded-full`}>
                        <img src={Tick} alt="tick logo" />
                    </div>
                    <p className={`text-xs text-center w-20 leading-4 ${i>count ? 'text-gray-600' : ''}`}>
                        {step}
                    </p>
                </div>)}
            </div>
        </div>
    );

}

export default Steps;
