import axios from "axios";
import AuthUtils from "./AuthUtils";

export const getToken = () => {
  if (AuthUtils.getToken()) {
    return AuthUtils.getToken();
  }
  return false;
};

export const genColumnKey = (key, dataIndex, index) => {
  if (key) {
    return key;
  }
  if (!key && dataIndex) {
    if (Array.isArray(dataIndex)) {
      return dataIndex.join("-");
    }
    return dataIndex;
  }
  return `${index}`;
};
export const avatarMap = (name, maxInitials) => {
  return name
    .split(/\s/)
    .map(function (part) {
      return part.substring(0, 1).toUpperCase();
    })
    .filter(function (v) {
      return !!v;
    })
    .slice(0, maxInitials)
    .join("");
};

export const get_image_url = async (props) => {

  console.log('props:', props);

  const valid_url = await axios({
    method: "post",
    url: `${process.env.REACT_APP_REGISTER_API}/api/v1/images`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${AuthUtils.getToken()}`,
      "X-Renewal-Secret": "register-master-auth"
    },
    data: {
      urls: props?.url
    },
  })
    .then((response) => {
      return response?.data?.signedUrls?.[0];
    })
    .catch((err) => {
      // console.log("err: ", err);
    });

  if (valid_url === undefined) {
    // console.log("Error");
  } else if (valid_url?.data?.errors) {
      console.log("Error", valid_url?.data.errors[0], props?.url?.[0]);
  } else {
    return valid_url;
  }
};

export default class Utils {
  static arrayCheck(arr) {
    if(!Array.isArray(arr))
      return [];
    return arr;
  }
}