import gql from "graphql-tag";

const GET_USER_DATA = gql`
  query getUserNameAndRole($notCST: Boolean!) {
    users {
      id
      first_name
      last_name
      role
      notification_sound
      branch @include(if: $notCST) {
        id
        name
      }
    }
  }
`;

const LOAN_AGGREGATE_DATA = gql`
  subscription loanAggregateDataForBLC($loan_status: [String!]) {
    loans_aggregate(where: {loan_status: {_in: $loan_status}}) {
      aggregate {
        count
      }
      nodes {
        id
        loan_number
        loan_status
        renew_from_loan
      }
    }
  }
`;

const REQUEST_AGGREGATE_DATA = gql`
  subscription rateAggregateData {
    approvals_changelog_aggregate {
      nodes {
        id
        status
      }
    }
  }
`;

export { GET_USER_DATA, LOAN_AGGREGATE_DATA, REQUEST_AGGREGATE_DATA };
