// import { Spin } from "antd";
import React, { useState, useEffect } from "react";
// import { hot } from "react-hot-loader";
// import './less/index.css'
// import { hot } from "react-hot-loader";
import Routes from "./routes";
import UnauthenticatedRoutes from "./routes/unauthenticated";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import AuthApi from "./utils/ApiUtils/AuthApi";
import AuthUtils from "./utils/AuthUtils";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated && !!AuthUtils.getToken()) {
      AuthApi.verifyToken().then((data) => {
        setAuthenticated(true);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        AuthUtils.logout();
      });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Spin indicator={antIcon} spinning={loading}>
      <div className="App Container bg-gray-100">
        {isAuthenticated ? (
          <Routes />
        ) : (
          <UnauthenticatedRoutes setAuthenticated={setAuthenticated} />
        )}
      </div>
    </Spin>
  );
};

export default App;
