import AuthUtils from '../AuthUtils';
import ApiUtils from './index';

const baseURL = process.env.REACT_APP_AUTH_ENDPOINT;

const AuthApi = {
  verifyToken(userToken = '') {
    return ApiUtils.post('/api/v1/auth/verify-token', {}, {
      baseURL,
      headers: {
        Authorization: `JWT ${userToken || AuthUtils.getToken()}`,
      }
    }).catch(async () => {
      await this.refreshToken();
      return ApiUtils.post('/api/v1/auth/verify-token', {}, {
        baseURL,
        headers: {
          Authorization: `JWT ${AuthUtils.getToken()}`,
        }
      });
    });
  },
  refreshToken() {
    return ApiUtils.post('/api/v1/ops/refresh-token', {}, {
      baseURL,
      headers: {
        'refresh-token': AuthUtils.getRefreshToken(),
        'client-id': "opsapp",
      }
    }).then(({ user: { token } }) => AuthUtils.setToken(token));
  },
  logout() {
    return ApiUtils.post('/api/v1/auth/logout', {}, {
      baseURL,
      headers: {
        Authorization: `JWT ${AuthUtils.getToken()}`,
        "refresh-token": `${AuthUtils.getRefreshToken()}`,
      },
    });
  },
};

export default AuthApi;
