import React from "react";
import { Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { Currency } from "../components/Number";

const PlanDetails = props => {
  // console.log("props", props);
  return (
    <div className="space-y-2 w-1/2">
      <h4>{props.title ?? "PLAN DETAILS"}</h4>

      <div className="border bg-white">
        {/* ROW 1 */}
        <div className="border-b flex p-4 w-full">
          <div className="w-1/2">
            <h5 className="text-xs">PLAN NAME</h5>
            <p className="text-base font-bold text-black">{props.scheme_name}</p>
          </div>
          <div className="w-1/2">
            <h5 className="text-xs">MIN-MAX AMOUNT</h5>
            <p className="text-base font-bold text-black">
              ₹ <Currency number={props.min_amt} /> - ₹{' '}
              <Currency number={props.max_amt} />
            </p>
          </div>
        </div>
        {/* ROW 1 */}

        {/* ROW 2 */}
        <div className="border flex bg-white">
          <div className="flex p-4 w-full">
            <div className="w-1/2">
              <h5 className="text-xs">TENURE</h5>
              <p className="text-base font-bold text-black">{props.tenure}</p>
            </div>
            <div className="w-1/2">
              <div className="flex space-x-1 items-center">
                <h5 className="text-xs">INTEREST P.A </h5>
                <Tooltip
                  title={
                    <div>
                      <p>Jumping interest applicable for this loan: </p>
                      <p>
                        {" "}
                        <span className="font-bold"> 30 days </span>-{" "}
                        {props.interest} p.a
                      </p>
                      <p>
                        {" "}
                        <span className="font-bold"> 30-60 days </span>-{" "}
                        {props.interest_slab_2} p.a
                      </p>
                      <p>
                        {" "}
                        <span className="font-bold"> 60-90 days </span>-{" "}
                        {props.interest_slab_3} p.a
                      </p>
                    </div>
                  }
                  className={props.loan_type === "GL" ? null : "hidden"}
                >
                  <InfoCircleFilled />
                </Tooltip>
              </div>

              <p className="text-base font-bold text-black">{props.interest} </p>
            </div>
          </div>
        </div>
        {/* ROW 2 */}
      </div>
    </div>
  );
};

export default PlanDetails;
