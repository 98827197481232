import React from 'react';
import Countdown from 'react-countdown';
import jwtDecode from 'jwt-decode';
import AuthUtils from '../utils/AuthUtils';
import { getValueFromLocalStorage } from '../utils/local-storage';
import { getValueFromCookie } from '../utils/cookies';

const LogoutTimer = () => {
	
	let decoded = getValueFromCookie("user")?.exp || 1719341826;
	
	const Completionist = () => <span>Session expired!</span>;
	
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Logout the user when timer runs out
			return <Completionist />;
		} else {
			// Render a countdown
			return <span>{hours}:{minutes}:{seconds}</span>;
		}
	};
	
	return (
		<div className="capitalize">
			<Countdown
				date={decoded.exp * 1000}
				renderer={renderer}
			/>
		</div>
	)
}

export default LogoutTimer
