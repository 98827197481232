import React, { useState } from "react";
import { Modal as AntModal, Radio, notification } from "antd";
import Warning from "../../assets/warning.png";
import { Currency } from "../Number";

const RejectRate = props => {
  const [reason, setReason] = useState(null);
  return (
    <AntModal
      width="400px"
      className="modal-title-center"
      //   title="Store gold"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <div>
          <h1>Approve Loan</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>
        <p className="text-black text-base w-3/4 mx-auto">
          Gold rate is set to{" "}
          <span className="font-bold">
            ₹ <Currency number={props.rate} />
          </span>{" "}
          Are you sure you want to reject the gold rate?
          {/* mentioned below. */}
        </p>
        <div className="flex space-x-4 justify-center">
          <button
            className="cta"
            onClick={() => {
              props.rejectRate();
            }}
          >
            Reject
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default RejectRate;
