// DEPS
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Spin, Steps, Tag, Empty } from "antd";
import moment from "moment";

// COMPONENTS
import ConfirmCallbackModal from "../../../components/Modals/confirmCallback";
import ErrorPage from "../../../components/ErrorPage";
import Sidebar from "../../../components/Sidebar";

// VIEWS
import GoldAppraisalDetails from "../../../views/goldAppraisalDetails";
import UserKYCDetails from "../../../views/userKYCDetails";
import DownloadLoanDetailsButton from "../../../views/exportLoanDetails";
import UserBankAccountDetails from "../../../views/userBankAccountDetails";
import LoanDetails from "../../../views/loanDetails";
import PlanDetails from "../../../views/planDetails";

// APIS
import { GET_LOAN_BY_ID } from "../graphql";

const { Step } = Steps;

const CSTRejectedLoans = props => {
  const [selectedLoan, setSelectedLoan] = useState(0);

  const [
    getLoanById,
    { loading: loanDataLoading, data: loanData, error: loanQueryError }
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    getLoanById({ variables: { id: selectedLoan } });
  }, [selectedLoan]);

  if (loanQueryError) return <ErrorPage />;

  return (
    <div className='flex flex-row space-x-4'>
      {/* Sidebar */}
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        loan_statuses={['REJECTED']}
      />
      {/* Sidebar */}

      <div className='w-full space-y-8'>
        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <>
              <div className='flex w-full justify-between space-x-16'>
                <div className='w-2/6'>
                  <Steps current={2} size='small'>
                    <Step title='REQUEST RECEIVED' />
                    <Step title='LOAN REJECTED' />
                  </Steps>
                </div>

                <div className='w-1/6 space-y-1 text-right'>
                  <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                  <p className='text-gray-600 font-semibold'>
                    {moment(loanData.loans_by_pk.created_at).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </p>
                  <Tag color={'red'} key='REJECTED'>
                    REJECTED
                  </Tag>
                </div>
              </div>
              <div className='space-y-8'>
                <div className='flex w-full justify-between items-center'>
                  <div className='flex space-x-8'>
                    <div>
                      <h4 className='text-red-600'>
                        Loan rejected due to:{' '}
                        {loanData.loans_by_pk.rejection_reason}
                      </h4>
                      <p>
                        Rejected on:{' '}
                        {moment(
                          loanData.loans_by_pk.partner_branch_approved_at
                        ).format('DD.MM.YYYY hh:mm a')}
                      </p>
                    </div>
                    <ConfirmCallbackModal />
                  </div>

                  {/* <DownloadLoanDetailsButton
                    loan_id={loanData.loans_by_pk.id}
                  /> */}
                </div>

                <div className='w-full flex space-x-8'>
                  {/* Loan request */}
                  <LoanDetails
                    loan_number={loanData.loans_by_pk.loan_number}
                    loan_type={loanData.loans_by_pk.loan_type.loan_type}
                    loan_amount={
                      loanData.loans_by_pk.od_amount
                        ? loanData.loans_by_pk.od_amount.credit_limit
                        : loanData.loans_by_pk.gl_amount
                        ? loanData.loans_by_pk.gl_amount.loan_amount
                        : null
                    }
                    gold_gross_weight={parseFloat(
                      loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.gross_weight;
                      }, 0)
                    ).toFixed(2)}
                    gold_net_weight={parseFloat(
                      loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.net_weight;
                      }, 0)
                    ).toFixed(2)}
                    gold_actual_net_weight={parseFloat(
                      loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.actual_net_weight;
                      }, 0)
                    ).toFixed(2)}
                  />
                  {/* Loan request */}

                  {/*Plan details */}
                  <PlanDetails
                    scheme_name={loanData.loans_by_pk.plan.scheme_name}
                    min_amt={loanData.loans_by_pk.plan.minimum_amount}
                    max_amt={loanData.loans_by_pk.plan.maximum_amount}
                    tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                      (total, obj) => obj.intervals + total,
                      0
                    )} months`}
                    interest={`   ${Math.max.apply(
                      Math,
                      loanData.loans_by_pk.plan.slab_details.map(function (o) {
                        return o.lender_interest_rate;
                      })
                    )} %`}
                    interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                    interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                    loan_type={loanData.loans_by_pk.loan_type.loan_type}
                  />
                  {/* Plan details */}
                </div>

                {/* Gold details */}
                <GoldAppraisalDetails id={loanData.loans_by_pk.id} />
                {/* Gold details */}

                {/* User Info & KYC */}
                <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
                {/* User Info & KYC */}

                <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />
              </div>
            </>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>
    </div>
  );
};

export default CSTRejectedLoans;
