import { Layout } from "antd";
import React, { useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import Nav from "../components/Navbars/Nav";
import { version } from "../../package.json";
import { getToken } from "../utils";
import AuthUtils from "../utils/AuthUtils";

const { Content } = Layout;

const Auth = ({ component: Component, allowed_roles, history, ...rest }) => {

  useEffect(() => {
    if (!AuthUtils.getToken()) {
      window.location.assign("/");
    }
  }, [allowed_roles, history]);

  return (
    <Route
      {...rest}
      render={props =>
        getToken() ? (
          <div>
            <Layout className="flex flex-row">
              <Layout
                style={{
                  width: "100vw",
                  minHeight: "100vh",
                  background: "#fff"
                }}
              >
                <div
                  className="pl-0"
                  style={{
                    zIndex: 99,
                    width: "100vw",
                    minHeight: "64px"
                  }}
                >
                  <Nav {...props} hideAdmin title={""} />
                </div>

                <div
                  className="flex flex-row"
                  style={{ minHeight: "calc(100vh - 120px)" }}
                >
                  <Content className="pr-4 bg-gray-100">
                    <Component {...props} />
                    {/* <div
                      style={{
                        position: "sticky",
                        width: "100%",
                        textAlign: "right",
                        paddingBottom: 10,
                        paddingRight: 10
                      }}
                    >
                      {version}
                    </div> */}
                  </Content>
                </div>
              </Layout>
            </Layout>
          </div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default withRouter(Auth);
