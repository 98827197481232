import { CloseOutlined } from '@ant-design/icons';
import { Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { get_image_url } from '../../utils';

export default function ViewPdf({ visible, modalHeading, url, onClose }) {
	const [signedUrl, setSignedUrl] = useState();

	useEffect(() => {
		if (url) {
			console.log('url:', url);
			async function getSignedUrl() {
				try {
					const valid_url = await get_image_url({
	
						url: [url],
					});
					console.log('valid_url:', valid_url);
					setSignedUrl(valid_url);
				} catch (error) {
				  notification.error({
					message: "Cannot show signed copy for some reason!",
					description: error
				  })
				}
			}
	
			getSignedUrl();
		}
	}, [url]);

	return (
		<Modal
			visible={visible}
			centered
			width={'70%'}
			footer={null}
			className='bg-transparent w-full rounded-lg'
		>
			<div className='mx-auto w-full p-3'>
				<div className='flex items-center'>
					<div className='text-xl font-bold'>{modalHeading}</div>
                    <div className='ml-auto'>
                        <CloseOutlined onClick={onClose} className='text-2xl' />
                    </div>
				</div>
				<iframe
					title='signedUrl'
					src={signedUrl}
					frameBorder='0'
					height='500'
					className='pt-4'
					style={{ width: '100%', height: '100%', minHeight: '500px' }}
				/>
			</div>
		</Modal>
	);
}
