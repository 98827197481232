import moment from 'moment';
import React, { useState, useEffect } from 'react';
import AuthUtils from '../utils/AuthUtils';

const OtpLogs = (props) => {
  const [otpLogs, setOtpLogs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const getOtpLogs = (loan_number) => {
    if (!loan_number) return;
    setIsLoading(true);

    fetch(
      `${process.env.REACT_APP_REGISTER_API}/api/v2/otp/loan/${loan_number}/logs`,
      {
        headers: {
          'X-Renewal-Secret': process.env.REACT_APP_REGISTER_SECRET,
          Authorization: `JWT ${AuthUtils.getToken()}`,
        },
      },
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setOtpLogs(res.logs);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOtpLogs(props.loan_number);
  }, []);

  if (isLoading || !otpLogs || !otpLogs?.otpApprovedAt || !props.loan_number)
    return null;

  // console.log("bank account details", OtpLogs);

  return (
    <div className="flex flex-col space-y-5 w-1/2">
      <p className="text-base font-bold uppercase">Renewal OTP Logs</p>
      <div className="border bg-white flex p-2 w-full">
        <div className="m-3 w-1/4 break-all border-r text-left">
          <h5 className="text-xs uppercase">OTP</h5>
          <p className="text-base font-bold text-black">{otpLogs?.otp}</p>
        </div>
        <div className="m-3 w-1/4 break-all border-r text-left">
          <h5 className="text-xs uppercase">Generated At</h5>
          <p className="text-base font-bold text-black">
            {moment(otpLogs?.otpCreatedAt).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
        <div className="m-3 w-1/4 break-all  text-left">
          <h5 className="text-xs uppercase">Verified At</h5>
          <p className="text-base font-bold text-black">
            {moment(otpLogs?.otpApprovedAt).format('DD.MM.YYYY HH:mm')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OtpLogs;
