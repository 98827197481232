// DEPS
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { notification } from "antd";

// COMPONENETS
import Loading from "../components/loading";
import ErrorPage from "../components/ErrorPage";

// ASSETS
import GreenCheck from "../assets/green-check.png";
import Loader from "../assets/loader.png";
import RedCross from "../assets/red-cross.png";

export const GET_RATES_HISTORY = gql`
  query getRateHistory {
    approvals_changelog(
      limit: 10
      order_by: { created_at: desc }
      where: { type: { _eq: "RATES" }, _and: { status: { _eq: "APPROVED" } } }
    ) {
      id
      data
      status
      created_at
      created_by {
        id
        first_name
        last_name
      }
      confirmed_by {
        id
        first_name
        last_name
      }
    }
  }
`;

// const GET_LATEST_RATE = gql`
//   query getLatestRate {
//     approvals_changelog(
//       limit: 1
//       order_by: { created_at: desc }
//       where: { type: { _eq: "RATES" } }
//     ) {
//       id
//       data
//       status
//       created_by {
//         id
//         first_name
//         last_name
//       }
//       confirmed_by {
//         id
//         first_name
//         last_name
//       }
//     }
//   }
// `;

const GET_LATEST_RATE = gql`
  query getLatestRate($obj: jsonb!) {
    approvals_changelog(
      where: { type: { _eq: "RATES" }, _and: { data: { _contains: $obj } } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      data
      status
      created_by {
        id
        first_name
        last_name
      }
      confirmed_by {
        id
        first_name
        last_name
      }
    }
  }
`;

const INSERT_RATE = gql`
  mutation updateRate($data: jsonb!) {
    insert_approvals_changelog_one(object: { type: "RATES", data: $data }) {
      id
    }
  }
`;

const Rate = () => {
  const { loading, data: Rates, error } = useQuery(GET_RATES_HISTORY);
  const { loading: LoadingLatestRate, data: LatestRate } = useQuery(
    GET_LATEST_RATE,
    {
      variables: {
        obj: {
          date:
            moment().format("H") < 18
              ? moment().format("MM-DD-YYYY")
              : moment().add("1", "days").format("MM-DD-YYYY"),
        },
      },
    }
  );

  const [disableBtn, setDisableBtn] = useState(false);

  const [insertRate] = useMutation(INSERT_RATE, {
    refetchQueries: [
      {
        query: GET_LATEST_RATE,
        variables: {
          obj: {
            date:
              moment().format("H") < 18
                ? moment().format("MM-DD-YYYY")
                : moment().add("1", "days").format("MM-DD-YYYY"),
          },
        },
      },
    ],
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: "Error!",
          description: "Something went wrong. Please try again after some time",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Rate sent for approval",
      });
      setDisableBtn(true);
      document.getElementById("rate-input").value = "";
    },
  });

  if (loading || LoadingLatestRate) return <Loading />;

  if (error) return <ErrorPage />;

  return (
    <div className="px-4 pt-12 space-y-8">
      <div className="w-1/2">
        <h1 className="uppercase text-xl font-bold">
          Gold rate for{" "}
          {moment().format("H") < 18
            ? moment().format("MMMM D YYYY")
            : moment().add("1", "days").format("MMMM D YYYY")}
        </h1>
        <p>
          You will be able to update the gold rate between 6PM of previous day
          and 6PM of current day. Any change for updating the price for a given
          day has to be performed between this window
        </p>
      </div>
      <div className="w-1/2">
        <h2 className="text-lg font-bold text-gray-600">STATUS</h2>

        {/* STATUS - TO BE SET BY BANK MAKER  */}
        {LatestRate.approvals_changelog.length === 0 ? (
          <div className="flex items-center space-x-2">
            <h2 className="uppercase font-bold">TO BE SET BY BANK MAKER</h2>
          </div>
        ) : (
          <>
            {" "}
            {/* STATUS - PENDING APPROVAL  */}
            {disableBtn ||
            LatestRate.approvals_changelog[0].status === "TO_BE_APPROVED" ? (
              <div className="flex items-center space-x-2">
                {" "}
                <img
                  src={Loader}
                  alt="check"
                  style={{ height: "1rem", width: "1rem" }}
                />
                <h2 className="uppercase font-bold">PENDING APPROVAL</h2>{" "}
              </div>
            ) : null}
            {/* STATUS - APPROVED / REJECTED  */}
            {disableBtn ? null : LatestRate.approvals_changelog[0].status !==
              "TO_BE_APPROVED" ? (
              <div className="flex items-center space-x-2">
                <img
                  src={
                    LatestRate.approvals_changelog[0].status === "APPROVED"
                      ? GreenCheck
                      : RedCross
                  }
                  alt="check"
                  style={{ height: "1rem", width: "1rem" }}
                />
                <h2 className="uppercase font-bold">
                  {LatestRate.approvals_changelog[0].status} BY{" "}
                  {LatestRate.approvals_changelog[0].confirmed_by.first_name}{" "}
                  {LatestRate.approvals_changelog[0].confirmed_by.last_name} -
                  BANK CHECKER
                </h2>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="flex space-x-4 items-center">
        <h2 className="text-black">Gold Rate for 22 carat (INR)</h2>
        <input
          id="rate-input"
          className="py-4 px-2 border"
          placeholder="Enter gold rate in INR"
          autoComplete="off"
        />
      </div>
      <div className="flex space-x-4 items-center">
        {/* {console.log("current day", moment().startOf("day"))}
        {console.log("rate date", moment(Rates.rates[0].date), "days")}
        {console.log(
          "diff",
          moment()
            .startOf("day")
            .diff(moment(Rates.rates[0].date), "days")
        )} */}
        <button
          disabled={
            LatestRate.approvals_changelog.length !== 0
              ? disableBtn ||
                LatestRate.approvals_changelog[0].status === "TO_BE_APPROVED"
                ? true
                : moment()
                    .startOf("day")
                    .diff(
                      moment(Rates.approvals_changelog[0].data.date),
                      "days"
                    ) === 0
                ? moment().format("H") < 18
                  ? true
                  : false
                : false
              : false
          }
          className={
            LatestRate.approvals_changelog.length !== 0
              ? disableBtn ||
                LatestRate.approvals_changelog[0].status === "TO_BE_APPROVED"
                ? "py-2 px-16 border bg-gray-400 text-gray-500 rounded font-bold shadow-md cursor-not-allowed"
                : moment()
                    .startOf("day")
                    .diff(
                      moment(Rates.approvals_changelog[0].data.date),
                      "days"
                    ) === 0
                ? moment().format("H") < 18
                  ? "py-2 px-16 border bg-gray-400 text-gray-500 rounded font-bold shadow-md cursor-not-allowed"
                  : "cta py-2 px-16"
                : "cta py-2 px-16"
              : "cta py-2 px-16"
          }
          onClick={() => {
            let value = document.getElementById("rate-input").value;
            if (value === "") {
              notification.error({
                message: "Error!",
                description: "Please enter the gold rate and try again",
              });
            } else if(!/^((?![()<>/;[\]{}'"]).)*$/gm.test(value)) {
              notification.error({
                message: "Error!",
                description: "Special Characters are not allowed",
              });
            } else {
              insertRate({
                variables: {
                  data: {
                    rate: value,
                    date:
                      moment().format("H") > 18
                        ? moment().add(1, "days").format("MM-DD-YYYY")
                        : moment().format("MM-DD-YYYY"),
                  },
                },
              });
            }
          }}
        >
          Set Gold Rate
        </button>{" "}
        {LatestRate.approvals_changelog.length > 0 ? (
          <p className="text-gray-500">
            {LatestRate.approvals_changelog[0].status === "TO_BE_APPROVED"
              ? "Changes will be updated only after Bank Checker approves."
              : LatestRate.approvals_changelog[0].status === "APPROVED"
              ? "Gold rate has been approved and updated successfully."
              : "Please check and update the gold rate again!"}
          </p>
        ) : null}
      </div>

      <div className="space-y-2">
        <h2 className="uppercase">Gold rate updation log (last 10 days)</h2>

        <table className="w-2/3">
          <colgroup>
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
          </colgroup>

          <tr>
            <th className="uppercase">Date</th>
            <th className="uppercase">Updated on</th>
            <th className="uppercase">22 carat rate (INR)</th>
            <th className="uppercase">Set by</th>
            <th className="uppercase">Approved by</th>
          </tr>

          {Rates.approvals_changelog.map((rate) => {
            return (
              <tr>
                <td>
                  {moment(rate.data.date).format("MMM Do, YYYY") !==
                  "Invalid date"
                    ? moment(rate.data.date).format("MMM Do, YYYY")
                    : moment(rate.data.date, "MM-DD-YYYY").format(
                        "MMM Do, YYYY"
                      )}
                </td>
                <td>{moment(rate.created_at).format("HH:mm, MMM DD, YYYY")}</td>
                <td>{rate.data.rate}</td>
                <td>
                  {rate.created_by?.first_name} {rate.created_by?.first_name}
                </td>
                <td>
                  {rate.confirmed_by?.first_name} {rate.confirmed_by?.first_name}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
      <p>
        Please contact{" "}
        <a
          href="mailto:naveen@nfnlabs.in"
          className="underline hover:underline"
        >
          <Link to="/support">Oro Support</Link>
        </a>{" "}
        if you would like to get detailed updation log.
      </p>
    </div>
  );
};

export default Rate;
