// DEPS
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  Spin,
  Popconfirm,
  Steps,
  Modal as AntModal,
  InputNumber,
  notification,
  Empty,
} from 'antd';
import moment from 'moment';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { get_image_url } from '../../../utils';

//ASSETS
import GreenCheck from '../../../assets/green-check.png';
import Loader from '../../../assets/loader.png';

// COMPONENTS
import ConfirmCallbackModal from '../../../components/Modals/confirmCallback';
import ErrorPage from '../../../components/ErrorPage';
import Sidebar from '../../../components/Sidebar';
import StepsContainer from '../../../components/Steps';
import ImageViewer from '../../../components/ImageViewer';

// VIEWS
import CreateLoanAccountNumberModal from '../../../components/Modals/createLoanAccountNumber';
import GoldAppraisalDetails from '../../../views/goldAppraisalDetails';
import UserKYCDetails from '../../../views/userKYCDetails';
import AgentDetailsView from '../../../views/agentDetails';
import Timestamps from '../../../views/timestamps';
import UserBankAccountDetails from '../../../views/userBankAccountDetails';
import LoanDetails from '../../../views/loanDetails';
import PlanDetails from '../../../views/planDetails';
import ViewPdf from '../../../components/ViewPdf';

// APIS
import {
  GET_LOAN_BY_ID,
  VALIDATE_OTP,
  SET_BLC_VERIFIED_STATUS,
  CHECK_SEAL_GROSS_WT,
  BLC_ABORT_LOAN,
  UPDATE_VISIT_STATUS_OTP_VALIDATED,
  UPDATE_GOLDS_ADD_SEALED_COVER_GROSS_WT_CHECKER,
} from '../graphql';
import SealIntegrityCheck from '../../../views/SealIntegrityCheck';
import _ from 'lodash';

//CSS
import './new.css';
import ApiUtils from '../../../utils/ApiUtils';
import AuthUtils from '../../../utils/AuthUtils';

const { Step } = Steps;

const BLCNewLoans = (props) => {
  const [trigger, setTrigger] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(0);

  const [loanAccountNumberSet, setLoanAccountNumberSet] = useState(false);
  const [isGoldStoreModalOpen, setGoldStoreModalOpen] = useState(false);
  const [isValidateOTPModalOpen, setValidateOTPModalOpen] = useState(false);
  const [isGoldAppraisalModalOpen, setGoldAppraisalModalOpen] = useState(false);

  const [isOTPValidated, setIsOTPValidated] = useState(false);

  const [signedreceiptImages, setSignedReceiptsImages] = useState(null);
  const [isPledgeCardImageModalOpen, setIsPledgeCardImageModalOpen] =
    useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [sealedCoverGrossWt, setSealedCoverGrossWt] = useState([]);

  const [isLoanAccountCreationModalOpen, setLoanAccountCreationModalOpen] =
    useState(false);
  const [toggleViewPdfModal, setToggleViewPdfModal] = useState({
    toggle: false,
    url: '',
    title: '',
  });  

  const baseURL = process.env.REACT_APP_FEDERAL_ENDPOINT

  const [updateGoldsAddSealedCoverGrossWtChecker] = useMutation(
    UPDATE_GOLDS_ADD_SEALED_COVER_GROSS_WT_CHECKER,
    {
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          // console.log(error);
          notification.error({
            message: 'Error!',
            description:
              'Something went wrong. Please check all details and try again',
          });
        });
      },
      onCompleted(data) {
        // notification.success({
        //   message: "Success!",
        //   description: "OTP validated",
        // });
        setGoldStoreModalOpen(false);
        setGoldAppraisalModalOpen(true);
      },
    }
  );

  const [setVisitStatusOTPValidated] = useMutation(
    UPDATE_VISIT_STATUS_OTP_VALIDATED,
    {
      onError(error) {
        error.graphQLErrors.map((error) => {
          // console.log(error);
          notification.error({
            message: 'Error!',
            description:
              'Something went wrong. Please check all details and try again',
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: 'Success!',
          description: 'OTP validated',
        });
        setIsOTPValidated(true);
        setValidateOTPModalOpen(false);
        setGoldStoreModalOpen(true);
      },
    }
  );

  const [updateOTP] = useMutation(VALIDATE_OTP, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description: error.message,
        });
      });
    },
    onCompleted(data) {
      // console.log("data", data);
      // notification.success({
      //   message: "Success!",
      //   description: "OTP validated"
      // });
      // setValidateOTPModalOpen(false);
      // setGoldStoreModalOpen(true);
      if (data.validate_otp.message === 'OTP validated') {
        // setVisitStatusOTPValidated({
        //   variables: {
        //     visit_id: loanData.loans_by_pk.visits[0]?.id,
        //   },
        // });
        notification.success({
          message: 'Success!',
          description: 'OTP validated',
        });
        setIsOTPValidated(true);
        setValidateOTPModalOpen(false);
        setGoldStoreModalOpen(true);
      } else {
        notification.error({
          message: 'Error!',
          description: 'OTP is invalid',
        });
      }
    },
  });

  const [setBLCVerifiedStatus] = useMutation(SET_BLC_VERIFIED_STATUS, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'Confirmed',
      });
      setSelectedLoan(0);
      setGoldAppraisalModalOpen(false);
    },
  });

  const [
    getOldLoanById,
    {
      loading: loanOldDataLoading,
      data: loanOldData,
      error: loanOldQueryError,
    },
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [
    getLoanById,
    {
      loading: loanDataLoading,
      data: loanData,
      error: loanQueryError,
      refetch: reFetchLoanById,
    },
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data && data.loans_by_pk && data.loans_by_pk.renew_from_loan) {
        getOldLoanById({ variables: { id: data.loans_by_pk.renew_from_loan } });
      }
    },
  });

  const [BlcAbortLoan] = useMutation(BLC_ABORT_LOAN, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'Loan aborted',
      });
      setGoldAppraisalModalOpen(false);
      setTrigger(true);
    },
  });

  const [checkWeights, { loading: checkingWeight, data: checkWeightResponse }] =
    useLazyQuery(CHECK_SEAL_GROSS_WT, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (sealedCoverGrossWt.length > 0 && checkWeightResponse)
      if (
        checkWeightResponse.golds_aggregate.aggregate.count ===
        loanData.loans_by_pk.golds.length
      ) {
        notification.success({
          message: 'Success!',
          description: 'Weights match',
        });
        setGoldStoreModalOpen(false);
        setGoldAppraisalModalOpen(true);
      } else {
        notification.error({
          message: 'Success!',
          description:
            "Seal weights don't match. Please try again or contact Orocorp for support",
        });
      }
  }, [checkingWeight]);

  useEffect(() => {
    getLoanById({ variables: { id: selectedLoan || 0 } });
  }, [selectedLoan]);

  React.useEffect(() => {
    (async () => {
      if (isGoldStoreModalOpen) {
        let response = await reFetchLoanById();
        if (
          response.data.loans_by_pk.visits[0]?.intermediate_status !==
          'OTP_VALIDATED'
        ) {
          notification.error({
            message: 'Error!',
            description: 'OTP is not validated',
          });
          setGoldStoreModalOpen(false);
          setValidateOTPModalOpen(true);
        }
      }
    })();
  }, [isGoldStoreModalOpen]);

  if (loanQueryError || loanOldQueryError) return <ErrorPage />;

  return (
    <div className='flex flex-row space-x-4'>
      {/* Sidebar */}
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        loan_statuses={['APPROVED', 'BLC_VERIFIED']}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      {/* Sidebar */}

      <div className='w-full py-4 new-blc overflow-auto'>
        <div className='flex w-full justify-between space-x-16'>
          {loanData ? (
            loanData.loans_by_pk !== null &&
            loanData.loans_by_pk.renew_from_loan === null ? (
              <>
                <div className='w-6/12 flex space-x-8'>
                  <StepsContainer
                    count={0}
                    steps={[
                      'REQUEST RECEIVED',
                      'GOLD STORED',
                      'GOLD RELEASED',
                      'LOAN CLOSED',
                    ]}
                  />
                </div>
                <div className='w-1/6 space-y-1 text-right flex flex-col items-end'>
                  <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                  <p className='text-gray-600 font-semibold'>
                    {moment(loanData.loans_by_pk.created_at).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </p>
                  <p className='m-0 border text-center font-semibold border-black text-xs bg-yellow-500 rounded px-5'>
                    TO BE STORED
                  </p>
                </div>
              </>
            ) : null
          ) : null}
        </div>

        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <div className='space-y-8'>
              <Timestamps loan_id={loanData.loans_by_pk.id} />
              <div className='flex w-full justify-between items-center'>
                {/* Renewal Condition */}
                {loanData.loans_by_pk.renew_from_loan !== null ? (
                  <div className='flex justify-between w-full items-center'>
                    <div className='space-x-2 flex items-center'>
                      <img
                        src={
                          loanData.loans_by_pk.loan_status === 'BLC_VERIFIED'
                            ? Loader
                            : GreenCheck
                        }
                        alt='check'
                        style={{ height: '1rem', width: '1rem' }}
                      />
                      <p>
                        {loanData.loans_by_pk.loan_status === 'BLC_VERIFIED'
                          ? 'Verifier Confirmation in Progress'
                          : 'Verifier Confirmed'}
                      </p>
                      {/* {loanData.loans_by_pk.loan_status === 'BLC_VERIFIED' && (
                        <button
                          type="submit"
                          className="btn-primary hover:shadow-lg"
                          onClick={() => {
                            setLoanAccountCreationModalOpen(true);
                          }}
                        >
                          Confirm Renewal
                        </button>
                      )} */}
                    </div>
                      {/* {loanOldData && loanOldData.loans_by_pk && (
                        <button
                          type="submit"
                          className="text-xs px-3 cta py-2"
                          style={{ minWidth: "170px" }}
                          onClick={async () => {
                            if(loanOldData && loanOldData.loans_by_pk) {
                              let images = await Promise.all(
                                loanOldData.loans_by_pk.receipts.release_letter.map(async (receiptURL) => {
                                  const valid_url = await get_image_url({
                                    url: [receiptURL],
                                  });
    
                                  return {
                                    caption: 'Release Letter',
                                    source: valid_url,
                                  };
                                }
                                )
                              );
                              setReleaseReceiptsImages(images);
                              setIsReleaseLetterImageModalOpen(true);
                            }
                          }}
                        >
                          View Old Loan Release Receipt
                        </button>
                      )} */}
                    {/* <ConfirmCallbackModal /> */}
                  </div>
                ) : (
                  <div className='flex space-x-8 items-center'>
                    {loanData.loans_by_pk.loan_status !== 'BLC_VERIFIED' ? (
                      <>
                        <button
                          type='submit'
                          className='btn-primary hover:shadow-lg'
                          onClick={() => {
                            if (
                              loanData.loans_by_pk.visits[0]
                                ?.intermediate_status === 'OTP_VALIDATED' ||
                              isOTPValidated
                            ) {
                              setGoldStoreModalOpen(true);
                            } else {
                              setValidateOTPModalOpen(true);
                            }
                          }}
                          // onClick={() => setGoldStoreModalOpen(true)}
                          // onClick={() => setGoldAppraisalModalOpen(true)}
                        >
                          Store Gold Ornaments
                        </button>

                        {/* <button
                        type="submit"
                        className="btn-danger hover:shadow-lg"
                        // onClick={() => setValidateOTPModalOpen(true)}
                        // onClick={() => setGoldStoreModalOpen(true)}
                        onClick={() => {
                          BlcAbortLoan({
                            variables: {
                              loan_id: loanData.loans_by_pk.id
                            }
                          });
                        }}
                      >
                        Abort Loan
                      </button> */}
                      </>
                    ) : null}

                    <ConfirmCallbackModal />
                  </div>
                )}

                {loanData?.loans_by_pk?.receipts != null ? (
                  <div className="flex">
                    {loanData?.loans_by_pk?.receipts?.signed_pledge_card?.length > 0 && (
                      <button
                      type="submit"
                      className="text-xs px-3 cta py-2"
                      style={{ minWidth: "220px" }}
                      onClick={() => {
                        setToggleViewPdfModal((prev) => ({
                          ...prev,
                          toggle: true,
                          title: 'Signed Pledge Card',
                          url:
                          loanData.loans_by_pk?.receipts?.signed_pledge_card?.[0]
                        }));
                      }}      
                    >
                      View Signed Pledge Card
                    </button>
                    )}
                    {loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement && (
                      <button
                        type="submit"
                        className="text-xs cta py-2 ml-4"
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          setToggleViewPdfModal((prev) => ({
                            ...prev,
                            toggle: true,
                            title: 'Signed KFS',
                            url:
                            loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement?.[0]
                          }));
                        }}
                      >
                        View Signed KFS
                      </button>
                    )}
                  </div>
                ) : null}
              </div>

              {/* Renewal Conditions */}
              {loanOldDataLoading ? (
                <Spin />
              ) : (
                loanData &&
                loanOldData &&
                loanData.loans_by_pk &&
                loanData.loans_by_pk.renew_from_loan !== null &&
                loanOldData.loans_by_pk && (
                  <div className='w-full flex space-x-8'>
                    {/* Loan request */}
                    <LoanDetails
                      title='OLD LOAN DETAILS'
                      loan_number={loanOldData.loans_by_pk.loan_number}
                      loan_type={loanOldData.loans_by_pk.loan_type.loan_type}
                      loan_amount={
                        loanOldData.loans_by_pk.od_amount
                          ? loanOldData.loans_by_pk.od_amount.credit_limit
                          : loanOldData.loans_by_pk.gl_amount
                          ? loanOldData.loans_by_pk.gl_amount.loan_amount
                          : null
                      }
                      gold_gross_weight={parseFloat(
                        loanOldData.loans_by_pk.golds.reduce(
                          (acc, goldItem) => {
                            return acc + goldItem.gross_weight;
                          },
                          0
                        )
                      ).toFixed(2)}
                      gold_net_weight={parseFloat(
                        loanOldData.loans_by_pk.golds.reduce(
                          (acc, goldItem) => {
                            return acc + goldItem.net_weight;
                          },
                          0
                        )
                      ).toFixed(2)}
                      gold_actual_net_weight={parseFloat(
                        loanOldData.loans_by_pk.golds.reduce(
                          (acc, goldItem) => {
                            return acc + goldItem.actual_net_weight;
                          },
                          0
                        )
                      ).toFixed(2)}
                    />
                    {/* Loan request */}

                    {/*Plan details */}
                    <PlanDetails
                      title='OLD PLAN DETAILS'
                      scheme_name={loanOldData.loans_by_pk.plan.scheme_name}
                      min_amt={loanOldData.loans_by_pk.plan.minimum_amount}
                      max_amt={loanOldData.loans_by_pk.plan.maximum_amount}
                      tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                        (total, obj) => obj.intervals + total,
                        0
                      )} months`}
                      interest={`   ${Math.max.apply(
                        Math,
                        loanData.loans_by_pk.plan.slab_details.map(function (
                          o
                        ) {
                          return o.lender_interest_rate;
                        })
                      )} %`}
                      interest_slab_2={`${loanOldData.loans_by_pk.plan.interest_slab_2} %`}
                      interest_slab_3={`${loanOldData.loans_by_pk.plan.interest_slab_3} %`}
                      loan_type={loanOldData.loans_by_pk.loan_type.loan_type}
                    />
                    {/* Plan details */}
                  </div>
                )
              )}

              <div className='w-full flex space-x-8'>
                {/* Loan request */}
                <LoanDetails
                  title={
                    loanData.loans_by_pk.renew_from_loan !== null
                      ? 'NEW LOAN DETAILS'
                      : null
                  }
                  loan_number={loanData.loans_by_pk.loan_number}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                  loan_amount={
                    loanData.loans_by_pk.od_amount
                      ? loanData.loans_by_pk.od_amount.credit_limit
                      : loanData.loans_by_pk.gl_amount
                      ? loanData.loans_by_pk.gl_amount.loan_amount
                      : null
                  }
                  gold_gross_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.gross_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.net_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_actual_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.actual_net_weight;
                    }, 0)
                  ).toFixed(2)}
                />
                {/* Loan request */}

                {/*Plan details */}
                <PlanDetails
                  title={
                    loanData.loans_by_pk.renew_from_loan !== null
                      ? 'NEW PLAN DETAILS'
                      : null
                  }
                  scheme_name={loanData.loans_by_pk.plan.scheme_name}
                  min_amt={loanData.loans_by_pk.plan.minimum_amount}
                  max_amt={loanData.loans_by_pk.plan.maximum_amount}
                  tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                    (total, obj) => obj.intervals + total,
                    0
                  )} months`}
                  interest={`   ${Math.max.apply(
                    Math,
                    loanData.loans_by_pk.plan.slab_details.map(function (o) {
                      return o.lender_interest_rate;
                    })
                  )} %`}
                  interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                  interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                />
                {/* Plan details */}
              </div>

              <AgentDetailsView loan_id={loanData.loans_by_pk.id} />

              {/* Gold details */}
              <GoldAppraisalDetails id={loanData.loans_by_pk.id} />
              {/* Gold details */}

              {/* User Info & KYC */}
              <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
              {/* User Info & KYC */}

              <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />

              <CreateLoanAccountNumberModal
                title='Confirm Loan Renewal'
                loan_id={loanData.loans_by_pk.id}
                isOpen={isLoanAccountCreationModalOpen}
                setOpen={setLoanAccountCreationModalOpen}
                setLoanAccountNumberSet={setLoanAccountNumberSet}
                setSelectedLoan={setSelectedLoan}
                setTrigger={setTrigger}
                customer_id={loanData.loans_by_pk.customer.id}
              />
              {isPledgeCardImageModalOpen && signedreceiptImages?.length > 0 ? (
                <ImageViewer
                  images={signedreceiptImages}
                  onClose={() => {
                    setIsPledgeCardImageModalOpen(false);
                  }}
                />
              ) : null}
              <ModalGateway>
                {isImageModalOpen && images.length > 0 ? (
                  <Modal
                    onClose={() => {
                      setIsImageModalOpen(!isImageModalOpen);
                    }}
                  >
                    <Carousel views={images} />
                  </Modal>
                ) : null}
              </ModalGateway>
            </div>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>

      {/* OTP VALIDATION */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isValidateOTPModalOpen}
        footer={[
          <button
            className='btn-primary hover:shadow-lg'
            onClick={() => {
              if (!document.getElementById('PartnerOTP').value) {
                notification.error({
                  message: 'Error!',
                  description: 'OTP is invalid',
                });
              } else if (
                !/^((?![()<>/;[\]{}'"]).)*$/gm.test(
                  document.getElementById('PartnerOTP').value
                )
              ) {
                notification.error({
                  message: 'Error!',
                  description: 'Special Characters are not allowed',
                });
              } else {
                ApiUtils.post(
                  "/api/v1/otp/validate",
                  {
                    otp: document.getElementById("PartnerOTP").value,
                    visitId: loanData.loans_by_pk.visits[0]?.id,
                  },
                  {
                    baseURL,
                    headers: {
                      Authorization: `JWT ${AuthUtils.getToken()}`,
                    },
                  }
                )
                  .then(function (data) {
                    if (data.status === 200) {
                      notification.success({
                        message: "Success!",
                        description: "OTP validated",
                      });
                      setIsOTPValidated(true);
                      setValidateOTPModalOpen(false);
                      setGoldStoreModalOpen(true);
                    } else {
                      notification.error({
                        message: "Error!",
                        description: "OTP is invalid",
                      });
                    }
                  })
                  .catch(function (error) {
                    return notification.error({
                      message: "Error!",
                      description: error?.response?.data?.message,
                    });
                  });
              }
            }}
            type='submit'
          >
            Complete agent identity check
          </button>,
          <Popconfirm
            title={`Are you sure you want to cancel?`}
            onConfirm={() => {
              setValidateOTPModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Store Gold Ornaments</h1>
          </div>
          <center>
            <div className='w-4/6'>
              {' '}
              <StepsContainer
                count={0}
                steps={[
                  'REQUEST RECEIVED',
                  'AGENT VERIFIED',
                  'SEAL CHECKED',
                  'APPRAISAL VERIFIED',
                ]}
              />
            </div>
          </center>
          <div className='text-center'>
            <h1>Verify Agent identity</h1>
            <p>Please verify the agent details and enter OTP to proceed.</p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <div className='border bg-white'>
                  <div className='border-b flex p-2 w-full'>
                    <div className='m-3 w-1/5 break-all border-r'>
                      <h5>AGENT NAME</h5>
                      <p className='font-bold text-black'>
                        {/* {console.log("data", loanData.loans_by_pk)} */}
                        {loanData.loans_by_pk.visits[0]?.agent_first_name}
                        {loanData.loans_by_pk.visits[0]?.agent_last_name}
                      </p>
                    </div>

                    <div className='m-3 w-1/5 break-all border-r'>
                      <h5>AGENT ID</h5>
                      <p className='font-bold text-black'>
                        {loanData.loans_by_pk.visits[0]?.agent_id}
                      </p>
                    </div>

                    <div className='m-3 w-1/5 break-all border-r'>
                      <h5>AGENT MOBILE NO.</h5>
                      <p className='font-bold text-black'>
                        {loanData.loans_by_pk.visits[0]?.agent_mobile_number}
                      </p>
                    </div>

                    <div className='m-3 w-1/5 break-all border-r'>
                      <h5>VISIT ID</h5>
                      <p className='font-bold text-black'>
                        {loanData.loans_by_pk.visits[0]?.visit_display_id}
                      </p>
                    </div>

                    <div className='m-3 w-1/5 break-all '>
                      <h5>AGENT PHOTO</h5>
                      <button
                        className='underline hover:underline text-yellow-primary'
                        onClick={async () => {
                          const valid_url = await get_image_url({
                            url: [loanData.loans_by_pk.visits[0]?.agent_photo],
                          });

                          setImages([
                            {
                              source: valid_url,
                            },
                          ]);
                          setIsImageModalOpen(true);
                        }}
                      >
                        Click to view
                      </button>
                    </div>
                  </div>
                </div>

                <div className='text-center w-1/2 mx-auto space-y-2'>
                  <h2>ENTER OTP:</h2>
                  <InputNumber
                    maxLength={4}
                    id='PartnerOTP'
                    placeholder='4 digit OTP'
                    size='large'
                    className='w-1/2 mx-auto text-gray-700 leading-tight focus:outline-none focus:shadow-outline rounded'
                  />
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* OTP VALIDATION */}

      {/* USE THIS MODAL TO VIEW PDF  */}
			{toggleViewPdfModal.toggle && (
				<ViewPdf
					url={toggleViewPdfModal.url}
					visible={toggleViewPdfModal.toggle}
					modalHeading={toggleViewPdfModal.title}
					onClose={() =>
						setToggleViewPdfModal((prev) => ({
							...prev,
							title: '',
							toggle: false,
							url: '',
						}))
					}
				/>
			)}

      {/* SEAL INTEGRITY CHECK */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isGoldStoreModalOpen}
        footer={[
          <button
            className={
              loanData
                ? 'btn-primary hover:shadow-lg'
                : 'bg-gray-100 py-2 px-4 border rounded'
            }
            onClick={() => {
              // console.log("sealed cover gross wt", sealedCoverGrossWt);

              if (sealedCoverGrossWt.length === 0) {
                notification.error({
                  message: 'Error!',
                  description:
                    "Seal weights don't match. Please try again or contact Orocorp for support",
                });
              } else {
                let groupedGoldForSeal = _.groupBy(
                  loanData.loans_by_pk.golds,
                  (gold) => gold.pickup_seal_id
                );

                Object.keys(groupedGoldForSeal).forEach((key) => {
                  let sealed_cover_gross_weight =
                    groupedGoldForSeal[key][0].sealed_cover_gross_weight;
                  let ornaments = groupedGoldForSeal[key].map((item) => ({
                    gold_display_id: item.gold_display_id,
                    type: item.type,
                    quantity: item.quantity,
                    id: item.id,
                    weight: item.sealed_cover_gross_weight,
                  }));
                  groupedGoldForSeal[key] = {
                    sealed_cover_gross_weight: sealed_cover_gross_weight,
                    ornaments: ornaments,
                    pickup_seal_id: key,
                  };
                });

                let output = sealedCoverGrossWt.map((seal) => {
                  return (
                    seal?.seal_weight >=
                      groupedGoldForSeal[seal.pickup_seal_id]
                        .sealed_cover_gross_weight *
                        0.96 &&
                    seal?.seal_weight <=
                      groupedGoldForSeal[seal.pickup_seal_id]
                        .sealed_cover_gross_weight *
                        1.04
                  );
                });

                let answer = output.includes(false);

                if (answer) {
                  notification.error({
                    message: 'Error!',
                    description:
                      "Seal weights don't match. Please try again or contact Orocorp for support",
                  });
                } else {
                  notification.success({
                    message: 'Success!',
                    description: 'Weights match',
                  });

                  let mutationData = [];

                  Object.keys(groupedGoldForSeal).forEach((key) => {
                    let index = -1;
                    let req_item = sealedCoverGrossWt.filter(
                      (seal, mapIndex) => {
                        if (seal.pickup_seal_id === key) index = mapIndex;
                        return seal.pickup_seal_id === key;
                      }
                    );
                    req_item[0].ornaments.forEach((ornament) => {
                      let obj = {
                        ...ornament,
                        sealed_cover_gross_weight_checker: Number(
                          sealedCoverGrossWt[index].seal_weight
                        ),
                        loan_id: 0,
                        type: 'Necklace',
                        quality: '22 carats',
                        quantity: 0,
                        gross_weight: 0,
                        net_weight: 0,
                        actual_net_weight: 0,
                        sealed_cover_gross_weight: 0,
                        pickup_seal_id: '0',
                        pickup_gold_images: {},
                        pickup_seal_images: {},
                        stone_deduction: 0,
                      };

                      delete obj.weight;
                      delete obj.gold_display_id;

                      mutationData.push(obj);
                    });
                  });

                  // console.log("mutationData", mutationData);

                  updateGoldsAddSealedCoverGrossWtChecker({
                    variables: {
                      obj: mutationData,
                    },
                  });
                }

                // checkWeights({
                //   variables: {
                //     loan_id: loanData.loans_by_pk.id,
                //     weights: sealedCoverGrossWt
                //   }
                // });
                // setGoldStoreModalOpen(false);
                // setGoldAppraisalModalOpen(true);
              }
            }}
            type='submit'
          >
            Complete integrity check
          </button>,
          <Popconfirm
            title={`Are you sure you want to cancel?`}
            onConfirm={() => {
              setGoldStoreModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Store Gold Ornaments</h1>
          </div>
          <center>
            <div className='w-4/6'>
              {' '}
              <StepsContainer
                count={1}
                steps={[
                  'REQUEST RECEIVED',
                  'AGENT VERIFIED',
                  'SEAL CHECKED',
                  'APPRAISAL VERIFIED',
                ]}
              />
            </div>
          </center>

          <div className='text-center'>
            <h1>Seal integrity check</h1>
            <p>
              Please enter the sealed covers' gross weight weighed at the branch
              to complete the seal integrity check.
            </p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <SealIntegrityCheck
                  id={loanData.loans_by_pk.id}
                  hideExport={true}
                  sealedCoverGrossWt={sealedCoverGrossWt}
                  setSealedCoverGrossWt={setSealedCoverGrossWt}
                />
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* SEAL INTEGRITY CHECK */}

      {/* GOLD APPRAISAL VERIFICATION */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isGoldAppraisalModalOpen}
        footer={null}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Store Gold Ornaments</h1>
          </div>
          <center>
            <div className='w-4/6'>
              {' '}
              <StepsContainer
                count={2}
                steps={[
                  'REQUEST RECEIVED',
                  'AGENT VERIFIED',
                  'SEAL CHECKED',
                  'APPRAISAL VERIFIED',
                ]}
              />
            </div>
          </center>
          <div className='text-center'>
            <h1>Gold appraisal verification</h1>
            <p>
              Please verify the Oro appraised Gold ornament details with the
              appraisal details at the branch.
            </p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <GoldAppraisalDetails
                  id={loanData.loans_by_pk.id}
                  hideExport={true}
                  size='medium'
                />

                <div className='flex space-x-4 justify-center'>
                  <button
                    className={
                      loanData
                        ? 'btn-primary hover:shadow-lg'
                        : 'bg-gray-100 py-2 px-4 border rounded'
                    }
                    onClick={() => {
                      // setGoldStoreModalOpen(false);
                      ApiUtils.post(
                        `/api/v1/visit/${loanData.loans_by_pk.visits[0]?.id}/complete/verification`,
                        {},
                        {
                          baseURL,
                          headers: {
                            Authorization: `JWT ${AuthUtils.getToken()}`,
                          },
                        }
                      )
                        .then(function (data) {
                          if (data.status === 200) {
                            notification.success({
                              message: "Success!",
                              description: "Confirmed",
                            });
                            setSelectedLoan(0);
                            setGoldAppraisalModalOpen(false);
                          } else {
                            notification.error({
                              message: "Error!",
                              description: "Error while updating",
                            });
                          }
                        })
                        .catch(function (error) {
                          return notification.error({
                            message: "Error!",
                            description:
                              "Something went wrong. Please check all details and try again",
                          });
                        });

                    }}
                    type='submit'
                  >
                    Complete appraisal verification
                  </button>

                  <Popconfirm
                    title={`Are you sure you want to cancel?`}
                    onConfirm={() => {
                      setGoldAppraisalModalOpen(false);
                    }}
                    onCancel={() => {}}
                    okText='Yes'
                    placement='bottomRight'
                    cancelText='No'
                  >
                    <button className='btn-danger hover:shadow-lg'>
                      Close
                    </button>
                  </Popconfirm>
                </div>

                {/* <p className="text-center">
                  Click here to{" "}
                  <button
                    className="text-red-600 underline hover:underline"
                    onClick={() => {
                      BlcAbortLoan({
                        variables: {
                          loan_id: loanData.loans_by_pk.id,
                          reason: "Weight mismatch",
                          checker_confirmed_at: moment()
                        }
                      });
                    }}
                  >
                    abort the loan
                  </button>{" "}
                  due to net weight mismatch
                </p> */}
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* GOLD APPRAISAL VERIFICATION */}
    </div>
  );
};

export default BLCNewLoans;
