// DEPS
import React, { useState, useEffect } from "react";

// COMPONENTS
import CheckerSidebar from "../../components/CheckerSidebar";
// import ErrorPage from "../../components/ErrorPage";
// import Loading from "../../components/loading";

// VIEWS
import RateDetails from "./rateDetails";
import BizHoursDetails from "./bizHoursDetails";
import HolidaysDetails from "./holidaysDetails";

const NewRequests = props => {
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [requestType, setRequestType] = useState(null);

  useEffect(() => {
    // console.log("fetching again", selectedRequest);
    // getRequestById({ variables: { id: selectedRequest || 0 } });
  }, [selectedRequest]);

  return (
    <div className="flex flex-row space-x-4">
      {/* Sidebar */}

      <CheckerSidebar
        props={props}
        index={selectedRequest}
        setSelectedRequest={setSelectedRequest}
        status="TO_BE_APPROVED"
        trigger={trigger}
        setTrigger={setTrigger}
        setRequestType={setRequestType}
      />
      {/* Sidebar */}

      {/* {console.log("requestType", requestType)} */}

      <div className="px-4 space-y-8" style={{ width: "82vw" }}>
        {requestType === "RATES" ? (
          <RateDetails
            id={selectedRequest}
            status="new"
            setTrigger={setTrigger}
            setRequestType={setRequestType}
          />
        ) : requestType === "BIZHOURS" ? (
          <BizHoursDetails status="new" />
        ) : requestType === "HOLIDAYS" ? (
          <HolidaysDetails status="new" />
        ) : null}
      </div>
    </div>
  );
};

export default NewRequests;
