import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import React from "react";

const Currency = (props) =>
  props.number.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const Number = (props) =>
  props.number.toLocaleString("en-IN", { maximumFractionDigits: 2 });

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    // backgroundColor: "#E4E4E4"
  },
  image: {
    // height: 200,
    // width: 150
    marginTop: 10,
    marginBottom: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    // borderBottom: "1pt solid black"
    // backgroundColor: "#4f4f4f"
  },
  text: {
    fontSize: 14,
    lineHeight: 2,
    // borderBottom: "1px solid #000"
  },
});

const MyDocument = (props) => {
  // if (props) console.log("gold data", props);

  // { props.data.number_of_items === 0 ? null : }

  if (props.data.number_of_items === 0) {
    return <Document> </Document>;
  } else {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={[
              styles.section,
              {
                textAlign: "center",
                borderBottom: "1pt solid black",
              },
            ]}
          >
            <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
              Loan ID: {props.data.loan_id}
            </Text>
          </View>
          <View style={[styles.section, { borderBottom: "1pt solid black" }]}>
            <Text style={styles.text}>
              Number of items: {props.data.number_of_items} nos.
            </Text>
            <Text style={styles.text}>
              Total actual gold weight: {props.data.total_actual_weight} grams
            </Text>
            <Text style={styles.text}>
              Total equivalent gold weight: {props.data.total_weight} grams
            </Text>
          </View>

          <View style={styles.section}>
            {props.data.golds.map((goldItem, index) => {
              if (index !== 0) {
                return (
                  <View break style={{ paddingTop: 20 }}>
                    {/* <View style={{ flexDirection: "row" }}> */}
                    <View>
                      <Text style={styles.text}>
                        Gold ID: {goldItem.gold_display_id}
                      </Text>
                      <Text style={styles.text}>
                        Gold Type: {goldItem.type}
                      </Text>
                      <Text style={styles.text}>
                        Quantity: {goldItem.quantity} nos.
                      </Text>
                      <Text style={styles.text}>
                        Quality: {goldItem.quality}
                      </Text>
                      <Text style={styles.text}>
                        Gross weight: <Number number={goldItem.gross_weight} />{" "}
                        grams
                      </Text>
                      <Text style={styles.text}>
                        Actual Net weight:{" "}
                        <Number number={goldItem.actual_net_weight} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Equivalent Net weight:{" "}
                        <Number number={goldItem.net_weight} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Stone deduction:{" "}
                        <Number number={goldItem.stone_deduction} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Seal ID: {goldItem.pickup_seal_id}
                      </Text>
                      <Text style={styles.text}>
                        Rate per gm: Rs. <Currency number={props.data.rate} />
                      </Text>
                      <Text style={styles.text}>
                        Value: Rs. <Currency number={goldItem.amount} />
                      </Text>
                      {/* <Text style={styles.text}>Images:</Text> */}
                    </View>
                    {/* <View>
                      {goldItem.pickup_gold_images.map(image => {
                        return <Image src={image} style={styles.image} />;
                      })}
                    </View> */}
                  </View>
                );
              } else {
                return (
                  <View>
                    {/* <View style={{ flexDirection: "row" }}> */}
                    <View>
                      <Text style={styles.text}>
                        Gold ID: {goldItem.gold_display_id}
                      </Text>
                      <Text style={styles.text}>
                        Gold Type: {goldItem.type}
                      </Text>
                      <Text style={styles.text}>
                        Quantity: {goldItem.quantity} nos.
                      </Text>
                      <Text style={styles.text}>
                        Quality: {goldItem.quality}
                      </Text>
                      <Text style={styles.text}>
                        Gross weight: <Number number={goldItem.gross_weight} />{" "}
                        grams
                      </Text>
                      <Text style={styles.text}>
                        Actual Net weight:{" "}
                        <Number number={goldItem.actual_net_weight} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Equivalent Net weight:{" "}
                        <Number number={goldItem.net_weight} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Stone deduction:{" "}
                        <Number number={goldItem.stone_deduction} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Seal ID: {goldItem.pickup_seal_id}
                      </Text>
                      <Text style={styles.text}>
                        Rate per gm: Rs. <Currency number={props.data.rate} />
                      </Text>
                      <Text style={styles.text}>
                        Value: Rs. <Currency number={goldItem.amount} />
                      </Text>
                      {/* <Text style={styles.text}>Images:</Text> */}
                    </View>
                    {/* <View>
                      {goldItem.pickup_gold_images.map(image => {
                        return <Image src={image} style={styles.image} />;
                      })}
                    </View> */}
                  </View>
                );
              }
            })}
          </View>
          <View>
            {props.data.goldImages.map((image) => {
              return <Image src={image.source} style={styles.image} />;
            })}
          </View>
        </Page>
      </Document>
    );
  }
};

export default MyDocument;
