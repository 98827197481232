// DEPS
import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Radio, notification } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";

// COMPONENTS
import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/loading";

// APIS
import {
  GET_PARTNER_DETAILS,
  GET_PROFILE,
  GET_BRANCH_DETAILS,
  UPDATE_NOTIFICATION_SOUND
} from "./graphql";
import RadioButton from "../../components/Inputs/RadioButton";
import Utils from "../../utils";
import AuthUtils from "../../utils/AuthUtils";
import { useBankDetails } from "../../contexts/BankDetailsContext";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const PartnerProfile = props => {
  const { currentBankName } = useBankDetails()
  const [notifSound, setNotifSound] = useState(null);

  const [updateNotificationSound] = useMutation(UPDATE_NOTIFICATION_SOUND, {
    onError(error) {
      error.graphQLErrors.map(error => {
        // console.log(error);
        return notification.error({
          message: "Error!",
          description: "Something went wrong!"
        });
      });
    },
    onCompleted() {
      notification.success({
        message: "Success!",
        description: "Notification sound updated!"
      });
    }
  });

  const {
    data: branchData,
    loading: branchDetailsLoading,
    error: branchDetailsError
  } = useQuery(GET_BRANCH_DETAILS);

  const {
    data: profile,
    loading: profileLoading,
    error: profileQueryError
  } = useQuery(GET_PROFILE, {
    onCompleted: () => setNotifSound(Utils.arrayCheck(profile?.users)[0]?.notification_sound)
  });

  const {
    data: partnerDetails,
    loading: partnerDetailsLoading,
    error: partnerDetailsQueryError
  } = useQuery(GET_PARTNER_DETAILS);

  if (branchDetailsLoading || profileLoading || partnerDetailsLoading) {
    return <Loading />;
  }

  if (branchDetailsError || profileQueryError || partnerDetailsQueryError) {
    return <ErrorPage></ErrorPage>;
  }

  return (
    <div className="pl-6 pt-4 pb-32 space-y-8">
      <p className="text-base">
        Please contact{" "}
        <a
          href="mailto:naveen@nfnlabs.in"
          className="underline hover:underline text-yellow-600"
        >
          <Link to="/support">Oro Support</Link>
        </a>{" "}
        if you would like to edit your profile.
      </p>

      {getValueFromCookie("user")?.["X_Hasura_Default_Role"] ===
        "cst" ||
      getValueFromCookie("user")?.["X_Hasura_Default_Role"] ===
        "maker" ||
      getValueFromCookie("user")?.["X_Hasura_Default_Role"] ===
        "checker" ? (
        <div className="space-y-2 w-10/12">
          <h4>PROFILE OVERVIEW</h4>

          <div className="border bg-white">
            <div className="border-b flex p-4 w-full">
              <div className="w-1/5">
                <h5 className="text-xs">PARTNER BRANCH ID</h5>
                <p className="text-base font-bold text-black">
                  {partnerDetails.bank_profile[0].display_id}
                </p>
              </div>

              <div className="w-1/5">
                <h5 className="text-xs">PARTNER NAME</h5>
                <p className="text-base font-bold text-black">
                  {partnerDetails.bank_profile[0].name}
                </p>
              </div>

              <div className="w-1/5">
                <h5 className="text-xs">PARTNER TYPE</h5>
                {getValueFromCookie("user")?.["X_Hasura_Default_Role"] === "cst" ? (
                  <p className="text-base font-bold text-black">
                    Central Sanctioning Team
                  </p>
                ) : getValueFromCookie("user")?.["X_Hasura_Default_Role"] === "maker" ? (
                  <p className="text-base font-bold text-black">Maker</p>
                ) : (
                  <p className="text-base font-bold text-black">Checker</p>
                )}
              </div>

              <div className="w-1/5">
                <h5 className="text-xs">CREATED ON</h5>
                <p className="text-base font-bold text-black">
                  {moment(partnerDetails.bank_profile[0].created_at).format(
                    "DD.MM.YYYY hh:mm a"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-2 w-10/12">
          <h4>BRANCH PROFILE OVERVIEW</h4>
          {/* {console.log("here")} */}

          <div className="border bg-white">
            <div className="border-b flex p-4 w-full">
              <div className="w-2/12">
                <h5 className="text-xs">PARTNER BRANCH ID</h5>
                <p className="text-base text-base font-bold text-black">
                  {branchData.branch_profile[0].branch_display_id}
                </p>
              </div>

              <div className="w-2/12">
                <h5 className="text-xs">BRANCH NAME</h5>
                <p className="text-base font-bold text-black">
                  {branchData.branch_profile[0].name}
                </p>
              </div>

              <div className="w-2/12">
                <h5 className="text-xs">PARTNER TYPE</h5>
                <p className="text-base font-bold text-black">
                  {getValueFromCookie("user")?.["X_Hasura_Default_Role"] === "blc"
                    ? "Checker"
                    : "Verifier"}
                </p>
              </div>

              <div className="w-2/12">
                <h5 className="text-xs">PARTNER NAME</h5>
                <p className="text-base font-bold text-black">{currentBankName}</p>
              </div>

              <div className="w-2/12">
                <h5 className="text-xs">CREATED ON</h5>
                <p className="text-base font-bold text-black">
                  {moment(branchData.branch_profile[0].created_at).format(
                    "DD.MM.YYYY hh:mm a"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* PARTNER DETAILS */}
      <div className="space-y-2 w-10/12">
        <h4>PARTNER DETAILS</h4>

        <div className="border bg-white">
          <div className="border-b flex p-4 w-full">
            <div className="w-2/12">
              <h5 className="text-xs">POC NAME</h5>
              <p className="text-base font-bold text-black">
                {profile.users[0].first_name} {profile.users[0].last_name}
              </p>
            </div>

            <div className="w-2/12">
              <h5 className="text-xs">POC EMAIL ADDRESS</h5>
              <p className="text-base font-bold text-black">
                {profile.users[0].email_id}
              </p>
            </div>

            <div className="w-2/12">
              <h5 className="text-xs">POC CONTACT NO.</h5>
              <p className="text-base font-bold text-black">
                {profile.users[0].mobile_number}
              </p>
            </div>

            {getValueFromCookie("user")?.["X_Hasura_Default_Role"] === "blc" ||
            getValueFromCookie("user")?.["X_Hasura_Default_Role"] === "blv" ? (
              <div className="w-6/12">
                <h5 className="text-xs">BRANCH ADDRESS</h5>
                {/* {console.log("not here")} */}
                <p className="text-base font-bold text-black">
                  {branchData.branch_profile[0].address.addr_line_1},
                  {branchData.branch_profile[0].address.addr_line_2},
                  {branchData.branch_profile[0].address.city},
                  {branchData.branch_profile[0].address.state} -{" "}
                  {branchData.branch_profile[0].address.pincode}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* PARTNER DETAILS */}

      {/* BUSINESS HOURS */}
      <div className="space-y-2 w-1/2">
        <h4>BUSINESS HOURS</h4>
        <table>
          <tr>
            <th className="pr-2 text-gray-600">DAYS OF THE WEEK</th>
            <th className="pl-2 text-gray-600">BUSINESS HOURS</th>
          </tr>
          <tr>
            <td className="pr-2 font-semibold text-base">Monday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Monday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Monday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Monday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Monday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>

          <tr>
            <td className="pr-2 font-semibold text-base">Tuesday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Tuesday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Tuesday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Tuesday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Tuesday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>

          <tr>
            <td className="pr-2 font-semibold text-base">Wednesday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Wednesday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Wednesday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Wednesday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Wednesday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>

          <tr>
            <td className="pr-2 font-semibold text-base">Thursday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Thursday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Thursday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Thursday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Thursday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>

          <tr>
            <td className="pr-2 font-semibold text-base">Friday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Friday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Friday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Friday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Friday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>

          <tr>
            <td className="pr-2 font-semibold text-base">Saturday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Saturday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Saturday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Saturday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Saturday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>

          <tr>
            <td className="pr-2 font-semibold text-base">Sunday</td>
            <td className="pl-2 font-semibold text-base">
              {partnerDetails.bank_profile[0].working_hours.Sunday.start !==
                undefined &&
              partnerDetails.bank_profile[0].working_hours.Sunday.end !==
                undefined
                ? moment(
                    partnerDetails.bank_profile[0].working_hours.Sunday.start
                  ).format("HH:mm") +
                  " - " +
                  moment(
                    partnerDetails.bank_profile[0].working_hours.Sunday.end
                  ).format("HH:mm")
                : "Closed"}
            </td>
          </tr>
        </table>
      </div>
      {/* BUSINESS HOURS */}

      <div className="space-y-2">
        <h4 className="uppercase">INCOMING NOTIFICATIONS</h4>
        <div className="flex">
          {['Buzzer', 'Soft bells', 'Bells', 'None'].map((sound) => 
          <div className="flex cursor-pointer" onClick={(e) => setNotifSound(sound)}>
            <RadioButton isSelected={notifSound===sound} />
            <p className="px-3 text-sm">
              {sound}
            </p>
          </div>)}
        </div>
      </div>
      <div>
        <button
          className="cta py-2"
          onClick={() =>
            updateNotificationSound({
              variables: {
                sound: notifSound
                  ? notifSound
                  : profile.users[0].notification_sound
              }
            })
          }
        >
          Update Notification Settings
        </button>
      </div>
    </div>
  );
};

export default PartnerProfile;
