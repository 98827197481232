import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import React from "react";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column"
    // backgroundColor: "#E4E4E4"
  },
  image: {
    // height: 200,
    // width: 150
    marginTop: 10,
    marginBottom: 10
  },
  section: {
    margin: 10,
    padding: 10
    // flexGrow: 1,
    // borderBottom: "1pt solid black"
    // backgroundColor: "#4f4f4f"
  },
  text: {
    fontSize: 14,
    lineHeight: 2
    // borderBottom: "1px solid #000"
  }
});

const UserBankAccountDetails = props => {
  // console.log("KYC data", props.data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={[
            styles.section,
            {
              textAlign: "center",
              borderBottom: "1pt solid black"
            }
          ]}
        >
          <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
            {props.data.account_name}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>
            Account number: {props.data.account_number}
          </Text>
          <Text style={styles.text}>Bank branch: {props.data.bank_branch}</Text>
          <Text style={styles.text}>Bank IFSC: {props.data.ifsc}</Text>
        </View>

        <View style={styles.section} break>
          <Text style={styles.text}>Bank proof: </Text>
          {props.data.bank_account_proof.map((proof, index) => {
            return (
              <View break={index}>
                <Text style={styles.text}>{proof.type}</Text>
                <Image source={proof.image_url} />
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default UserBankAccountDetails;
