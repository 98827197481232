// DEPS
import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  Spin,
  Popconfirm,
  Steps,
  Tag,
  Modal as AntModal,
  notification,
  Empty,
} from "antd";
import moment from "moment";
import Carousel, { Modal, ModalGateway } from "react-images";

// COMPONENTS
import ConfirmCallbackModal from "../../../components/Modals/confirmCallback";
import ErrorPage from "../../../components/ErrorPage";
import Sidebar from "../../../components/Sidebar";
import StepsContainer from '../../../components/Steps';
import ViewPdf from '../../../components/ViewPdf';

// VIEWS
import GoldAppraisalDetails from "../../../views/goldAppraisalDetails";
import UserKYCDetails from "../../../views/userKYCDetails";
import AgentDetailsView from "../../../views/agentDetails";
import Timestamps from "../../../views/timestamps";
import UserBankAccountDetails from "../../../views/userBankAccountDetails";
import LoanDetails from "../../../views/loanDetails";
import PlanDetails from "../../../views/planDetails";
import CustomerDetails from "../../../views/customerDetails";

// APIS
import {
  GET_LOAN_BY_ID,
  SET_LOAN_RELEASED_STATUS,
  GENERATE_OTP,
  CHECK_OTP,
  UPDATE_VISIT_STATUS_GOLD_HANDED_OVER,
} from "../graphql";
import { get_image_url } from "../../../utils";
import AuthUtils from "../../../utils/AuthUtils";

//CSS
import './stored.css';
import OtpLogs from "../../../views/otpLogDetails";
import { getValueFromLocalStorage } from "../../../utils/local-storage";
import { getValueFromCookie } from "../../../utils/cookies";

const { Step } = Steps;

const BLCStoredLoans = (props) => {
  const [trigger, setTrigger] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(0);
  const [isGoldReleaseModalOpen, setGoldReleaseModalOpen] = useState(false);
  const [
    isValidateReleaseOTPModalOpen,
    setValidateReleaseOTPModalOpen,
  ] = useState(false);
  const [otp, setOTP] = useState(null);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [toggleViewPdfModal, setToggleViewPdfModal] = useState({
    toggle: false,
    url: '',
    title: '',
  });  

  const [checkOTP, { data: OTPData }] = useLazyQuery(CHECK_OTP, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (OTPData) {
      if (OTPData.check_otp.is_active) {
        notification.error({
          message: "Error!",
          description: "OTP has not been validated yet",
        });
      } else {
        setValidateReleaseOTPModalOpen(false);
        setGoldReleaseModalOpen(true);
      }
    }
  }, [OTPData]);

  const [generateOTP] = useMutation(GENERATE_OTP, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "OTP generated",
      });
      setOTP(data.generate_otp.otp);
      // setValidateReleaseOTPModalOpen(false);
      // setGoldReleaseModalOpen(true);
    },
  });

  const [setLoanReleasedStatus] = useMutation(SET_LOAN_RELEASED_STATUS, {
    //   update(cache, { data: mutationData }) {
    //     let offsetValue = index - (index % 10);
    //     console.log("cache", cache);
    //     console.log("loan", mutationData);
    //     console.log("offset", offsetValue);
    //     const { loans } = cache.readQuery({
    //       query: GET_STORED_LOANS_FOR_PARTNER_BRANCH,
    //       variables: {
    //         offset: offsetValue,
    //         limit: 10,
    //         id: null
    //       }
    //     });
    //     // console.log("loans", loans);

    //     const newLoans = loans.filter(
    //       element =>
    //         element.id !== mutationData.update_visit_intermediate_status.id
    //     );

    //     client.writeQuery({
    //       query: GET_STORED_LOANS_FOR_PARTNER_BRANCH,
    //       variables: {
    //         offset: offsetValue,
    //         limit: 10,
    //         id: null
    //       },
    //       data: {
    //         loans: newLoans
    //       }
    //     });

    //     // console.log("update cache done");
    //     setGoldReleaseModalOpen(false);
    //     setSelectedLoan(0);
    //   },
    onError(error) {
      error.graphQLErrors.map((error) => {
        return notification.error({
          message: "Error!",
          description:
            "Something went wrong. Please check all details and try again",
        });
      });
    },
    onCompleted(data) {
      // notification.success({
      //   message: "Success!",
      //   description: "Gold Released"
      // });
      // setGoldReleaseModalOpen(false);
      // setTrigger(true);
      let visit_id = loanData.loans_by_pk.visits.filter((visit) => {
        // console.log("visit", visit);
        return visit.type === "GR";
      });

      // console.log("visit_id", visit_id, visit_id[0], visit_id[0].id);

      updateVisitStatusToGoldHandedOver({
        variables: {
          visit_id: visit_id[0].id,
        },
      });
    },
  });

  const [updateVisitStatusToGoldHandedOver] = useMutation(
    UPDATE_VISIT_STATUS_GOLD_HANDED_OVER,
    {
      onError(error) {
        error.graphQLErrors.map((error) => {
          // console.log(error);
          return notification.error({
            message: "Error!",
            description:
              "Something went wrong. Please check all details and try again",
          });
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success!",
          description: "Gold Released",
        });
        setGoldReleaseModalOpen(false);
        setTrigger(true);
      },
    }
  );

  const [
    getLoanById,
    { loading: loanDataLoading, data: loanData, error: loanQueryError },
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getLoanById({ variables: { id: selectedLoan || 0 } });
  }, [selectedLoan]);

  if (loanQueryError) return <ErrorPage />;

  return (
    <div className='flex flex-row space-x-4'>
      {/* Sidebar */}
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        loan_statuses={['GOLD_STORED']}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      {/* Sidebar */}

      <div className='w-full space-y-8 py-4 stored-blc overflow-auto'>
        {/* {console.log("loanData", loanData)} */}

        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <>
              <div className='flex w-full justify-between space-x-16'>
                {loanData ? (
                  loanData.loans_by_pk !== null ? (
                    <>
                      <div className='w-6/12  flex space-x-8'>
                        <StepsContainer
                          count={2}
                          steps={[
                            'REQUEST RECEIVED',
                            'LOAN APPROVED',
                            'GOLD STORED',
                            'GOLD RELEASED',
                            'LOAN CLOSED',
                          ]}
                        />
                      </div>

                      <div className='w-1/6 space-y-1 text-right'>
                        <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                        <p className='text-gray-600 font-semibold'>
                          {moment(loanData.loans_by_pk.created_at).format(
                            'DD-MM-YYYY HH:mm'
                          )}
                        </p>
                        <Tag color={'green'} key='GOLD STORED'>
                          STORED
                        </Tag>
                      </div>
                    </>
                  ) : null
                ) : null}
              </div>

              <div className='space-y-8 '>
                <Timestamps loan_id={loanData.loans_by_pk.id} />
                <div className='flex w-full justify-between items-center'>
                  <div className='flex space-x-8 items-center'>
                    {loanData.loans_by_pk.visits.filter((visit) => {
                      return visit.type === 'GR';
                    }).length > 0 ? (
                      <button
                        type='submit'
                        className='btn-primary hover:shadow-lg'
                        onClick={() => {
                          if (
                            loanData.loans_by_pk.visits.filter((visit) => {
                              return visit.type === 'GR';
                            })[0].intermediate_status ===
                            'LENDING_PARTNER_BRANCH_OTP_VALIDATED'
                          )
                            setGoldReleaseModalOpen(true);
                          else setValidateReleaseOTPModalOpen(true);
                        }}
                        // onClick={() => setGoldReleaseModalOpen(true)}
                      >
                        Release Gold Ornaments
                      </button>
                    ) : null}

                    <ConfirmCallbackModal />
                  </div>
                  {loanData?.loans_by_pk?.receipts != null ? (
                  <div className="flex">
                    {loanData?.loans_by_pk?.receipts?.signed_pledge_card?.length > 0 && (
                      <button
                      type="submit"
                      className="text-xs px-3 cta py-2"
                      style={{ minWidth: "220px" }}
                      onClick={() => {
                        setToggleViewPdfModal((prev) => ({
                          ...prev,
                          toggle: true,
                          title: 'Signed Pledge Card',
                          url:
                          loanData.loans_by_pk?.receipts?.signed_pledge_card?.[0]
                        }));
                      }}  
                    >
                      View Signed Pledge Card
                    </button>
                    )}
                    {loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement && (
                      <button
                        type="submit"
                        className="text-xs cta py-2 ml-4"
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          setToggleViewPdfModal((prev) => ({
                            ...prev,
                            toggle: true,
                            title: 'Signed KFS',
                            url:
                            loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement?.[0]
                          }));
                        }}   
                      >
                        View Signed KFS
                      </button>
                    )}
                  </div>
                ) : null}
                  {/* <DownloadLoanDetailsButton
                    loan_id={loanData.loans_by_pk.id}
                  /> */}
                </div>
                <div className='w-full flex space-x-8'>
                  {/* Loan request */}
                  <LoanDetails
                    loan_number={loanData.loans_by_pk.loan_number}
                    loan_type={loanData.loans_by_pk.loan_type.loan_type}
                    loan_amount={
                      loanData.loans_by_pk.od_amount
                        ? loanData.loans_by_pk.od_amount.credit_limit
                        : loanData.loans_by_pk.gl_amount
                        ? loanData.loans_by_pk.gl_amount.loan_amount
                        : null
                    }
                    gold_gross_weight={parseFloat(
                      loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.gross_weight;
                      }, 0)
                    ).toFixed(2)}
                    gold_net_weight={parseFloat(
                      loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.net_weight;
                      }, 0)
                    ).toFixed(2)}
                    gold_actual_net_weight={parseFloat(
                      loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                        return acc + goldItem.actual_net_weight;
                      }, 0)
                    ).toFixed(2)}
                  />
                  {/* Loan request */}

                  {/*Plan details */}
                  <PlanDetails
                    scheme_name={loanData.loans_by_pk.plan.scheme_name}
                    min_amt={loanData.loans_by_pk.plan.minimum_amount}
                    max_amt={loanData.loans_by_pk.plan.maximum_amount}
                    tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                      (total, obj) => obj.intervals + total,
                      0
                    )} months`}
                    interest={`   ${Math.max.apply(
                      Math,
                      loanData.loans_by_pk.plan.slab_details.map(function (o) {
                        return o.lender_interest_rate;
                      })
                    )} %`}
                    interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                    interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                    loan_type={loanData.loans_by_pk.loan_type.loan_type}
                  />
                  {/* Plan details */}
                </div>

                <CustomerDetails loan_id={loanData.loans_by_pk.id} />

                <AgentDetailsView loan_id={loanData.loans_by_pk.id} />

                {/* Gold details */}
                <GoldAppraisalDetails
                  id={loanData.loans_by_pk.id}
                  // hideExport={true}
                />
                {/* Gold details */}

                {/* User Info & KYC */}
                <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
                {/* User Info & KYC */}
                <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />

                {/* OTP Logs */}
                <OtpLogs loan_number={loanData?.loans_by_pk?.loan_renew_from?.loan_number}></OtpLogs>
                {/* OTP Logs */}

                <ModalGateway>
                  {isImageModalOpen && images.length > 0 ? (
                    <Modal
                      onClose={() => {
                        setIsImageModalOpen(!isImageModalOpen);
                      }}
                    >
                      <Carousel views={images} />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </div>
            </>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>
      {/* USE THIS MODAL TO VIEW PDF  */}
			{toggleViewPdfModal.toggle && (
				<ViewPdf
					url={toggleViewPdfModal.url}
					visible={toggleViewPdfModal.toggle}
					modalHeading={toggleViewPdfModal.title}
					onClose={() =>
						setToggleViewPdfModal((prev) => ({
							...prev,
							title: '',
							toggle: false,
							url: '',
						}))
					}
				/>
			)}
      {/* <button
        onClick={() => {
          console.log("opening modal");
          setGoldReleaseModalOpen(true);
        }}
      >
        Open modal
      </button> */}

      {/* OTP VALIDATION */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isValidateReleaseOTPModalOpen}
        footer={[
          <button
            className='btn-primary hover:shadow-lg'
            onClick={() => {
              checkOTP({
                variables: {
                  otp: otp,
                  auth_id: getValueFromCookie("user")?.auth_id, // todo: approved by QA
                },
              });
              // console.log("Function not developed yet");
            }}
            type='submit'
          >
            Next
          </button>,
          <Popconfirm
            title={`Are you sure you want to close?`}
            onConfirm={() => {
              setValidateReleaseOTPModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Relase Gold Ornaments</h1>
          </div>
          <div className='w-full'>
            {' '}
            <Steps current={0}>
              <Step title='REQUEST RECEIVED' />
              <Step title='AGENT VERIFIED' />
              <Step title='ORNAMENTS HANDED OVER' />
            </Steps>
          </div>

          <div className='text-center'>
            <h1>Verify Agent identity</h1>
            <p>
              Please verify the agent details and provide the OTP to proceed.
            </p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <div className='border bg-white'>
                  <div className='border-b flex p-2 w-full'>
                    {/* {console.log("t", loanData.loans_by_pk)} */}
                    {loanData.loans_by_pk.visits
                      .filter((visit) => {
                        return visit.type === 'GR';
                      })
                      .map((vis) => {
                        return (
                          <>
                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT NAME</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_first_name}
                                {vis.agent_last_name}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT ID</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_id}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>AGENT MOBILE NO.</h5>
                              <p className='font-bold text-black'>
                                {vis.agent_mobile_number}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all border-r'>
                              <h5>VISIT ID</h5>
                              <p className='font-bold text-black'>
                                {vis.visit_display_id}
                              </p>
                            </div>

                            <div className='m-3 w-1/5 break-all '>
                              <h5>AGENT PHOTO</h5>
                              <button
                                className='underline hover:underline text-yellow-primary'
                                onClick={async () => {
                                  const valid_url = await get_image_url({
                                    url: [vis.agent_photo],
                                  });

                                  setImages([
                                    {
                                      source: valid_url,
                                    },
                                  ]);
                                  setIsImageModalOpen(true);
                                }}
                              >
                                Click to view
                              </button>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className='text-center w-1/2 mx-auto space-y-4'>
                  <button
                    className='btn-primary'
                    onClick={() => {
                      let visit_id = loanData.loans_by_pk.visits.filter(
                        (visit) => {
                          // console.log("visit", visit);
                          return visit.type === 'GR';
                        }
                      );

                      // console.log("visit_id", visit_id);

                      generateOTP({
                        variables: {
                          agent_auth_id: visit_id[0].agent_auth_id,
                          visit_id: visit_id[0].id,
                        },
                      });
                      // console.log("Function not developed yet");
                    }}
                  >
                    Generate OTP
                  </button>
                  <p className={otp ? `font-bold text-lg` : 'hidden'}>
                    OTP is: {otp}
                  </p>
                  {/* console.log("Function not developed yet") */}
                </div>
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* OTP VALIDATION */}

      {/* RELEASE GOLD */}
      <AntModal
        width='960px'
        className='modal-title-center'
        title={null}
        visible={isGoldReleaseModalOpen}
        footer={[
          <button
            className={
              loanData
                ? 'btn-primary hover:shadow-lg'
                : 'bg-gray-100 py-2 px-4 border rounded'
            }
            onClick={() => {
              setLoanReleasedStatus({
                variables: {
                  id: loanData.loans_by_pk.id,
                  released_at: moment(),
                },
              });
            }}
            type='submit'
          >
            Complete handover
          </button>,
          <Popconfirm
            title={`Are you sure you want to close?`}
            onConfirm={() => {
              setGoldReleaseModalOpen(false);
            }}
            onCancel={() => {}}
            okText='Yes'
            placement='bottomRight'
            cancelText='No'
          >
            <button className='btn-danger hover:shadow-lg'>Close</button>
          </Popconfirm>,
        ]}
      >
        <div className='space-y-8'>
          <div className='text-center'>
            <h1>Release Gold Ornaments</h1>
          </div>
          <div className='w-full'>
            {' '}
            <Steps current={1}>
              <Step title='REQUEST RECEIVED' />
              <Step title='AGENT VERIFIED' />
              <Step title='ORNAMENTS HANDED OVER' />
            </Steps>
          </div>

          <div className='text-center'>
            <h1>Handover Gold Ornaments</h1>
            <p>Please verify the gold ornament details and complete handover</p>
          </div>

          {loanDataLoading ? (
            <Spin />
          ) : loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='space-y-8'>
                <GoldAppraisalDetails
                  id={loanData.loans_by_pk.id}
                  // hideExport={true}
                  size='medium'
                />
              </div>
            ) : null
          ) : null}
        </div>
      </AntModal>
      {/* RELEASE GOLD */}
    </div>
  );
};

export default BLCStoredLoans;
