// DEPS
import React, { useState } from "react";
import moment from "moment";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { notification } from "antd";

// ASSETS
import GreenCheck from "../../assets/green-check.png";
import Loader from "../../assets/loader.png";
import RedCross from "../../assets/red-cross.png";

// MODALS
import ApproveRate from "../../components/Modals/approveRequest";
import RejectRate from "../../components/Modals/rejectRequest";

// API
import { GET_RATES_HISTORY } from "../maker-rate";

// COMPONENTS
import ErrorPage from "../../components/ErrorPage";
import Loading from "../../components/loading";

const GET_RATE_BY_ID = gql`
  query getRateByID($id: Int!) {
    approvals_changelog(
      where: { id: { _eq: $id }, _and: { type: { _eq: "RATES" } } }
    ) {
      id
      status
      type
      data
      created_at
      created_by {
        id
        first_name
        last_name
      }
      confirmed_by {
        id
        first_name
        last_name
      }
    }
  }
`;

const APPROVE_REJECT_RATE = gql`
  mutation approveOrRejectRateUpdate($id: Int!, $status: String!) {
    update_approvals_changelog_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

const RateDetails = (props) => {
  const {
    loading: rateDataLoading,
    data: rateData,
    error: rateQueryError,
  } = useQuery(GET_RATE_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      id: props.id,
    },
  });

  const [approveOrRejectRate] = useMutation(APPROVE_REJECT_RATE, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        // console.log(error);
        return notification.error({
          message: "Error!",
          description: "Something went wrong. Please try again after some time",
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success!",
        description: "Rate updated",
      });
      props.setRequestType(null);
      props.setTrigger(true);
      setApproveModalOpen(false);
      setRejectModalOpen(false);
    },
  });

  const { loading: loadingRates, data: Rates, error } = useQuery(
    GET_RATES_HISTORY
  );

  const [disableBtn, setDisableBtn] = useState(false);

  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);

  if (rateDataLoading || loadingRates) return <Loading />;

  if (rateQueryError || error) {
    <ErrorPage />;
    // console.log("Error", rateQueryError, error);
  }

    //   console.log("rateData", rateData);

  return (
    <>
      <div className="space-y-8">
        {" "}
        <div className="w-full">
          <h1 className="uppercase text-xl font-bold">
            Gold rate for{" "}
            {moment(rateData.approvals_changelog[0].data.date).format(
              "MMMM D YYYY"
            )}
          </h1>
          <p>
            You will be able to update the gold rate between 6PM of previous day
            and 6PM of current day. Any change for updating the price for a
            given day has to be performed between this window
          </p>
        </div>
        {/* Status  */}
        <div className="w-1/2">
          <h2 className="text-lg font-bold text-gray-600">STATUS</h2>
          {disableBtn ||
          rateData.approvals_changelog[0].status === "TO_BE_APPROVED" ? (
            <div className="flex items-center space-x-2">
              {" "}
              <img
                src={Loader}
                alt="check"
                style={{ height: "1rem", width: "1rem" }}
              />
              <h2 className="uppercase font-bold">PENDING APPROVAL</h2>{" "}
            </div>
          ) : null}

          {rateData.approvals_changelog[0].status !== "TO_BE_APPROVED" ? (
            <div className="flex items-center space-x-2">
              <img
                src={
                  rateData.approvals_changelog[0].status === "APPROVED"
                    ? GreenCheck
                    : RedCross
                }
                alt="check"
                style={{ height: "1rem", width: "1rem" }}
              />
              <h2 className="uppercase font-bold">
                {rateData.approvals_changelog[0].status} BY{" "}
                {rateData.approvals_changelog[0].created_by?.first_name}{" "}
                {rateData.approvals_changelog[0].created_by?.last_name} - BANK
                CHECKER
              </h2>
            </div>
          ) : null}
        </div>
        {/* Status  */}
        {/* Updated by  */}
        <h2 className="text-black">
          Gold Rate for 22 carat (INR):{" "}
          {rateData.approvals_changelog[0].data.rate}
        </h2>
        <div className="flex space-x-16">
          <div>
            <h2 className="text-lg font-bold text-gray-600">UPDATED BY</h2>
            <p className="text-black">
              {rateData.approvals_changelog[0].created_by?.first_name}{" "}
              {rateData.approvals_changelog[0].created_by?.last_name}
            </p>
          </div>

          <div>
            <h2 className="text-lg font-bold text-gray-600">UPDATED ON</h2>
            <p className="text-black">
              {moment(rateData.approvals_changelog[0].created_at).format(
                "HH:mm, MMM DD, YYYY"
              )}
            </p>
          </div>
        </div>
        {/* Updated by  */}
        {/* Approve / Reject rate  */}
        {props.status === "new" ? (
          <div className="flex space-x-4">
            <button
              className="btn-primary"
              onClick={() => {
                // approveOrRejectRate({
                //   variables: { id: selectedRequest, status: "APPROVED" }
                // });
                setApproveModalOpen(true);
              }}
            >
              Approve Rate
            </button>
            <button
              className="btn-danger"
              onClick={() => {
                // approveOrRejectRate({
                //   variables: { id: selectedRequest, status: "REJECTED" }
                // });
                setRejectModalOpen(true);
              }}
            >
              Reject Rate
            </button>
          </div>
        ) : null}
        {/* Approve / Reject rate  */}
      </div>

      <div className="space-y-2">
        <h2 className="uppercase">Gold rate updation log (last 10 days)</h2>

        <table className="w-2/3">
          <colgroup>
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
            <col span="1" style={{ width: "20%" }} />
          </colgroup>

          <tr>
            <th className="uppercase">Date</th>
            <th className="uppercase">Updated on</th>
            <th className="uppercase">22 carat rate (INR)</th>
            <th className="uppercase">Set by</th>
            <th className="uppercase">Approved by</th>
          </tr>

          {Rates.approvals_changelog.map((rate) => {
            return (
              <tr>
                <td>
                  {moment(rate.data.date).format("MMM Do, YYYY") !==
                  "Invalid date"
                    ? moment(rate.data.date).format("MMM Do, YYYY")
                    : moment(rate.data.date, "MM-DD-YYYY").format(
                        "MMM Do, YYYY"
                      )}
                </td>
                <td>{moment(rate.created_at).format("HH:mm, MMM DD, YYYY")}</td>
                <td>{rate.data.rate}</td>
                <td>
                  {rate.created_by?.first_name} {rate.created_by?.first_name}
                </td>
                <td>
                  {rate.confirmed_by?.first_name} {rate.confirmed_by?.first_name}
                </td>
              </tr>
            );
          })}
        </table>
      </div>

      <>
        {" "}
        <ApproveRate
          rate={rateData.approvals_changelog[0].data.rate}
          isOpen={isApproveModalOpen}
          setOpen={setApproveModalOpen}
          approveRate={() => {
            approveOrRejectRate({
              variables: {
                id: props.id,
                status: "APPROVED",
              },
            });
          }}
        />
        <RejectRate
          rate={rateData.approvals_changelog[0].data.rate}
          isOpen={isRejectModalOpen}
          setOpen={setRejectModalOpen}
          rejectRate={() => {
            approveOrRejectRate({
              variables: { id: props.id, status: "REJECTED" },
            });
          }}
        />{" "}
      </>
    </>
  );
};

export default RateDetails;
