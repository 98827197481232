// DEPS
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Spin, Steps, Tag, Empty } from 'antd';
import moment from 'moment';

// COMPONENTS
import ConfirmCallbackModal from '../../../components/Modals/confirmCallback';
import ErrorPage from '../../../components/ErrorPage';
import Sidebar from '../../../components/Sidebar';
import StepsContainer from '../../../components/Steps';
import ViewPdf from '../../../components/ViewPdf';

// VIEWS
import GoldAppraisalDetails from '../../../views/goldAppraisalDetails';
import UserKYCDetails from '../../../views/userKYCDetails';
import AgentDetailsView from '../../../views/agentDetails';
import Timestamps from '../../../views/timestamps';
import UserBankAccountDetails from '../../../views/userBankAccountDetails';
import LoanDetails from '../../../views/loanDetails';
import PlanDetails from '../../../views/planDetails';
import CustomerDetails from '../../../views/customerDetails';

// APIS
import { GET_LOAN_BY_ID } from '../graphql';

//CSS
import './released.css';

const { Step } = Steps;

const BLCReleasedLoans = (props) => {
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [toggleViewPdfModal, setToggleViewPdfModal] = useState({
    toggle: false,
    url: '',
    title: '',
  });  

  const [
    getLoanById,
    { loading: loanDataLoading, data: loanData, error: loanQueryError },
  ] = useLazyQuery(GET_LOAN_BY_ID);

  useEffect(() => {
    getLoanById({ variables: { id: selectedLoan || 0 } });
  }, [selectedLoan]);

  if (loanQueryError) return <ErrorPage />;

  return (
    <div className='flex flex-row space-x-4'>
      {/* Sidebar */}
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        loan_statuses={['GOLD_RELEASED', 'CLOSED']}
      />
      {/* Sidebar */}

      <div className='w-full space-y-8 py-4 released-blc overflow-auto'>
        <div className='flex w-full justify-between space-x-16'>
          <div className='w-6/12 flex space-x-8'>
            {loanData ? (
              loanData.loans_by_pk !== null ? (
                loanData.loans_by_pk.loan_status === 'GOLD_RELEASED' ? (
                  <StepsContainer
                    count={3}
                    steps={[
                      'REQUEST RECEIVED',
                      'GOLD STORED',
                      'GOLD RELEASED',
                      'LOAN CLOSED',
                    ]}
                  />
                ) : (
                  <StepsContainer
                    count={4}
                    steps={[
                      'REQUEST RECEIVED',
                      'GOLD STORED',
                      'GOLD RELEASED',
                      'LOAN CLOSED',
                    ]}
                  />
                )
              ) : null
            ) : null}
          </div>
          {loanData ? (
            loanData.loans_by_pk !== null ? (
              <div className='w-1/6 space-y-1 text-right'>
                <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                <p className='text-gray-600 font-semibold'>
                  {moment(loanData.loans_by_pk.created_at).format(
                    'DD-MM-YYYY HH:mm'
                  )}
                </p>
                {loanData ? (
                  loanData.loans_by_pk !== null ? (
                    loanData.loans_by_pk.loan_status === 'GOLD_RELEASED' ? (
                      <Tag color={'green'} key='RELEASED' className='mr-0'>
                        GOLD ORNAMENTS RELEASED
                      </Tag>
                    ) : (
                      <Tag color={'green'} key='CLOSED' className='mr-0'>
                        LOAN CLOSED
                      </Tag>
                    )
                  ) : null
                ) : null}
              </div>
            ) : null
          ) : null}
        </div>

        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <div className='space-y-8'>
              <div className='flex justify-between items-center'>
                <Timestamps loan_id={loanData.loans_by_pk.id} />
              </div>

              <div className='flex w-full justify-between items-center'>
                <div>
                  {loanData.loans_by_pk.loan_status !== 'CLOSED' ? (
                    <ConfirmCallbackModal />
                  ) : null}
                </div>
                {loanData?.loans_by_pk?.receipts != null ? (
                  <div className="flex">
                    {loanData?.loans_by_pk?.receipts?.signed_pledge_card?.length > 0 && (
                      <button
                      type="submit"
                      className="text-xs px-3 cta py-2"
                      style={{ minWidth: "220px" }}
                      onClick={() => {
                        setToggleViewPdfModal((prev) => ({
                          ...prev,
                          toggle: true,
                          title: 'Signed Pledge Card',
                          url:
                          loanData.loans_by_pk?.receipts?.signed_pledge_card?.[0]
                        }));
                      }}  
                    >
                      View Signed Pledge Card
                    </button>
                    )}
                    {loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement && (
                      <button
                        type="submit"
                        className="text-xs cta py-2 ml-4"
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          setToggleViewPdfModal((prev) => ({
                            ...prev,
                            toggle: true,
                            title: 'Signed KFS',
                            url:
                            loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement?.[0]
                          }));
                        }}   
                      >
                        View Signed KFS
                      </button>
                    )}
                  </div>
                ) : null}
                {/* <DownloadLoanDetailsButton loan_id={loanData.loans_by_pk.id} /> */}
              </div>

              <div className='w-full flex space-x-8'>
                {/* Loan request */}
                <LoanDetails
                  loan_number={loanData.loans_by_pk.loan_number}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                  loan_amount={
                    loanData.loans_by_pk.od_amount
                      ? loanData.loans_by_pk.od_amount.credit_limit
                      : loanData.loans_by_pk.gl_amount
                      ? loanData.loans_by_pk.gl_amount.loan_amount
                      : null
                  }
                  gold_gross_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.gross_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.net_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_actual_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.actual_net_weight;
                    }, 0)
                  ).toFixed(2)}
                />
                {/* Loan request */}

                {/*Plan details */}
                <PlanDetails
                  scheme_name={loanData.loans_by_pk.plan.scheme_name}
                  min_amt={loanData.loans_by_pk.plan.minimum_amount}
                  max_amt={loanData.loans_by_pk.plan.maximum_amount}
                  tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                    (total, obj) => obj.intervals + total,
                    0
                  )} months`}
                  interest={`   ${Math.max.apply(
                    Math,
                    loanData.loans_by_pk.plan.slab_details.map(function (o) {
                      return o.lender_interest_rate;
                    })
                  )} %`}
                  interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                  interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                />
                {/* Plan details */}
              </div>

              <CustomerDetails loan_id={loanData.loans_by_pk.id} />

              <AgentDetailsView loan_id={loanData.loans_by_pk.id} />

              {/* Gold details */}
              <GoldAppraisalDetails id={loanData.loans_by_pk.id} />
              {/* Gold items */}

              {/* User Info & KYC */}
              <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
              {/* User Info & KYC */}

              <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />
            </div>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>
            {/* USE THIS MODAL TO VIEW PDF  */}
			{toggleViewPdfModal.toggle && (
				<ViewPdf
					url={toggleViewPdfModal.url}
					visible={toggleViewPdfModal.toggle}
					modalHeading={toggleViewPdfModal.title}
					onClose={() =>
						setToggleViewPdfModal((prev) => ({
							...prev,
							title: '',
							toggle: false,
							url: '',
						}))
					}
				/>
			)}
    </div>
  );
};

export default BLCReleasedLoans;
