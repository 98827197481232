import React from "react";
import { Modal as AntModal } from "antd";
import Warning from "../../assets/warning.png";
import UserBankAccountDetails from "../../views/userBankAccountDetails";
import { Currency } from "../Number";

const ApproveLoanModal = props => {
  return (
    <AntModal
      width="768px"
      className="modal-title-center"
      //   title="Store gold"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-6 mx-auto text-center">
        <div>
          <h1>Approve Loan</h1>
        </div>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>
        <p className="text-black text-base w-3/4 mx-auto">
          Clicking on the Approve Loan button will transfer{" "}
          <span className="font-bold">
            ₹ <Currency number={props.loanAmount} />
          </span>{" "}
          to the user, in their bank account mentioned below.
          {/* mentioned below. */}
        </p>

        <div className="text-left">
          <UserBankAccountDetails
            loan_id={props.loan_id}
            withoutProof={true}
            withoutExport={true}
          />
        </div>
        <div className="flex space-x-4 justify-center">
          <button className="btn-primary" onClick={() => props.approveLoan()}>
            Approve Loan
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default ApproveLoanModal;
