import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import React from "react";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column"
    // backgroundColor: "#E4E4E4"
  },
  image: {
    // height: 200,
    // width: 150
    marginTop: 10,
    marginBottom: 10
  },
  section: {
    margin: 10,
    padding: 10
    // flexGrow: 1,
    // borderBottom: "1pt solid black"
    // backgroundColor: "#4f4f4f"
  },
  text: {
    fontSize: 14,
    lineHeight: 2
    // borderBottom: "1px solid #000"
  }
});

const AgentDetails = props => {
  // console.log("Agent data", props.data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={[
            styles.section,
            {
              textAlign: "center",
              borderBottom: "1pt solid black"
            }
          ]}
        >
          <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
            {props.data.name}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>Agent ID: {props.data.id}</Text>
          <Text style={styles.text}>
            Agent phone number: {props.data.number}
          </Text>
          <Text style={styles.text}>
            Address: {props.data.address.addr_line_1},{" "}
            {props.data.address.addr_line_2}, {props.data.address.city},{" "}
            {props.data.address.state} - {props.data.address.pincode}
          </Text>
          <Text style={styles.text}>
            Agent's secondary contact number:{" "}
            {props.data.secondary_contact_number}
          </Text>
          <View>
            <Text style={styles.text}>Profile picture:</Text>
            <Image source={props.data.picture} />
          </View>
        </View>

        {/* <View style={styles.section}>
          <View>
            <Text style={styles.text}>Profile picture:</Text>
            <Image source={props.data.picture} />
          </View>
        </View> */}
      </Page>
    </Document>
  );
};

export default AgentDetails;
