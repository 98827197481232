import React from "react";
import Can from "../../components/Can";
import BLCRenewedLoans from "./blc/renewed";
import BLVRenewedLoans from "./blv/renewed";
import AuthUtils from "../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const RenewedLoans = props => {
  return (
    <Can
      role={
        getValueFromCookie("user")?.["X_Hasura_Default_Role"]
      }
      perform="blc"
      yes={() => {
        return <BLCRenewedLoans />;
      }}
      no={() => {
        return <BLVRenewedLoans />;
      }}
    />
  );
};

export default RenewedLoans;
