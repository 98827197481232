import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import _ from "lodash";
import { pdf } from "@react-pdf/renderer";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Input } from "antd";
import { get_image_url } from "../utils";
import { saveAs } from "file-saver";
import MyDocument from "../components/export/gold";
import ErrorPage from "../components/ErrorPage";

const GET_GOLD_ITEMS_FOR_APPRAISAL = gql`
	query getGoldItemsForAppraisal($loan_id: Int!) {
		loans_by_pk(id: $loan_id) {
			id
			loan_number
			rate {
				id
				ma_rate
			}
			golds {
				id
				gold_display_id
				quality
				quantity
				gross_weight
				actual_net_weight
				net_weight
				stone_deduction
				type
				pickup_seal_id
				pickup_seal_images
				sealed_cover_gross_weight
				gold_images
				pickup_sealed_gold_images
				amount
			}
		}
	}
`;

const SealIntegrityCheck = (props) => {
	const {loading: goldItemDetailsLoading, data: goldItems, error: goldItemsQueryError} = useQuery(GET_GOLD_ITEMS_FOR_APPRAISAL, {
		variables: {
			loan_id: props.id,
		},
	});
	
	const [creditBreakupData, setCreditBreakupData] = useState([]);
	
	const [goldImages, setGoldImages] = useState([]);
	const [isImageModalOpen, setImageModalOpen] = useState(false);
	
	const [sealImages, setSealImages] = useState([]);
	const [isSealImageModalOpen, setSealImageModalOpen] = useState(false);
	const [sealData, setSealData] = useState([]);
	
	useEffect(() => {
		async function setGolds() {
			if (goldItems) {
				if (goldItems.loans_by_pk !== null) {
					let goldImages = [];
					
					await Promise.all(
						goldItems.loans_by_pk.golds.map(async (goldItem) => {
							let gold_ornaments = await get_image_url({
								url: goldItem.gold_images.gold_ornaments,
							});
							
							let images = [];
							
							if (goldItem.gold_images.used_touchstone !== undefined && goldItem.gold_images.clean_touchstone !== undefined) {
								let used_touchstone = await get_image_url({
									url: goldItem.gold_images.used_touchstone,
								});
								
								let clean_touchstone = await get_image_url({
									url: goldItem.gold_images.clean_touchstone,
								});
								
								if (Array.isArray(gold_ornaments))
									images = [...gold_ornaments.map((image) => {
										return { source: image, caption: "Gold ornaments" };
									}),
										{
											source: used_touchstone,
											caption: "Used touchstone",
										},
										{
											source: clean_touchstone,
											caption: "Clean touchstone",
										},
									];
								else
									images = [
										{ source: gold_ornaments, caption: "Gold ornaments" },
										{
											source: used_touchstone,
											caption: "Used touchstone",
										},
										{
											source: clean_touchstone,
											caption: "Clean touchstone",
										},
									];
							} else {
								if (Array.isArray(gold_ornaments))
									images = gold_ornaments.map((image) => {
										return { source: image, caption: "Gold ornaments" };
									});
								else
									images = [
										{ source: gold_ornaments, caption: "Gold ornaments" },
									];
							}
							
							goldImages = [...goldImages, ...images];
							return;
						})
					);
					setGoldImages(goldImages);
				}
			}
		}
		
		setGolds();
	}, [goldItemDetailsLoading, goldItems]);
	
	useEffect(() => {
		async function setGolds() {
			if (goldItems) {
				if (goldItems.loans_by_pk !== null) {
					let sealImages = [];
					await Promise.all(
						goldItems.loans_by_pk.golds.map(async (goldItem) => {
							let sealed_gold = await get_image_url({
								url: goldItem.pickup_sealed_gold_images.sealed_gold,
							});
							
							let SealImages = [];
							
							if (goldItem.pickup_sealed_gold_images.sealed_gold_seal_ID !== undefined && goldItem.pickup_sealed_gold_images.sealed_gold_cover_wt !== undefined) {
								let sealed_gold_seal_ID = await get_image_url({
									url: goldItem.pickup_sealed_gold_images.sealed_gold_seal_ID,
								});
								
								let sealed_gold_cover_wt = await get_image_url({
									url: goldItem.pickup_sealed_gold_images.sealed_gold_cover_wt,
								});
								
								if (Array.isArray(sealed_gold))
									SealImages = [
										...sealed_gold.map((image) => {
											return { source: image, caption: "Sealed gold" };
										}),
										{ source: sealed_gold_seal_ID, caption: "Seal ID" },
										{
											source: sealed_gold_cover_wt,
											caption: "Sealed cover weight",
										},
									];
								else
									SealImages = [
										{ source: sealed_gold, caption: "Sealed gold" },
										{ source: sealed_gold_seal_ID, caption: "Seal ID" },
										{
											source: sealed_gold_cover_wt,
											caption: "Sealed cover weight",
										},
									];
							} else {
								if (Array.isArray(sealed_gold))
									SealImages = sealed_gold.map((image) => {
										return { source: image, caption: "Sealed gold" };
									});
								else
									SealImages = [
										{ source: sealed_gold, caption: "Sealed gold" },
									];
							}
							
							sealImages = [...sealImages, ...SealImages];
							
							return;
						})
					);
					
					setSealImages(sealImages);
				}
			}
		}
		setGolds();
	}, [goldItemDetailsLoading, goldItems]);
	
	useEffect(() => {
		async function setGolds() {
			if (goldItems) {
				if (goldItems.loans_by_pk !== null) {
					let groupedGold = _.groupBy(
						goldItems.loans_by_pk.golds,
						function (e) {
							return e.quality;
						}
					);
					
					let groupedGoldKeys = Object.keys(groupedGold);
					let goldTableData = [];
					
					groupedGoldKeys.map((key) => {
						// console.log("run", key);
						let total_quantity = groupedGold[key].reduce((total, goldItem) => {
							return total + goldItem.quantity;
						}, 0);
						
						let total_net_wt = groupedGold[key].reduce((total, goldItem) => {
							return total + goldItem.net_weight;
						}, 0);
						
						let total_actual_net_wt = groupedGold[key].reduce(
							(total, goldItem) => {
								return total + goldItem.actual_net_weight;
							},
							0
						);
						
						let rate = goldItems.loans_by_pk.rate.ma_rate;
						
						const obj = {
							quality: key,
							quantity: total_quantity,
							net_weight: total_net_wt,
							actual_net_weight: total_actual_net_wt,
							rate: rate,
							credit: total_net_wt * rate,
						};
						
						goldTableData.push(obj);
					});
					
					setCreditBreakupData(goldTableData);
				}
			}
		}
		setGolds();
	}, [goldItemDetailsLoading, goldItems]);
	
	useEffect(() => {
		if (goldItems) {
			if (goldItems.loans_by_pk !== null) {
				let groupedGoldForSeal = _.groupBy(goldItems.loans_by_pk.golds, (gold) => gold.pickup_seal_id);
				let sealTableData = [];
				Object.keys(groupedGoldForSeal).forEach(key => {
					let sealed_cover_gross_weight = groupedGoldForSeal[key][0].sealed_cover_gross_weight;
					let obj = {
						ornaments: groupedGoldForSeal[key].map((item) => ({
							gold_display_id: item.gold_display_id,
							type: item.type,
							quantity: item.quantity,
							id: item.id,
							weight: item.sealed_cover_gross_weight,
						})),
						sealed_cover_gross_weight: sealed_cover_gross_weight,
						pickup_seal_id: key,
					}
					sealTableData.push(obj);
				});
				setSealData(sealTableData)
			}
		}
	}, [goldItemDetailsLoading, goldItems])
	
	if (goldItemsQueryError) return <ErrorPage />;
	
	if (goldItemDetailsLoading) return null;
	
	return (
		<>
			{" "}
			<div
				className={props.hideExport ? "hidden justify-between items-center" : "flex justify-between items-center"}
			>
				<div>
					<h4>GOLD APPRAISAL DETAILS</h4>
					<div className="flex space-x-4">
						<p className="font-semibold">
							TOTAL NUMBER OF ORNAMENTS{" "}
							<span className="font-bold">
                {creditBreakupData.reduce((acc, data) => {
									return acc + data.quantity;
								}, 0)}
              </span>
						</p>
						<p className="font-semibold">
							TOTAL ACTUAL GOLD NET WEIGHT{" "}
							<span className="font-bold">
                {parseFloat(
									creditBreakupData.reduce((acc, data) => {
										return acc + data.actual_net_weight;
									}, 0)
								).toFixed(2)}{" "}
								grams
              </span>
						</p>
					</div>
				</div>
				
				<div className="space-x-4 flex">
					<button className="btn-white" onClick={() => setImageModalOpen(true)}>
						View Gold Photos
					</button>
					
					{/* Pdf is dynamically generated when the user clicks on the button */}
					{goldItems === undefined || creditBreakupData.length === 0 || goldImages.length === 0 ? null : (
						<button
							className="cta px-4 py-2"
							onClick={async () => {
								// const props = await getProps();
								const doc = (
									<MyDocument
										data={{
											loan_id: goldItems.loans_by_pk.loan_number,
											number_of_items: creditBreakupData.reduce((acc, data) => {
												return acc + data.quantity;
											}, 0),
											total_weight: `${parseFloat(
												creditBreakupData.reduce((acc, data) => {
													return acc + data.net_weight;
												}, 0)
											).toFixed(2)}`,
											total_actual_weight: `${parseFloat(
												creditBreakupData.reduce((acc, data) => {
													return acc + data.actual_net_weight;
												}, 0)
											).toFixed(2)}`,
											golds: goldItems.loans_by_pk.golds,
											goldImages: goldImages,
											rate: goldItems.loans_by_pk.rate.ma_rate,
											plans: goldItems.loans_by_pk.plan,
										}}
									/>
								);
								const asPdf = pdf([]); // {} is important, throws without an argument
								asPdf.updateContainer(doc);
								const blob = await asPdf.toBlob();
								saveAs(
									blob,
									`Gold details for loan ID: ${goldItems.loans_by_pk.loan_number}`
								);
							}}
						>
							Export gold details
						</button>
					)}
				</div>
			</div>
			{/* GOLD ITEMS */}
			<div className="space-y-4">
				{creditBreakupData.length === 0
					? null
					: sealData.map((sealItem, index) => (
						<div className="shadow-md" key={index}>
							<div className="bg-white border-l-4 border-yellow-primary">
								<div className="flex">
									<div className="m-3 w-2/6 break-all border-r">
										<p>ORNAMENT ID</p>
										{sealItem.ornaments.map((ornament, i) => (
											<p className="font-bold" key={i}>
												{ornament.gold_display_id}
											</p>
										))}
									</div>
									<div className="m-3 w-2/6 break-all border-r">
										<p>TYPE (QTY)</p>
											{sealItem.ornaments.map((ornament, i) => (
												<p className="font-bold" key={i}>
													{ornament.type} ({ornament.quantity})
												</p>
											))}
									</div>
									<div className="m-3 w-2/6 break-all border-r">
										<p>SEAL ID</p>
										<p className="font-bold">
											{sealItem.pickup_seal_id}
										</p>
									</div>
									
									<div className="m-3 w-2/6 break-all border-r">
										<p>SEAL GROSS WT.</p>
										<p className="font-bold">
											{sealItem.sealed_cover_gross_weight} grams
										</p>
									</div>
									
									<div className="m-3 w-2/6 break-all">
										<p>SEAL PHOTO</p>
										<button
											className="underline hover:underline text-yellow-primary"
											onClick={() => {
												setSealImageModalOpen(true);
											}}
										>
											Click to view
										</button>
									</div>
									<div className="m-3 w-2/6">
										<p>ENTER GROSS WT.</p>
										<Input
											className="py-2 text-gray-900 leading-tight ant-input-bg focus:outline-none focus:shadow-outline rounded-none"
											placeholder="Enter gross wt."
											onChange={(e) => {
												let temp = props.sealedCoverGrossWt;
												temp[index] = {
													pickup_seal_id: sealItem.pickup_seal_id,
													ornaments: sealItem.ornaments,
													seal_weight: e.target.value,
												};
												props.setSealedCoverGrossWt(temp);
											}}
										/>
									</div>
								</div>
							</div>
							{goldImages.length > 0 ? (
								<ModalGateway>
									{isImageModalOpen ? (
										<Modal onClose={() => setImageModalOpen(false)}>
											<Carousel views={goldImages} />
										</Modal>
									) : null}
								</ModalGateway>
							) : null}
							
							{sealImages.length > 0 ? (
								<ModalGateway>
									{isSealImageModalOpen ? (
										<Modal onClose={() => setSealImageModalOpen(false)}>
											<Carousel views={sealImages} />
										</Modal>
									) : null}
								</ModalGateway>
							) : null}
						</div>
					))}
			</div>
		</>
	);
};

export default SealIntegrityCheck;
