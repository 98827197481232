import React from "react";
import { Number, Currency } from "../components/Number";

const LoanDetails = props => {
  return (
    <div className="space-y-2 w-1/2">
      <h4>{props.title ?? "LOAN DETAILS"}</h4>

      <div className="border bg-white">
        {/* ROW 1 */}
        <div className="border-b flex p-4 w-full">
          <div className="w-1/3">
            <h5 className="text-xs">LOAN ID</h5>
            <p className="text-base font-bold text-black">{props.loan_number}</p>
          </div>
          <div className="w-1/3">
            <h5 className="text-xs">LOAN TYPE</h5>
            <p className="text-base font-bold text-black">
              {props.loan_type === 'OD' ? 'Overdraft' : 'Gold loan'}
            </p>
          </div>
          <div className="w-1/3">
            <h5 className="text-xs">LOAN AMOUNT / CREDIT LIMIT</h5>
            <p className="text-base font-bold text-black">
              ₹ <Currency number={props.loan_amount} />
            </p>
          </div>
        </div>
        {/* ROW 1 */}

        {/* ROW 2 */}
        <div className="border flex bg-white">
          <div className="flex p-4 w-full">
            <div className="w-1/3">
              <h5 className="text-xs">GOLD GROSS WT.</h5>
              <p className="text-base font-bold text-black">
                <Number number={props.gold_gross_weight} /> grams
              </p>
            </div>
            <div className="w-1/3">
              <h5 className="text-xs">ACUTAL GOLD NET WT.</h5>
              <p className="text-base font-bold text-black">
                <Number number={props.gold_actual_net_weight} /> grams
              </p>
            </div>
            <div className="w-1/3">
              <h5 className="text-xs">EQ. GOLD NET WT. (22 carats)</h5>
              <p className="text-base font-bold text-black">
                <Number number={props.gold_net_weight} /> grams
              </p>
            </div>
          </div>
        </div>
        {/* ROW 2 */}
      </div>
    </div>
  );
};

export default LoanDetails;
