import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { EmptyPage } from "./ErrorPage";
import { useSubscription } from "@apollo/react-hooks";
import {
  List,
  InfiniteLoader,
  AutoSizer,
  WindowScroller,
} from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { Input } from "antd";
import gql from "graphql-tag";

// const debounce = require("lodash.debounce");
// const { Search } = Input;

// $limit: Int!
//     $offset: Int!
// limit: $limit;
// offset: $offset;

const GET_REQUESTS_FOR_SIDEBAR = gql`
  subscription getRequestsForSideBar($status: String!) {
    approvals_changelog(
      order_by: { created_at: desc }
      where: { status: { _eq: $status } }
    ) {
      id
      data
      status
      type
      created_at
    }
  }
`;

const CheckerSidebar = (props) => {
  const [loading, setLoading] = useState(false);
  // const [searchText, setSearchText] = useState("");
  const [trigger, setTrigger] = useState(false);

  const myRef = useRef(null);
  let loadedRowsMap = {};

  const {
    data: requests,
    loading: loadingRequests,
    // error: loanQueryError,
    // subscribeToMore,
  } = useSubscription(GET_REQUESTS_FOR_SIDEBAR, {
    fetchPolicy: "network-only",
    variables: {
      // offset: 0,
      // limit: 10,
      // id: searchText !== "" ? parseInt(searchText.replace("OMOD", "")) : null,
      status: props.status,
    },
    onCompleted(FetchedData) {
      // console.log("FetchedData", FetchedData);
      // let temp = [...data, ...FetchedData.loans];
      // console.log("temp", temp);
      // setData(temp);
      // setLoading(false);
      // console.log("check");
      if (FetchedData.approvals_changelog.length > 0) {
        props.setSelectedRequest(FetchedData.approvals_changelog[0].id);
        props.setRequestType(FetchedData.approvals_changelog[0].type);
      }
    },
  });

  // TRIGGER FOR SETTING FIRST ITEM AS ACTIVE ON LOAD
  useEffect(() => {
    if (!trigger && requests !== undefined) {
      setTrigger(true);
      props.setSelectedRequest(
        requests.approvals_changelog.length > 0
          ? requests.approvals_changelog[0].id
          : null
      );
      props.setRequestType(
        requests.approvals_changelog.length > 0
          ? requests.approvals_changelog[0].type
          : null
      );
    }
  }, [loadingRequests]);

  useEffect(() => {
    // console.log("props.trigger", props.trigger);

    if (props.trigger) {
      let current_index = requests.approvals_changelog.findIndex(
        (rate) => rate.id === props.index
      );
      // console.log("current_index", current_index);

      if (requests.approvals_changelog[current_index + 1]) {
        props.setSelectedRequest(
          requests.approvals_changelog[current_index + 1].id
        );
        props.setRequestType(
          requests.approvals_changelog[current_index + 1].type
        );
      } else if (requests.approvals_changelog[current_index - 1]) {
        props.setSelectedRequest(
          requests.approvals_changelog[current_index - 1].id
        );
        props.setRequestType(
          requests.approvals_changelog[current_index + 1].type
        );
      } else {
        props.setSelectedRequest(0);
      }

      props.setTrigger(false);
    }
  }, [props.trigger]);

  const isRowLoaded = ({ index }) => {
    return !!loadedRowsMap[index];
  };

  const renderItem = ({ index, key, style }) => {
    const item = requests.approvals_changelog[index];

    // console.log("Index matching", props.index, index);

    return (
      <div
        key={key}
        style={style}
        className={
          props.index === item.id
            ? "px-4 py-6 border bg-yellow-primary bg-opacity-75"
            : "px-4 py-6 border hover:bg-yellow-primary hover:bg-opacity-25 cursor-pointer"
        }
        onClick={() => {
          props.setSelectedRequest(item.id);
          props.setRequestType(item.type);
        }}
      >
        {item ? (
          <>
            <h4
              className={
                props.index === item.id
                  ? "text-base font-semibold text-black"
                  : "text-base font-semibold text-black"
              }
            >
              {item.type === "RATES"
                ? "Gold Rate Update"
                : item.type === "BIZHOURS"
                ? "Business Hours Update"
                : item.type === "HOLIDAYS"
                ? "Holidays Update"
                : null}
            </h4>
            <p className="text-gray-600 font-semibold">
              {moment(item.created_at).format("DD-MM-YYYY HH:mm")}
            </p>
          </>
        ) : (
          "No item"
        )}
      </div>
    );
  };

  const handleInfiniteOnLoad = ({ startIndex, stopIndex }) => {
    // console.log("handleInfiniteLoadIndices", startIndex, stopIndex);
    setLoading(true);
    for (let i = startIndex; i <= stopIndex; i++) {
      // 1 means loading
      loadedRowsMap[i] = 1;
    }
    if (requests.approvals_changelog.length % 10 !== 0) {
      setLoading(false);
      return;
    }
    // subscribeToMore({
    //   variables: {
    //     offset: requests.length,
    //     limit: 10,
    //   },
    //   updateQuery: (prev, { fetchMoreResult }) => {
    //     if (!fetchMoreResult) return prev;
    //     // console.log("New data", fetchMoreResult);
    //     setLoading(false);
    //     return Object.assign({}, prev, {
    //       loans: [...prev.requests, ...fetchMoreResult.requests],
    //     });
    //     // let temp = [...data, ...fetchMoreResult.loans];
    //     // setData(temp);
    //   },
    // });
  };

  // const debouncedSearch = debounce(value => setSearchText(value), 1000);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "calc(100vh - 128px)",
        width: "18vw",
        overflow: "scroll",
        minWidth: "250px",
      }}
      className="border"
      ref={myRef}
    >
      {/* <Search
        // placeholder="Enter Loan ID"
        onChange={e => {
          console.log("Search text", e.target.value);
          debouncedSearch(e.target.value);
        }}
      /> */}
      {requests ? (
        requests.approvals_changelog.length > 0 ? (
          <WindowScroller scrollElement={myRef.current} key={myRef.current}>
            {({ isScrolling, onChildScroll, scrollTop }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={handleInfiniteOnLoad}
                rowCount={100}
              >
                {({ onRowsRendered }) => (
                  <AutoSizer>
                    {({ height, width }) => {
                      return (
                        <List
                          autoHeight
                          height={height}
                          isScrolling={isScrolling}
                          onScroll={onChildScroll}
                          overscanRowCount={2}
                          rowCount={requests.approvals_changelog.length}
                          // rowCount={1}
                          rowHeight={110}
                          rowRenderer={renderItem}
                          onRowsRendered={onRowsRendered}
                          scrollTop={scrollTop}
                          width={width}
                        />
                      );
                    }}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </WindowScroller>
        ) : (
          <EmptyPage />
        )
      ) : (
        <EmptyPage />
      )}
    </div>
  );
};

export default CheckerSidebar;
