import React from "react";
import Can from "../../components/Can";
import BLCReleasedLoans from "./blc/released";
import BLVReleasedLoans from "./blv/released";
import AuthUtils from "../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const ReleasedLoans = props => {
  return (
    <Can
      role={getValueFromCookie("user")?.["X_Hasura_Default_Role"]}
      perform="blc"
      yes={() => {
        return <BLCReleasedLoans />;
      }}
      no={() => {
        return <BLVReleasedLoans />;
      }}
    />
  );
};

export default ReleasedLoans;
