import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";
// import ForgotPassword from "../pages/Auth/forgotPassword";
// import LoginAD from "../pages/Auth/login-ad";
// import Login from "../pages/Auth/login";
// import ResetPassword from "../pages/Auth/resetPassword";
import NotFound from "../pages/notFound";
import Auth from "./Auth";
import { BankDetailsProvider } from "../contexts/BankDetailsContext";
import CSTApprovedLoans from "../pages/loans/cst/approved";

import NewLoans from "../pages/loans/new";
import StoredLoans from "../pages/loans/stored";
import ReleasedLoans from "../pages/loans/released";
import RejectedLoans from "../pages/loans/rejected";
import AllLoans from "../pages/loans/all";
import RenewedLoans from "../pages/loans/renewed";

import Profile from "../pages/Profile/profile";
import Support from "../pages/support";

import UpdateRate from "../pages/maker-rate";
import NewRequests from "../pages/Requests/new";
import ApprovedRequests from "../pages/Requests/approved";
import RejectedRequests from "../pages/Requests/rejected";
import AllRequests from "../pages/Requests/all";

import RedirectPage from "../pages/Auth/redirect";

import history from "../utils/history";

// for ApolloProvider
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, Observable } from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { onError } from "apollo-link-error";
import {refetchToken} from "../utils/userSessions";
import AuthUtils from "../utils/AuthUtils";

function Routes() {
  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: {
        // headers: header
        headers: {
          Authorization: `Bearer ${AuthUtils.getToken()}`
        }
      }
    }
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GQL_ENDPOINT,
    // headers: header
    headers: {
      Authorization: `Bearer ${AuthUtils.getToken()}`
    }
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        console.error("graphQLErrors", graphQLErrors);
        for (let err of graphQLErrors) {
          switch (err.extensions.code) {
            case "invalid-jwt":
              // console.log("invalid jwt");
              break;
            case "access-denied":
              return new Observable(observer => {
                refetchToken().then((res) => {
                  if (res.code === "200") {
                    AuthUtils.setToken(res.user.token);
                    let oldHeaders = operation.getContext().headers;
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        Authorization: `Bearer ${res.user.token}`,
                      },
                    });
      
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };
      
                    // Retry last failed request
                    return forward(operation).subscribe(subscriber);
                  } else {
                    observer.error({});
                  }
                }).catch(err => {
                  // No refresh or client token available, we force user to login
                  observer.error(err);
                });
              });
              // break;
            case "unexpected":
              return new Observable(observer => {
                refetchToken().then((res) => {
                  if (res.code === "200") {
                    AuthUtils.setToken(res.user.token);
                    let oldHeaders = operation.getContext().headers;
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        Authorization: `Bearer ${res.user.token}`,
                      },
                    });
      
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };
      
                    // Retry last failed request
                    return forward(operation).subscribe(subscriber);
                  } else {
                    observer.error({});
                  }
                }).catch(err => {
                  // No refresh or client token available, we force user to login
                  observer.error(err);
                });
              });
              // break;
            default:
              console.log("default error handler");
          }
        }
      }
      if (networkError) {
        // logoutUser();
        console.error("networkError", networkError);
        // for (let err of networkError) {
        if (networkError.extensions.code === "start-failed") {
          // case "start-failed":
          if (networkError.message === "cannot start as connection_init failed with : Invalid response from authorization hook") {
            return new Observable(observer => {
              refetchToken().then((res) => {
                if (res.code === "200") {
                  AuthUtils.setToken(res.user.token);
                  let oldHeaders = operation.getContext().headers;
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${res.user.token}`,
                    },
                  });
    
                  const subscriber = {
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  };
    
                  // Retry last failed request
                  return forward(operation).subscribe(subscriber);
                } else {
                  observer.error({});
                }
              }).catch(err => {
                // No refresh or client token available, we force user to login
                observer.error(err);
              });
            });
          }
        } else {
          console.log("default network error handler");
        }
      }
    }
  );

  const link = split(
    // split based on operation types
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: errorLink.concat(link),
    cache: new InMemoryCache({})
  });

  return (
    <ApolloProvider client={client}>
      <div style={{ margin: "0 auto" }}>
        <BankDetailsProvider>
        <Router history={history}>
          <Switch>
            <Auth
              path="/"
              exact
              component={RedirectPage}
              // allowed_roles={["super_admin"]}
            />

            <Auth
              path="/update-rate"
              exact
              component={UpdateRate}
              allowed_roles={["maker"]}
            />

            <Auth
              path="/requests"
              exact
              component={() => <Redirect to="/requests/new" />}
              allowed_roles={["checker"]}
            />

            <Auth
              path="/requests/new"
              exact
              component={NewRequests}
              allowed_roles={["checker"]}
            />

            <Auth
              path="/requests/approved"
              exact
              component={ApprovedRequests}
              allowed_roles={["checker"]}
            />

            <Auth
              path="/requests/rejected"
              exact
              component={RejectedRequests}
              allowed_roles={["checker"]}
            />

            <Auth
              path="/requests/all"
              exact
              component={AllRequests}
              allowed_roles={["checker"]}
            />

            <Auth
              path="/loans"
              exact
              component={<Redirect to="/loans/new-loans" />}
              allowed_roles={["cst", "blc", "blv", "maker", "checker"]}
            />
            <Auth
              path="/loans/new-loans"
              exact
              component={NewLoans}
              allowed_roles={["cst", "blc", "blv"]}
            />
            <Auth
              path="/loans/approved-loans"
              exact
              component={CSTApprovedLoans}
              allowed_roles={["cst"]}
            />
            <Auth
              path="/loans/renewed-loans"
              exact
              component={RenewedLoans}
              allowed_roles={["blc", "blv"]}
            />
            <Auth
              path="/loans/all-loans"
              exact
              component={AllLoans}
              allowed_roles={["cst", "blc", "blv"]}
            />
            <Auth
              path="/loans/rejected-loans"
              exact
              component={RejectedLoans}
              allowed_roles={["cst", "blc", "blv"]}
            />
            <Auth
              path="/loans/stored-loans"
              exact
              component={StoredLoans}
              allowed_roles={["cst", "blc", "blv"]}
            />
            <Auth
              path="/loans/released-loans"
              exact
              component={ReleasedLoans}
              allowed_roles={["cst", "blc", "blv"]}
            />
            <Auth
              path="/loans/rejected-loans"
              exact
              component={RejectedLoans}
              allowed_roles={["cst", "blc", "blv"]}
            />
            <Auth
              path="/profile"
              exact
              component={Profile}
              allowed_roles={["cst", "blc", "blv", "maker", "checker"]}
            />
            <Auth
              path="/support"
              exact
              component={Support}
              allowed_roles={["cst", "blc", "blv", "maker", "checker"]}
            />
            <Route path="*" component={NotFound}></Route>
          </Switch>
        </Router>
        </BankDetailsProvider>
      </div>
    </ApolloProvider>
  );
}

export default Routes;
