import gql from "graphql-tag";

const GET_PROFILE = gql`
  query getProfile {
    users {
      id
      first_name
      last_name
      email_id
      mobile_number
      notification_sound
    }
  }
`;

const GET_PARTNER_DETAILS = gql`
  query getPartnerDetails {
    bank_profile {
      id
      display_id
      name
      working_hours
      created_at
    }
  }
`;

const GET_BRANCH_DETAILS = gql`
  query getBranchDetails {
    branch_profile {
      id
      branch_display_id
      name
      address
      created_at
    }
  }
`;

const UPDATE_NOTIFICATION_SOUND = gql`
  mutation updateNotificationSound($sound: String!) {
    update_users(where: {}, _set: { notification_sound: $sound }) {
      returning {
        id
        notification_sound
      }
    }
  }
`;

export {
  GET_PROFILE,
  GET_PARTNER_DETAILS,
  GET_BRANCH_DETAILS,
  UPDATE_NOTIFICATION_SOUND
};
