import React from "react";
import Can from "../../components/Can";
import CSTNewLoans from "./cst/new";
import BLCNewLoans from "./blc/new";
import BLVNewLoans from "./blv/new";
import AuthUtils from "../../utils/AuthUtils";
import { getValueFromLocalStorage } from "../../utils/local-storage";
import { getValueFromCookie } from "../../utils/cookies";

const NewLoans = props => {
  return (
    <Can
      role={getValueFromCookie("user")?.["X_Hasura_Default_Role"]}
      perform="cst"
      yes={() => {
        return <CSTNewLoans />;
      }}
      no={() => {
        return (
          <Can
            role={
              getValueFromCookie("user")?.["X_Hasura_Default_Role"]
            }
            perform="blc"
            yes={() => {
              return <BLCNewLoans />;
            }}
            no={() => {
              return <BLVNewLoans />;
            }}
          />
        );
      }}
    />
  );
};

export default NewLoans;
