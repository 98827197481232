const Number = props => {
  return props.number.toLocaleString("en-IN", { maximumFractionDigits: 2 });
};

const Currency = props =>
  props.number.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

export { Number, Currency };
