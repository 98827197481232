import React, { useState, useEffect } from "react";
import { get_image_url } from "../utils";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_CUSTOMER_DETAILS = gql`
  query getCustomerDetails($loan_id: Int!) {
    loans_by_pk(id: $loan_id) {
      id
      account_number
      receipts
      customer {
        id
        customer_id
      }
    }
  }
`;

const CustomerDetails = (props) => {
  const {
    loading: customerDetailsLoading,
    data: customerDetails,
    error: customerDetailsQueryError,
  } = useQuery(GET_CUSTOMER_DETAILS, {
    variables: {
      loan_id: props.loan_id,
    },
  });

  const [releaseLetter, setReleaseLetter] = useState(null);
  const [pledgeCard, setPledgeCard] = useState(null);

  useEffect(() => {
    async function setReceipts() {
      if (customerDetails !== undefined) {
        if (customerDetails.loans_by_pk.receipts) {
          let release_letter_valid_url = null;
          let signed_pledge_card_valid_url = null;

          if (customerDetails.loans_by_pk.receipts.release_letter) {
            if (
              customerDetails.loans_by_pk.receipts.release_letter.length > 0
            ) {
              release_letter_valid_url = await get_image_url({
                url: customerDetails.loans_by_pk.receipts.release_letter,
              });
            }
          }

          if (customerDetails.loans_by_pk.receipts.signed_pledge_card) {
            if (
              customerDetails.loans_by_pk.receipts.signed_pledge_card.length > 0
            ) {
              signed_pledge_card_valid_url = await get_image_url({
                url: customerDetails.loans_by_pk.receipts.signed_pledge_card,
              });
            }
          }

          setReleaseLetter(release_letter_valid_url);
          setPledgeCard(signed_pledge_card_valid_url);
        }
      }
    }

    setReceipts();
  }, [customerDetails]);

  if (customerDetailsLoading) return null;

  // if (customerDetailsQueryError) console.log(customerDetailsQueryError);

  return (
    <div className="text-center space-y-4">
      <h4>CUSTOMER DETAILS</h4>

      <div className="flex space-x-4 items-center justify-center">
        {/* ROW 1  */}

        {/* {console.log(
          "test",
          customerDetails.loans_by_pk,
          customerDetails.loans_by_pk.account_number
        )} */}
        {customerDetails.loans_by_pk.customer.customer_id &&
        customerDetails.loans_by_pk.account_number ? (
          <div className="border bg-white p-2 flex space-x-4 w-1/3">
            <div className="w-1/2">
              <h5 className="text-xs">CUSTOMER ID</h5>
              <p className="text-base font-bold text-black">
                {customerDetails?.loans_by_pk?.customer?.customer_id}
              </p>
            </div>
            <div className="w-1/2">
              <h5 className="text-xs">LOAN ACCOUNT NUMBER</h5>
              <p className="text-base font-bold text-black">
                {customerDetails?.loans_by_pk?.account_number}
              </p>
            </div>
          </div>
        ) : null}

        {releaseLetter ? (
          <a
            href={releaseLetter}
            alt="Release Letter"
            target="blank"
            className="underline hover:underline"
          >
            Release Letter
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerDetails;
