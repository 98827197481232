import AuthUtils from "./AuthUtils";
import importPublicKeyAndEncrypt from "./encryption";

const login = async (email, password) => {
	const encryptedPassword = await importPublicKeyAndEncrypt(password);
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/ops/login`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			"client-id": "opsapp",
		},
		body: JSON.stringify({
			"input": {
				"email_id": email,
				"password": encryptedPassword
			}
		}),
	});
	return await response.json();
};

const refetchToken = async () => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/ops/refresh-token`, {
		method: 'POST',
		headers: {
			"refresh-token": `${AuthUtils.getRefreshToken()}`,
			"client-id": "opsapp",
		},
	});
	return await response.json();
};

const logout = async () => {
	let response = await fetch(`${process.env.REACT_APP_AUTH_ENDPOINT}/api/v1/auth/logout`, {
		method: 'POST',
		headers: {
			"Authorization": `JWT ${AuthUtils.getToken()}`,
			"refresh-token": `${AuthUtils.getRefreshToken()}`,
		},
	});
	return await response.json();
};

export { login, refetchToken, logout };
