import { Empty } from "antd";
import React, { Component } from "react";

export class NotFound extends Component {
  render() {
    return (
      <div className="h-screen flex justify-center items-center">
        <Empty description="Page not found"></Empty>
      </div>
    );
  }
}

export default NotFound;
