import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
// import ForgotPassword from "../pages/Auth/forgotPassword";
import Login from "../pages/Auth/login";
// import ResetPassword from "../pages/Auth/resetPassword";
import NotFound from "../pages/notFound";

import history from "../utils/history";

function UnauthenticatedRoutes(props) {
  //   console.log("UnauthenticatedRoutes props", props);
  return (
    <div style={{ margin: "0 auto" }}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            exact
            render={() => {
              return (
                <Login {...props} setAuthenticated={props.setAuthenticated} />
              );
            }}
          />
          {/* <Route path="/forgotpassword" exact component={ForgotPassword} /> */}
          {/* <Route path="/resetpassword" exact component={ResetPassword} /> */}

          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </div>
  );
}

export default UnauthenticatedRoutes;
