const rules = {
  super_admin: {
    static: [
      "loans:new",
      "loans:cst_approved",
      "loans:gold_stored",
      "loans:gold_released",
      "loans:aborted",
      "loans:all",
      // "loans:view",
      // "loans:approve",
      // "partner:create",
      // "partner:list",
      // "partner:view",
      // "partner:edit",
      // "partner:deactivate",
      // "partner_branch:create",
      // "partner_branch:view",
      // "partner_branch:edit",
      // "partner_branch:deactivate",
      // "plan:create",
      // "plan:list",
      // "plan:view",
      // "plan:deactivate",
      // "rate:create",
      // "rate:list",
      // "rate:view",
      // "rate:deactivate",
      // "visit:create",
      // "visit:list",
      // "visit:view",
      // "visit:assign",
      // "visit:cancel",
      "cst_navbar"
    ],
    dynamic: {}
  },
  cst: {
    static: ["cst_navbar", "cst_loan_approved_stepper", "cst"]
  },
  blc: {
    static: ["blc_navbar", "blc_loan_approved_stepper", "blc", "release_gold"]
  },
  blv: {
    static: ["blv_navbar", "blv_loan_approved_stepper", "blv"]
  },
  maker: {
    static: ["maker_navbar"]
  },
  checker: {
    static: ["checker_navbar"]
  },
  partner_branch: {
    static: ["partner_branch_navbar"],
    dynamic: {
      "loans:list": ({ partner_branch_id, loan_partner_branch_id }) => {
        if (!partner_branch_id || !loan_partner_branch_id) return false;
        return partner_branch_id === loan_partner_branch_id;
      },
      "loans:view": ({ partner_branch_id, loan_partner_branch_id }) => {
        if (!partner_branch_id || !loan_partner_branch_id) return false;
        return partner_branch_id === loan_partner_branch_id;
      },
      "loans:approve": ({ partner_branch_id, loan_partner_branch_id }) => {
        if (!partner_branch_id || !loan_partner_branch_id) return false;
        return partner_branch_id === loan_partner_branch_id;
      }
    }
  }
};

export default rules;
