import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import React from "react";
import moment from "moment";

const Currency = props =>
  props.number.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

const Number = props =>
  props.number.toLocaleString("en-IN", {
    maximumFractionDigits: 2
  });

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column"
    // backgroundColor: "#E4E4E4"
  },
  image: {
    // height: 200,
    // width: 150
    marginTop: 10,
    marginBottom: 10
  },
  section: {
    margin: 10,
    padding: 10
    // flexGrow: 1,
    // borderBottom: "1pt solid black"
    // backgroundColor: "#4f4f4f"
  },
  text: {
    fontSize: 14,
    lineHeight: 2
    // borderBottom: "1px solid #000"
  }
});

const DownloadLoan = props => {
  // if (props) console.log("data", props);

  // { props.data.number_of_items === 0 ? null : }

  if (props.data.number_of_items === 0) {
    return <Document> </Document>;
  } else {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={[
              styles.section,
              {
                textAlign: "center",
                borderBottom: "1pt solid black"
              }
            ]}
          >
            <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
              Loan ID: {props.data.loan_id}
            </Text>
          </View>

          <View style={[styles.section, { borderBottom: "1pt solid black" }]}>
            <Text style={styles.text}>
              Loan type: {props.data.loan_details.loan_type}
            </Text>
            <Text style={styles.text}>
              Loan amount: Rs.{" "}
              <Currency number={props.data.loan_details.loan_amount} />
            </Text>
            <Text style={styles.text}>
              Plan name: {props.data.plan_details.scheme_name}
            </Text>
            <Text style={styles.text}>
              Tenure: {props.data.plan_details.tenure} months
            </Text>
            <Text style={styles.text}>
              Min - Max amount: Rs.{" "}
              <Currency number={props.data.plan_details.minimum_amount} /> - Rs.{" "}
              <Currency number={props.data.plan_details.maximum_amount} />
            </Text>
            <Text style={styles.text}>
              interest_slab_1: {props.data.plan_details.interest_slab_1}
            </Text>
          </View>

          <View
            style={[
              styles.section,
              {
                textAlign: "center",
                borderBottom: "1pt solid black"
              }
            ]}
          >
            <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
              Gold details
            </Text>
          </View>

          <View style={[styles.section, { borderBottom: "1pt solid black" }]}>
            <Text style={styles.text}>
              Number of items: {props.data.number_of_items} nos.
            </Text>
            <Text style={styles.text}>
              Total gold weight: {props.data.total_weight} grams
            </Text>
          </View>

          <View style={styles.section}>
            {props.data.golds.map((goldItem, index) => {
              if (index !== 0) {
                return (
                  <View break style={{ paddingTop: 20 }}>
                    {/* <View style={{ flexDirection: "row" }}> */}
                    <View>
                      <Text style={styles.text}>
                        Gold ID: {goldItem.gold_display_id}
                      </Text>
                      <Text style={styles.text}>
                        Gold Type: {goldItem.type}
                      </Text>
                      <Text style={styles.text}>
                        Quantity: {goldItem.quantity} nos.
                      </Text>
                      <Text style={styles.text}>
                        Quality: {goldItem.quality}
                      </Text>
                      <Text style={styles.text}>
                        Gross weight: <Number number={goldItem.gross_weight} />{" "}
                        grams
                      </Text>
                      <Text style={styles.text}>
                        Net weight: <Number number={goldItem.net_weight} />{" "}
                        grams
                      </Text>
                      <Text style={styles.text}>
                        Stone deduction:{" "}
                        <Number number={goldItem.stone_deduction} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Seal ID: {goldItem.pickup_seal_id}
                      </Text>
                      <Text style={styles.text}>
                        Rate per gm: Rs. <Currency number={props.data.rate} />
                      </Text>
                      <Text style={styles.text}>
                        Total amount: Rs.{" "}
                        <Currency
                          number={props.data.rate * goldItem.gross_weight}
                        />
                      </Text>
                      {/* <Text style={styles.text}>Images:</Text> */}
                    </View>
                    <View>
                      {goldItem.pickup_gold_images.map(image => {
                        return <Image src={image} style={styles.image} />;
                      })}
                    </View>
                  </View>
                );
              } else {
                return (
                  <View>
                    {/* <View style={{ flexDirection: "row" }}> */}
                    <View>
                      <Text style={styles.text}>
                        Gold ID: {goldItem.gold_display_id}
                      </Text>
                      <Text style={styles.text}>
                        Gold Type: {goldItem.type}
                      </Text>
                      <Text style={styles.text}>
                        Quantity: {goldItem.quantity} nos.
                      </Text>
                      <Text style={styles.text}>
                        Quality: {goldItem.quality}
                      </Text>
                      <Text style={styles.text}>
                        Gross weight: <Number number={goldItem.gross_weight} />{" "}
                        grams
                      </Text>
                      <Text style={styles.text}>
                        Net weight: <Number number={goldItem.net_weight} />{" "}
                        grams
                      </Text>
                      <Text style={styles.text}>
                        Stone deduction:{" "}
                        <Number number={goldItem.stone_deduction} /> grams
                      </Text>
                      <Text style={styles.text}>
                        Seal ID: {goldItem.pickup_seal_id}
                      </Text>
                      <Text style={styles.text}>
                        Rate per gm: Rs. <Currency number={props.data.rate} />
                      </Text>
                      <Text style={styles.text}>
                        Total amount: Rs.{" "}
                        <Currency
                          number={props.data.rate * goldItem.gross_weight}
                        />
                      </Text>
                      {/* <Text style={styles.text}>Images:</Text> */}
                    </View>
                    <View>
                      {goldItem.pickup_gold_images.map(image => {
                        return <Image src={image} style={styles.image} />;
                      })}
                    </View>
                  </View>
                );
              }
            })}
          </View>

          <View
            style={[
              styles.section,
              {
                textAlign: "center",
                borderTop: "1pt solid black",
                borderBottom: "1pt solid black"
              }
            ]}
            break
          >
            <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
              Customer KYC details
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
              {props.data.kyc.legal_name}
            </Text>
            <Text style={styles.text}>
              Mother's name: {props.data.kyc.mothers_name}
            </Text>
            <Text style={styles.text}>
              Father's name: {props.data.kyc.fathers_name}
            </Text>
            <Text style={styles.text}>
              Date of birth:{" "}
              {moment(props.data.kyc.date_of_birth).format("DD.MM.YYYY")}
            </Text>
            <Text style={styles.text}>
              Gender:{" "}
              {props.data.kyc.gender === "MALE"
                ? "Male"
                : props.data.kyc.gender === "FEMALE"
                ? "Female"
                : props.data.kyc.gender === "TRANSGENDER"
                ? "Transgender"
                : "N/A"}
            </Text>
            <Text style={styles.text}>
              Marital status:{" "}
              {props.data.kyc.marital_status === "MARRIED"
                ? "Married"
                : props.data.kyc.marital_status === "SINGLE"
                ? "Single"
                : "N/A"}
            </Text>
          </View>

          <View style={styles.section} break>
            <Text style={styles.text}>Address proof: </Text>
            {props.data.kyc.address_proof.map(proof => {
              return (
                <View break>
                  <Text style={styles.text}>{proof.type}</Text>
                  <Image source={proof.image_url} />
                </View>
              );
            })}
          </View>

          <View style={styles.section} break>
            <Text style={styles.text}>ID proof: </Text>
            {props.data.kyc.id_proof.map((proof, index) => {
              return (
                <View break={index}>
                  <Text style={styles.text}>{proof.type}</Text>
                  <Image source={proof.image_url} />
                </View>
              );
            })}
          </View>

          <View style={styles.section} break>
            <Text style={styles.text}>PAN number: {props.data.pan_number}</Text>
            <Text style={styles.text}>PAN proof: </Text>
            {props.data.kyc.pan_proof.map(proof => {
              return (
                <View break>
                  <Text style={styles.text}>{proof.type}</Text>
                  <Image source={proof.image_url} />
                </View>
              );
            })}
          </View>

          <View style={styles.section} break>
            <Text style={styles.text}>Recent photo: </Text>
            {/* {console.log("recetn_pic", props.data.live_photo)} */}
            <Image source={props.data.kyc.live_photo} />
          </View>

          <View
            style={[
              styles.section,
              {
                textAlign: "center",
                borderBottom: "1pt solid black"
              }
            ]}
            break
          >
            <Text style={[styles.text, { fontSize: 18, fontWeight: "bold" }]}>
              Customer bank account details
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.text}>
              Bank account name: {props.data.bank_account_details.account_name}
            </Text>
            <Text style={styles.text}>
              Account number: {props.data.bank_account_details.account_number}
            </Text>
            <Text style={styles.text}>
              Bank branch: {props.data.bank_account_details.bank_branch}
            </Text>
            <Text style={styles.text}>
              Bank IFSC: {props.data.bank_account_details.ifsc}
            </Text>
          </View>

          <View style={styles.section} break>
            <Text style={styles.text}>Bank proof: </Text>
            {props.data.bank_account_details.bank_account_proof.map(
              (proof, index) => {
                return (
                  <View break={index}>
                    <Text style={styles.text}>{proof.type}</Text>
                    <Image source={proof.image_url} />
                  </View>
                );
              }
            )}
          </View>
        </Page>
      </Document>
    );
  }
};

export default DownloadLoan;
