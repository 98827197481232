import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import DownloaduserBankAccountDetails from "../components/export/bankAccountDetails";
import Carousel, { Modal, ModalGateway } from "react-images";
import Utils, { get_image_url } from "../utils";

const GET_USER_BANK_ACCOUNT_DETAILS = gql`
  query getUserBankAccountDetails($id: Int!) {
    loans_by_pk(id: $id) {
      id
      loan_number
      customer {
        id
        bank_account_details {
          id
          bank_account_name
          bank_account_number
          bank_name
          ifsc
          bank_branch
          bank_account_proof
        }
      }
    }
  }
`;

const UserBankAccountDetails = (props) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [images, setImages] = useState([]);

  const [pdfUrl, setPdfUrl] = useState(null);

  const {
    loading: userBankAccountDetailsLoading,
    data: userBankAccountDetails,
    error: userBankAccountDetailsError,
  } = useQuery(GET_USER_BANK_ACCOUNT_DETAILS, {
    variables: {
      id: props.loan_id,
    },
  });

  useEffect(() => {
    async function getUrl() {
      if (userBankAccountDetails !== undefined) {
        let images = await Promise.all(
          Utils.arrayCheck(userBankAccountDetails?.loans_by_pk?.customer?.bank_account_details[0]?.bank_account_proof)?.map(
            async (proof) => {
              const valid_url = await get_image_url({
                url: [proof?.image_url],
              });

              return {
                type: proof.type,
                image_url: valid_url,
              };
            }
          )
        );

        setPdfUrl(images);
      }
    }

    getUrl();
  }, [userBankAccountDetails]);

  if (userBankAccountDetailsLoading) return null;

  if (userBankAccountDetailsError) console.log(userBankAccountDetailsError);

  // console.log("bank account details", userBankAccountDetails);

  return (
    <div className="space-y-2">
      <h4>USER'S BANK ACCOUNT DETAILS</h4>
      <div className="space-y-4">
        <div className="w-full flex justify-between items-center space-x-4">
          <div
            className={
              props.withoutExport && props.withoutProof
                ? "space-y-2 w-full"
                : "space-y-2 w-2/3"
            }
          >
            <div className="border bg-white flex p-2 w-full">
              <div className="m-3 w-1/4 break-all border-r text-left">
                <h5 className="text-xs">ACCOUNT NAME</h5>
                <p className="text-base font-bold text-black">
                  {
                    userBankAccountDetails?.loans_by_pk?.customer
                      ?.bank_account_details[0]?.bank_account_name
                  }
                </p>
              </div>

              <div className="m-3 w-1/4 break-all border-r text-left">
                <h5 className="text-xs">ACCOUNT NO.</h5>
                <p className="text-base font-bold text-black">
                  {
                    userBankAccountDetails?.loans_by_pk?.customer
                      ?.bank_account_details[0]?.bank_account_number
                  }
                </p>
              </div>

              <div className="m-3 w-1/4 break-all border-r text-left">
                <h5 className="text-xs">BANK NAME</h5>
                <p className="text-base font-bold text-black">
                  {
                    userBankAccountDetails?.loans_by_pk?.customer
                      ?.bank_account_details[0]?.bank_name
                  }
                </p>
              </div>

              <div className="m-3 w-1/4 break-all  text-left">
                <h5 className="text-xs">BANK IFSC</h5>
                <p className="text-base font-bold text-black">
                  {
                    userBankAccountDetails?.loans_by_pk?.customer
                      ?.bank_account_details[0]?.ifsc
                  }
                </p>
              </div>
            </div>
          </div>

          {!props.withoutProof ? (
            <button
              type="submit"
              className="text-xs px-2 btn-white"
              style={{ minWidth: "170px" }}
              onClick={async () => {
                let images = await Promise.all(
                  userBankAccountDetails.loans_by_pk.customer.bank_account_details[0].bank_account_proof.map(
                    async (proof) => {
                      const valid_url = await get_image_url({
                        url: [proof.image_url],
                      });

                      return {
                        caption: proof.type,
                        source: valid_url,
                      };
                    }
                  )
                );
                setImages(images);
                setIsImageModalOpen(true);
              }}
            >
              View Bank Proof
            </button>
          ) : null}

          {/* Pdf is dynamically generated when the user clicks on the button */}
          {!props.withoutExport && pdfUrl ? (
            <button
              className="text-xs px-3 cta py-2"
              onClick={async () => {
                // const props = await getProps();
                const doc = (
                  <DownloaduserBankAccountDetails
                    data={{
                      account_name:
                        userBankAccountDetails.loans_by_pk.customer
                          .bank_account_details[0].bank_account_name,
                      account_number:
                        userBankAccountDetails.loans_by_pk.customer
                          .bank_account_details[0].bank_account_number,
                      bank_name:
                        userBankAccountDetails.loans_by_pk.customer
                          .bank_account_details[0].bank_name,
                      bank_brank:
                        userBankAccountDetails.loans_by_pk.customer
                          .bank_account_details[0].bank_branch,
                      ifsc:
                        userBankAccountDetails.loans_by_pk.customer
                          .bank_account_details[0].ifsc,
                      bank_account_proof: pdfUrl,
                    }}
                  />
                );
                const asPdf = pdf([]); // {} is important, throws without an argument
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                saveAs(
                  blob,
                  `User bank account details for loan ID: ${userBankAccountDetails.loans_by_pk.loan_number}`
                );
              }}
            >
              <p className="text-xs m-0">
                Export bank account details
              </p>
            </button>
          ) : null}

          {/* OLD CODE - PDF generation happens when page loads. This was scrapped since this led to too much load on the browser */}
          {/* {!props.withoutExport && pdfUrl ? (
            <PDFDownloadLink
              document={
                <DownloaduserBankAccountDetails
                  data={{
                    account_name:
                      userBankAccountDetails.loans_by_pk.customer
                        .bank_account_details[0].bank_account_name,
                    account_number:
                      userBankAccountDetails.loans_by_pk.customer
                        .bank_account_details[0].bank_account_number,
                    bank_name:
                      userBankAccountDetails.loans_by_pk.customer
                        .bank_account_details[0].bank_name,
                    bank_brank:
                      userBankAccountDetails.loans_by_pk.customer
                        .bank_account_details[0].bank_branch,
                    ifsc:
                      userBankAccountDetails.loans_by_pk.customer
                        .bank_account_details[0].ifsc,
                    bank_account_proof: pdfUrl
                  }}
                />
              }
              fileName={`User bank account details for loan ID: ${userBankAccountDetails.loans_by_pk.loan_number}`}
              style={{
                textDecoration: "none",
                color: "black",
                backgroundColor: "#f7c366",
                padding: "0.5rem 1rem 0.5rem 1rem",
                height: "fit-content",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
                minWidth: "170px"
                // border: "1px solid #f7c366"
              }}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Export bank account details"
              }
            </PDFDownloadLink>
          ) : null} */}
        </div>
      </div>

      <ModalGateway>
        {isImageModalOpen && images.length > 0 ? (
          <Modal
            onClose={() => {
              setIsImageModalOpen(!isImageModalOpen);
            }}
          >
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default UserBankAccountDetails;
