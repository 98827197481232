import React from "react";
import { Modal as AntModal, notification } from "antd";
import { logout } from "../../utils/userSessions";
import Warning from "../../assets/warning.png";
import AuthUtils from "../../utils/AuthUtils";

const LogoutModal = props => {
  return (
    <AntModal
      width="560px"
      className="modal-title-center"
      visible={props.isOpen}
      footer={null}
    >
      <div className="space-y-8 mx-auto text-center">
        <h1>Logout</h1>
        <p>Are you sure you want to log out?</p>
        <div>
          <img
            src={Warning}
            alt="warning"
            style={{ height: "8rem", width: "0 auto", margin: "0 auto" }}
          />
        </div>
        <div className="flex space-x-4 justify-center">
          <button
            className="cta"
            onClick={() => {
              logout().then((res) => {
                if (res.code === "200") {
                  AuthUtils.logout()
                } else {
                  AuthUtils.logout()
                  notification.error({
                    message: "Something went wrong",
                    description: res.message,
                  });
                }
              })
            }}
          >
            Logout
          </button>
          <button
            className="btn-danger"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </AntModal>
  );
};

export default LogoutModal;
