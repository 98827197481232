// DEPS
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Spin, Steps, Tag, notification, Empty } from 'antd';
import moment from 'moment';

//ASSETS
import GreenCheck from '../../../assets/green-check.png';
import Loader from '../../../assets/loader.png';

// COMPONENTS
import ConfirmCallbackModal from '../../../components/Modals/confirmCallback';
import CreateLoanAccountNumberModal from '../../../components/Modals/createLoanAccountNumber';
import ErrorPage from '../../../components/ErrorPage';
import Sidebar from '../../../components/Sidebar';
import StepsContainer from '../../../components/Steps';
import ImageViewer from '../../../components/ImageViewer';

// VIEWS
import GoldAppraisalDetails from '../../../views/goldAppraisalDetails';
import AgentDetailsView from '../../../views/agentDetails';
import Timestamps from '../../../views/timestamps';
import UserKYCDetails from '../../../views/userKYCDetails';
import UserBankAccountDetails from '../../../views/userBankAccountDetails';
import LoanDetails from '../../../views/loanDetails';
import PlanDetails from '../../../views/planDetails';
import CustomerDetails from '../../../views/customerDetails';
import ViewPdf from '../../../components/ViewPdf';

// APIS
import { GET_LOAN_BY_ID, BLV_ABORT_LOAN, BLV_CONFIRM_LOAN } from '../graphql';
import { get_image_url } from '../../../utils';

//CSS
import './all.css';
import RejectLoanModal from "../../../components/Modals/rejectLoan";
import Carousel, { Modal, ModalGateway } from "react-images";
import OtpLogs from "../../../views/otpLogDetails";

const { Step } = Steps;

const BLVAllLoans = (props) => {
  const [trigger, setTrigger] = useState(false);
  const [selectedLoan, setSelectedLoan] = useState(0);
  const [isLoanAccountCreationModalOpen, setLoanAccountCreationModalOpen] =
    useState(false);
  const [loanAccountNumberSet, setLoanAccountNumberSet] = useState(false);
  const [isRejectLoanModalOpen, setRejectLoanModalOpen] = useState(false);

  const [signedreceiptImages, setSignedReceiptsImages] = useState(null);
  const [isPledgeCardImageModalOpen, setIsPledgeCardImageModalOpen] =
    useState(false);
  const [releasereceiptImages, setReleaseReceiptsImages] = useState(null);
  const [isReleaseLetterImageModalOpen, setIsReleaseLetterImageModalOpen] =
    useState(false);
  const [toggleViewPdfModal, setToggleViewPdfModal] = useState({
    toggle: false,
    url: '',
    title: '',
  });  

  const [
    getOldLoanById,
    {
      loading: loanOldDataLoading,
      data: loanOldData,
      error: loanOldQueryError,
    },
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [
    getLoanById,
    { loading: loanDataLoading, data: loanData, error: loanQueryError },
  ] = useLazyQuery(GET_LOAN_BY_ID, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data && data.loans_by_pk && data.loans_by_pk.renew_from_loan) {
        getOldLoanById({ variables: { id: data.loans_by_pk.renew_from_loan } });
      }
    },
  });

  const [BlvAbortLoan] = useMutation(BLV_ABORT_LOAN, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'Loan aborted',
      });
      // getLoanById({
      //   variables: { id: null }
      // });
      setTrigger(true);
      // setGoldAppraisalModalOpen(false);
    },
  });

  const TriggerRejectLoan = (reason) => {
    // console.log(props);
    BlvAbortLoan({
      variables: {
        loan_id: loanData.loans_by_pk.id,
        reason: reason,
        stored_at: moment(),
      },
    });
  };

  const [BlvConfirmLoan] = useMutation(BLV_CONFIRM_LOAN, {
    onError(error) {
      error.graphQLErrors.map((error) => {
        return notification.error({
          message: 'Error!',
          description:
            'Something went wrong. Please check all details and try again',
        });
      });
    },
    onCompleted(data) {
      notification.success({
        message: 'Success!',
        description: 'Loan confirmed',
      });
      // getLoanById({
      //   variables: { id: selectedLoan }
      // });
      // setGoldAppraisalModalOpen(false);
    },
  });

  useEffect(() => {
    console.log('Fetching data again');

    getLoanById({ variables: { id: selectedLoan || 0 } });
  }, [selectedLoan]);

  if (loanQueryError) return <ErrorPage />;

  function getSteps(current) {
    return (
      <StepsContainer
        count={current}
        steps={[
          'REQUEST RECEIVED',
          'GOLD STORED',
          'GOLD RELEASED',
          'LOAN CLOSED',
        ]}
      />
    );
  }

  if (loanQueryError || loanOldQueryError) return <ErrorPage />;

  return (
    <div className='flex flex-row space-x-4'>
      <Sidebar
        props={props}
        index={selectedLoan}
        setSelectedLoan={setSelectedLoan}
        trigger={trigger}
        setTrigger={setTrigger}
        loan_statuses={[
          'BLV_CONFIRMED',
          'BLC_VERIFIED',
          'GOLD_RELEASED',
          'CLOSED',
          'GOLD_STORED',
          'BLV_ABORTED',
          'RENEWED',
        ]}
      />
      {/* Sidebar */}

      <div className='w-full py-4 all-blv overflow-auto'>
        <div className='flex w-full justify-between space-x-16'>
          <div className='w-6/12 flex space-x-8'>
            {loanData
              ? loanData.loans_by_pk !== null &&
                loanData.loans_by_pk.renew_from_loan === null
                ? loanData.loans_by_pk.loan_status === 'CST_APPROVED'
                  ? getSteps(0)
                  : loanData.loans_by_pk.loan_status === 'GOLD_STORED'
                  ? getSteps(1)
                  : loanData.loans_by_pk.loan_status === 'GOLD_RELEASED'
                  ? getSteps(2)
                  : getSteps(4)
                : null
              : null}
          </div>

          {loanData ? (
            loanData.loans_by_pk !== null &&
            loanData.loans_by_pk.renew_from_loan === null ? (
              <div className='w-1/6 space-y-1 text-right'>
                <h4>LOAN ID: {loanData.loans_by_pk.loan_number}</h4>
                <p className='text-gray-600 font-semibold'>
                  {moment(loanData.loans_by_pk.created_at).format(
                    'DD-MM-YYYY HH:mm'
                  )}
                </p>
                {loanData ? (
                  loanData.loans_by_pk !== null ? (
                    loanData.loans_by_pk.loan_status === 'CST_APPROVED' ||
                    loanData.loans_by_pk.loan_status === 'BLC_VERIFIED' ? (
                      <Tag color={'yellow'} key='TO BE STORED'>
                        TO BE STORED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'GOLD_STORED' ? (
                      <Tag color={'green'} key='GOLD STORED'>
                        STORED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'GOLD_RELEASED' ? (
                      <Tag color={'green'} key='RELEASED' className='mr-0'>
                        GOLD ORNAMENTS RELEASED
                      </Tag>
                    ) : loanData.loans_by_pk.loan_status === 'RENEWED' ? (
                      <Tag color={'green'} key='CLOSED'>
                        RENEWED
                      </Tag>
                    ) : (
                      <Tag color={'red'} key='CLOSED'>
                        LOAN CLOSED
                      </Tag>
                    )
                  ) : null
                ) : null}
              </div>
            ) : null
          ) : null}
        </div>

        {/* {console.log("loanData", loanData)} */}

        {loanDataLoading ? (
          <Spin />
        ) : loanData ? (
          loanData.loans_by_pk !== null ? (
            <div className='space-y-8'>
              <Timestamps loan_id={loanData.loans_by_pk.id} />
              <div className='flex w-full justify-between items-center'>
                <div className='flex space-x-8 items-center'>
                  {loanData.loans_by_pk.loan_status === 'BLC_VERIFIED' ? (
                    loanData.loans_by_pk.renew_from_loan !== null ? (
                      <>
                        <div className='flex justify-between w-full items-center'>
                          <div className='space-x-2 flex items-center'>
                            <img
                              src={
                                loanData.loans_by_pk.loan_status ===
                                'BLC_VERIFIED'
                                  ? Loader
                                  : GreenCheck
                              }
                              alt='check'
                              style={{ height: '1rem', width: '1rem' }}
                            />
                            <p>
                              {loanData.loans_by_pk.loan_status ===
                              'BLC_VERIFIED'
                                ? 'Verifier Confirmation in Progress'
                                : 'Verifier Confirmed'}
                            </p>
                            {loanData.loans_by_pk.loan_status ===
                              'BLC_VERIFIED' && (
                              <>
                                <button
                                  type='submit'
                                  className='btn-primary hover:shadow-lg'
                                  onClick={() => {
                                    setLoanAccountCreationModalOpen(true);
                                  }}
                                >
                                  Confirm Renewal
                                </button>
                                <button
                                  type='submit'
                                  className=' btn-danger hover:shadow-lg'
                                  onClick={() => setRejectLoanModalOpen(true)}
                                >
                                  Abort Renewal
                                </button>
                              </>
                            )}
                          </div>
                          <div className='flex space-x-4'>
                            <button
                              type='submit'
                              className='text-xs px-3 cta py-2'
                              style={{ minWidth: '170px' }}
                              onClick={async () => {
                                let images = await Promise.all(
                                  loanData.loans_by_pk.receipts.signed_pledge_card.map(
                                    async (receiptURL) => {
                                      const valid_url = await get_image_url({
                                        url: [receiptURL],
                                      });

                                      return {
                                        caption: 'Signed Pledge Card',
                                        source: valid_url,
                                      };
                                    }
                                  )
                                );
                                setSignedReceiptsImages(images);
                                setIsPledgeCardImageModalOpen(true);
                              }}
                            >
                              View User Signed Pledge Receipt
                            </button>
                            {/* {loanOldData && loanOldData.loans_by_pk && (
                        <button
                          type="submit"
                          className="text-xs px-3 cta py-2"
                          style={{ minWidth: "170px" }}
                          onClick={async () => {
                            if(loanOldData && loanOldData.loans_by_pk) {
                              let images = await Promise.all(
                                loanOldData.loans_by_pk.receipts.release_letter.map(async (receiptURL) => {
                                  const valid_url = await get_image_url({
                                    url: [receiptURL],
                                  });
    
                                  return {
                                    caption: 'Release Letter',
                                    source: valid_url,
                                  };
                                }
                                )
                              );
                              setReleaseReceiptsImages(images);
                              setIsReleaseLetterImageModalOpen(true);
                            }
                          }}
                        >
                          View Old Loan Release Receipt
                        </button>
                      )} */}
                          </div>
                          {/* <ConfirmCallbackModal /> */}
                        </div>
                      </>
                    ) : (
                      <>
                        {loanData.loans_by_pk.account_number ||
                        loanAccountNumberSet ? null : (
                          <button
                            type='submit'
                            className='btn-primary hover:shadow-lg'
                            // onClick={() => setValidateOTPModalOpen(true)}
                            // onClick={() => setGoldStoreModalOpen(true)}
                            // onClick={() => setGoldAppraisalModalOpen(true)}
                            onClick={() => {
                              setLoanAccountCreationModalOpen(true);
                            }}
                          >
                            Confirm Loan
                          </button>
                        )}
                        {loanData.loans_by_pk.renew_from_loan !== null && (
                          <button
                            type='submit'
                            className=' btn-danger hover:shadow-lg'
                            onClick={() => setRejectLoanModalOpen(true)}
                          >
                            Abort Renewal
                          </button>
                        )}
                      </>
                    )
                  ) : null}

                  {loanData.loans_by_pk.loan_status !== 'CLOSED' ? (
                    <ConfirmCallbackModal />
                  ) : null}

                </div>
                {loanData?.loans_by_pk?.receipts != null ? (
                  <div className="flex">
                    {loanData?.loans_by_pk?.receipts?.signed_pledge_card?.length > 0 && (
                      <button
                      type="submit"
                      className="text-xs px-3 cta py-2"
                      style={{ minWidth: "220px" }}
                      onClick={() => {
                        setToggleViewPdfModal((prev) => ({
                          ...prev,
                          toggle: true,
                          title: 'Signed Pledge Card',
                          url:
                          loanData.loans_by_pk?.receipts?.signed_pledge_card?.[0]
                        }));
                      }}  
                    >
                      View Signed Pledge Card
                    </button>
                    )}
                    {loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement && (
                      <button
                        type="submit"
                        className="text-xs cta py-2 ml-4"
                        style={{ minWidth: "160px" }}
                        onClick={() => {
                          setToggleViewPdfModal((prev) => ({
                            ...prev,
                            toggle: true,
                            title: 'Signed KFS',
                            url:
                            loanData?.loans_by_pk?.receipts?.signed_gl_fact_statement?.[0]
                          }));
                        }}   
                      >
                        View Signed KFS
                      </button>
                    )}
                  </div>
                ) : null}

                {/* <DownloadLoanDetailsButton loan_id={loanData.loans_by_pk.id} /> */}
              </div>

              {/* Renewal Conditions */}
              {loanOldDataLoading ? (
                <Spin />
              ) : (
                loanData &&
                loanOldData &&
                loanData.loans_by_pk &&
                loanData.loans_by_pk.renew_from_loan !== null &&
                loanOldData.loans_by_pk && (
                  <div className='w-full flex space-x-8'>
                    {/* Loan request */}
                    <LoanDetails
                      title='OLD LOAN DETAILS'
                      loan_number={loanOldData.loans_by_pk.loan_number}
                      loan_type={loanOldData.loans_by_pk.loan_type.loan_type}
                      loan_amount={
                        loanOldData.loans_by_pk.od_amount
                          ? loanOldData.loans_by_pk.od_amount.credit_limit
                          : loanOldData.loans_by_pk.gl_amount
                          ? loanOldData.loans_by_pk.gl_amount.loan_amount
                          : null
                      }
                      gold_gross_weight={parseFloat(
                        loanOldData.loans_by_pk.golds.reduce(
                          (acc, goldItem) => {
                            return acc + goldItem.gross_weight;
                          },
                          0
                        )
                      ).toFixed(2)}
                      gold_net_weight={parseFloat(
                        loanOldData.loans_by_pk.golds.reduce(
                          (acc, goldItem) => {
                            return acc + goldItem.net_weight;
                          },
                          0
                        )
                      ).toFixed(2)}
                      gold_actual_net_weight={parseFloat(
                        loanOldData.loans_by_pk.golds.reduce(
                          (acc, goldItem) => {
                            return acc + goldItem.actual_net_weight;
                          },
                          0
                        )
                      ).toFixed(2)}
                    />
                    {/* Loan request */}

                    {/*Plan details */}
                    <PlanDetails
                      title='OLD PLAN DETAILS'
                      scheme_name={loanOldData.loans_by_pk.plan.scheme_name}
                      min_amt={loanOldData.loans_by_pk.plan.minimum_amount}
                      max_amt={loanOldData.loans_by_pk.plan.maximum_amount}
                      tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                        (total, obj) => obj.intervals + total,
                        0
                      )} months`}
                      interest={`   ${Math.max.apply(
                        Math,
                        loanData.loans_by_pk.plan.slab_details.map(function (
                          o
                        ) {
                          return o.lender_interest_rate;
                        })
                      )} %`}
                      interest_slab_2={`${loanOldData.loans_by_pk.plan.interest_slab_2} %`}
                      interest_slab_3={`${loanOldData.loans_by_pk.plan.interest_slab_3} %`}
                      loan_type={loanOldData.loans_by_pk.loan_type.loan_type}
                    />
                    {/* Plan details */}
                  </div>
                )
              )}
              <div className='w-full flex space-x-8'>
                {/* Loan request */}
                <LoanDetails
                  loan_number={loanData.loans_by_pk.loan_number}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                  loan_amount={
                    loanData.loans_by_pk.od_amount
                      ? loanData.loans_by_pk.od_amount.credit_limit
                      : loanData.loans_by_pk.gl_amount
                      ? loanData.loans_by_pk.gl_amount.loan_amount
                      : null
                  }
                  gold_gross_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.gross_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.net_weight;
                    }, 0)
                  ).toFixed(2)}
                  gold_actual_net_weight={parseFloat(
                    loanData.loans_by_pk.golds.reduce((acc, goldItem) => {
                      return acc + goldItem.actual_net_weight;
                    }, 0)
                  ).toFixed(2)}
                />
                {/* Loan request */}

                {/*Plan details */}
                <PlanDetails
                  scheme_name={loanData.loans_by_pk.plan.scheme_name}
                  min_amt={loanData.loans_by_pk.plan.minimum_amount}
                  max_amt={loanData.loans_by_pk.plan.maximum_amount}
                  tenure={`${loanData.loans_by_pk.plan.slab_details.reduce(
                    (total, obj) => obj.intervals + total,
                    0
                  )} months`}
                  interest={`   ${Math.max.apply(
                    Math,
                    loanData.loans_by_pk.plan.slab_details.map(function (o) {
                      return o.lender_interest_rate;
                    })
                  )} %`}
                  interest_slab_2={`${loanData.loans_by_pk.plan.interest_slab_2} %`}
                  interest_slab_3={`${loanData.loans_by_pk.plan.interest_slab_3} %`}
                  loan_type={loanData.loans_by_pk.loan_type.loan_type}
                />
                {/* Plan details */}
              </div>

              <CustomerDetails loan_id={loanData.loans_by_pk.id} />

              {loanData.loans_by_pk.renew_from_loan === null && <AgentDetailsView loan_id={loanData.loans_by_pk.id} /> }

              {/* Gold details */}
              <GoldAppraisalDetails id={loanData.loans_by_pk.id} />
              {/* Gold details */}

              {/* User Info & KYC */}
              <UserKYCDetails loan_id={loanData.loans_by_pk.id} />
              {/* User Info & KYC */}

              <UserBankAccountDetails loan_id={loanData.loans_by_pk.id} />

              {/* OTP Logs */}
                 <OtpLogs loan_number={loanData?.loans_by_pk?.loan_renew_from?.loan_number}></OtpLogs>
              {/* OTP Logs */}

              <CreateLoanAccountNumberModal
                title='Confirm Loan Renewal'
                loan_id={loanData.loans_by_pk.id}
                renewal_id={loanData?.loans_by_pk?.renew_from_loan}
                isOpen={isLoanAccountCreationModalOpen}
                setOpen={setLoanAccountCreationModalOpen}
                setLoanAccountNumberSet={setLoanAccountNumberSet}
                setSelectedLoan={setSelectedLoan}
                setTrigger={setTrigger}
                customer_id={loanData.loans_by_pk.customer.id}
              />
            </div>
          ) : (
            <Empty description='No loan selected' className='p-16' />
          )
        ) : null}
      </div>
      {/* USE THIS MODAL TO VIEW PDF  */}
			{toggleViewPdfModal.toggle && (
				<ViewPdf
					url={toggleViewPdfModal.url}
					visible={toggleViewPdfModal.toggle}
					modalHeading={toggleViewPdfModal.title}
					onClose={() =>
						setToggleViewPdfModal((prev) => ({
							...prev,
							title: '',
							toggle: false,
							url: '',
						}))
					}
				/>
			)}
      {loanData ? (
        <RejectLoanModal
          isOpen={isRejectLoanModalOpen}
          setOpen={setRejectLoanModalOpen}
          rejectLoan={TriggerRejectLoan}
        />
      ) : null}
      {isPledgeCardImageModalOpen && signedreceiptImages?.length > 0 ? (
          <ImageViewer
            images={signedreceiptImages}
            onClose={() => {
              setIsPledgeCardImageModalOpen(false);
            }}
           />
        ) : null}
      <ModalGateway>
        {isReleaseLetterImageModalOpen && releasereceiptImages.length > 0 ? (
          <Modal
            onClose={() => {
              setIsReleaseLetterImageModalOpen(!isReleaseLetterImageModalOpen);
            }}
          >
            <Carousel views={releasereceiptImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default BLVAllLoans;
