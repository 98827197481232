// DEPS
import React, { useState } from "react";
import * as Yup from "yup";
// import ReCAPTCHA from "react-google-recaptcha";

// COMPONENTS
import { Button, notification } from "antd";
import { ReactForm, ReactInput } from "../../components/Form";

import Layout from "./layout";
import "./style.css";
import {login} from "../../utils/userSessions";
import AuthUtils from "../../utils/AuthUtils";
import AuthApi from "../../utils/ApiUtils/AuthApi";
import { addCookie } from "../../utils/cookies";
import { addToLocalStorage } from "../../utils/local-storage";

const Login = props => {
  const [loading, setLoading] = useState(false);
  const [captchaSuccess, setCaptchSuccess] = useState(true);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Email ID required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password required"),
    remember: Yup.boolean()
  });

  const handleSubmit = data => {
    if (!captchaSuccess) {
      alert("Captcha failed. Try again!")
      return;
    }
    setLoading(true);
    login(data.email, data.password).then(res => {
      console.log(res);
      if (res.status === 200) {
        AuthUtils.storeAuthTokens({ token: res.user.token, refreshToken: res.user.refreshToken })
        props.setAuthenticated(true);

        AuthApi.verifyToken(res.user.token).then((data) => {
          addToLocalStorage("user", data?.user)
          addCookie('user', JSON.stringify(data?.user))
          setTimeout(() => {
            window.location.reload("/")
          }, 1000)
        }).catch(() => {
          setLoading(false);
        });
        // setLoading(false);
      } else {
        notification.error({
          message: "Something went wrong",
          description: res.message,
        });
        setLoading(false);
      }
    });
  };

  const onChange = (value) => {
    setCaptchSuccess(true);
  }

  return (
    <div>
      <Layout loading={loading}>
        <div className="w-1/2 mx-auto bg-white rounded-lg text-left loginContainer">
          <ReactForm
            onSubmit={handleSubmit}
            initialValues={{
              email: "",
              password: "",
              remember: false
            }}
            validationSchema={validationSchema}
            formCls="flex flex-col content-center justify-center space-y-12"
          >
            <div>
              <ReactInput
                name="email"
                // label="Email"
                type="text"
                showError={false}
                placeholder="Email address"
              />
              <ReactInput
                ispassword
                // label="Password"
                name="password"
                type="password"
                placeholder="Password"
                showError={false}
              />
              {/*<ReactCheckbox*/}
              {/*  name="remember"*/}
              {/*  label="Remember these login credentials"*/}
              {/*/>*/}
            </div>
            {/* <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={onChange}
            /> */}
            <div className="text-center">
              <Button
                htmlType="submit"
                className="w-5/12 rounded-lg cta"
                // type="primary"
                size={"large"}
              >
                Login
              </Button>
            </div>
            {/* <Button
              type="link"
              className="mt-2 text-center "
              onClick={() => {
                props.history.push("/forgotpassword");
              }}
            >
              Forgot Password
            </Button> */}
          </ReactForm>
        </div>
      </Layout>
    </div>
  );
};
export default Login;
