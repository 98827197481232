import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { EmptyPage } from "./ErrorPage";
import { useSubscription } from "@apollo/react-hooks";
import {
  List,
  InfiniteLoader,
  AutoSizer,
  WindowScroller,
} from "react-virtualized";
import "react-virtualized/styles.css"; // only needs to be imported once
import { Input } from "antd";
import gql from "graphql-tag";
import { Currency } from "./Number";

import packageJson from "../../package.json";

const debounce = require("lodash.debounce");
const { Search } = Input;

// $limit: Int!
//     $offset: Int!
// limit: $limit;
// offset: $offset;

const GET_LOANS_FOR_SIDEBAR = gql`
  subscription getLoansForSidebar(
    $loan_number: String
    $loan_statuses: [String!]
  ) {
    loans(
      where: {
        loan_status: { _in: $loan_statuses }
        loan_number: { _ilike: $loan_number }
      }
      order_by: { created_at: desc }
    ) {
      id
      loan_number
      approved_at
      created_at
      loan_type
      od_amount {
        id
        credit_limit
      }
      gl_amount {
        id
        loan_amount
      }
      loan_renew_from {
        id
        loan_display_id
        loan_number
        receipts
      }
    }
  }
`;

const Sidebar = (props) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [trigger, setTrigger] = useState(false);

  const myRef = useRef(null);
  let loadedRowsMap = {};

  const {
    data: loans,
    loading: loadingLoans,
    // error: loanQueryError,
    // subscribeToMore,
  } = useSubscription(GET_LOANS_FOR_SIDEBAR, {
    fetchPolicy: "network-only",
    variables: {
      // offset: 0,
      // limit: 10,
      loan_number: `%${searchText}%`,
      loan_statuses: props.loan_statuses,
    },
    onCompleted(FetchedData) {
      // console.log("FetchedData", FetchedData);
      // let temp = [...data, ...FetchedData.loans];
      // console.log("temp", temp);
      // setData(temp);
      // setLoading(false);
      // console.log("check");
      // if (FetchedData.loans.length > 0) {
      //   props.setSelectedLoan(FetchedData.loans[0].id);
      // }
    },
  });

  // TRIGGER FOR SETTING FIRST LOAN AS ACTIVE ON LOAD
  useEffect(() => {
    if (!trigger && loans !== undefined) {
      setTrigger(true);
      props.setSelectedLoan(loans.loans.length > 0 ? loans.loans[0].id : null);
    }
  }, [loadingLoans]);

  useEffect(() => {
    // console.log("props.trigger", props.trigger);

    if (props.trigger) {
      let current_index = loans.loans.findIndex(
        (loan) => loan.id === props.index
      );
      // console.log("current_index", current_index);

      if (loans.loans[current_index + 1])
        props.setSelectedLoan(loans.loans[current_index + 1].id);
      else if (loans.loans[current_index - 1])
        props.setSelectedLoan(loans.loans[current_index - 1].id);
      else props.setSelectedLoan(0);

      props.setTrigger(false);
    }
  }, [props.trigger]);

  // useEffect(() => {
  //   props.setNextLoan()
  // }, [loans])

  const isRowLoaded = ({ index }) => {
    return !!loadedRowsMap[index];
  };

  const renderItem = ({ index, key, style }) => {
    const item = loans.loans[index];

    // console.log("Index matching", props.index, index);

    return (
      <div
        key={key}
        style={style}
        className={`px-4 py-2 space-y-2 border
          ${
            props.index === item.id
              ? "bg-yellow-primary bg-opacity-75"
              : "hover:bg-yellow-primary hover:bg-opacity-25 cursor-pointer"
          }
        `}
        onClick={() => {
          props.setSelectedLoan(item.id);
        }}
      >
        {item ? (
          <>
            <h4
              className={
                props.index === item.id
                  ? "text-base font-semibold"
                  : "text-base font-semibold"
              }
            >
              Loan ID: {item?.loan_number}
            </h4>
            <div className="flex space-x-4">
              <div className="text-xs border rounded px-4 py-0 bg-yellow-primary font-bold">
                {item.loan_type === "OD" ? "Overdraft" : "Gold Loan"}
              </div>
              <div className="text-xs border rounded px-2 py-0 bg-yellow-primary font-bold">
                ₹{" "}
                <Currency
                  number={
                    item.od_amount
                      ? item.od_amount.credit_limit
                      : item.gl_amount
                      ? item.gl_amount.loan_amount
                      : null
                  }
                />
              </div>
            </div>
            <p className="text-xs text-gray-600 font-semibold">
              {moment(item.created_at).format("DD-MM-YYYY HH:mm")}
            </p>
          </>
        ) : (
          "No item"
        )}
      </div>
    );
  };

  const handleInfiniteOnLoad = ({ startIndex, stopIndex }) => {
    // console.log("handleInfiniteLoadIndices", startIndex, stopIndex);
    setLoading(true);
    for (let i = startIndex; i <= stopIndex; i++) {
      // 1 means loading
      loadedRowsMap[i] = 1;
    }
    if (loans.length % 10 !== 0) {
      setLoading(false);
      return;
    }
    // subscribeToMore({
    //   variables: {
    //     offset: loans.length,
    //     limit: 10,
    //   },
    //   updateQuery: (prev, { fetchMoreResult }) => {
    //     if (!fetchMoreResult) return prev;
    //     // console.log("New data", fetchMoreResult);
    //     setLoading(false);
    //     return Object.assign({}, prev, {
    //       loans: [...prev.loans, ...fetchMoreResult.loans],
    //     });
    //     // let temp = [...data, ...fetchMoreResult.loans];
    //     // setData(temp);
    //   },
    // });
  };

  const debouncedSearch = debounce((value) => setSearchText(value), 1000);

  return (
    <div className="relative">
      <div
        style={{
          backgroundColor: "white",
          width: "18vw",
          overflow: "scroll",
          minWidth: "250px",
        }}
        className="border h-full"
        ref={myRef}
      >
        <Search
          className="sticky z-10 top-0"
          placeholder="Search Loan ID"
          onChange={(e) => {
            // console.log("Search text", e.target.value);
            debouncedSearch(e.target.value);
          }}
        />
        {loans ? (
          loans.loans.length > 0 ? (
            <WindowScroller scrollElement={myRef.current} key={myRef.current}>
              {({ isScrolling, onChildScroll, scrollTop }) => (
                <InfiniteLoader
                  isRowLoaded={isRowLoaded}
                  loadMoreRows={handleInfiniteOnLoad}
                  rowCount={100}
                >
                  {({ onRowsRendered }) => (
                    <AutoSizer>
                      {({ height, width }) => {
                        return (
                          <List
                            autoHeight
                            height={height}
                            isScrolling={isScrolling}
                            onScroll={onChildScroll}
                            overscanRowCount={2}
                            rowCount={loans.loans.length}
                            // rowCount={1}
                            rowHeight={110}
                            rowRenderer={renderItem}
                            onRowsRendered={onRowsRendered}
                            scrollTop={scrollTop}
                            width={width}
                          />
                        );
                      }}
                    </AutoSizer>
                  )}
                </InfiniteLoader>
              )}
            </WindowScroller>
          ) : (
            <EmptyPage />
          )
        ) : (
          <EmptyPage />
        )}
      </div>
      <p className="fixed bottom-0 flex bg-white items-center justify-center font-extrabold ml-3 mb-4 text-[12px] h-[24px] px-2 py-1 border border-[#E6E6E6] rounded-lg bg-[#FFFFFF] text-[#909090]">
        V{packageJson.version}
      </p>
    </div>
  );
};

export default Sidebar;
